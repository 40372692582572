import React, { useState }  from 'react';
import                      './ChangePassword.css';
import FetchService         from '../../common/FetchService';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Button               from '@material-ui/core/Button';
import FormControl          from '@material-ui/core/FormControl';
import InputLabel           from '@material-ui/core/InputLabel';
import InputAdornment       from '@material-ui/core/InputAdornment';
import IconButton           from '@material-ui/core/IconButton';
import Visibility           from '@material-ui/icons/Visibility';
import VisibilityOff        from '@material-ui/icons/VisibilityOff';
import Input                from '@material-ui/core/Input';
import Dialog               from '@material-ui/core/Dialog';
import DialogTitle          from '@material-ui/core/DialogTitle';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogActions        from '@material-ui/core/DialogActions';
import TextField            from '@material-ui/core/TextField';
/*
import InfoIcon             from '@material-ui/icons/InfoOutlined';
import Popper               from '@material-ui/core/Popper';
import Fade                 from '@material-ui/core/Fade';
import Paper                from '@material-ui/core/Paper';
import ClickAwayListener    from '@material-ui/core/ClickAwayListener';
*/
const themePrimary      = createTheme({
    overrides: { MuiTooltip: { tooltip: { fontSize: '0.9em' } } },
    palette: {
        primary: {
            main: '#519657'
        },
        secondary: {
            main: 'rgb(33, 33, 33)'
        }
    },

});

//regex e funzioni di controllo per conformità dati
// const regexPswValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{10,}$/;
// const checkPsw          = (value) => regexPswValidator.test(value);

const ChangePassword    = (props) =>{
    
    const
         [ showPswInputOld     ,setShowPswInputOld     ] = useState(false)
        ,[ showPswInputNew     ,setShowPswInputNew     ] = useState(false)
        ,[ showPswInputConfirm ,setShowPswInputConfirm ] = useState(false)
     // ,[ showPswFields       ,setShowPswFields       ] = useState(false)
        ,[ oldPsw              ,setOldPsw              ] = useState('')
        ,[ newPsw              ,setNewPsw              ] = useState('')
        ,[ newPswConfirm       ,setNewPswConfirm       ] = useState('')
     // ,[ oldPswCheck         ,setOldPswCheck         ] = useState(true)
     // ,[ newPswCheck         ,setNewPswCheck         ] = useState(true)
    
        /* stati per il popper della psw
        ,[ anchorEl            ,setAnchorEl            ] = useState(null)
        ,[ open                ,setOpen                ] = useState(false)
        ,[ placement           ,setPlacement           ] = useState('')
        ,[ error               ,setError               ] = useState('')
        */
    ;

    //mentre effettuo il cambio del valore, controllo la conformità di quello che inserisce l'utente ed eventualmente blocco il bottone di submit
    
    const handleChange                  = (event) =>{
        const value = event.target.value;
        switch(event.target.name){
            case 'oldPsw':
                setOldPsw(value);
                // checkPsw( value) ? setOldPswCheck(true) : setOldPswCheck(false);
                break;
            case 'newPsw':
                setNewPsw(value);
                // checkPsw( value) ? setNewPswCheck(true) : setNewPswCheck(false);
                break;
            case 'newPswConfirm':
                setNewPswConfirm(value);
                break;
            default :
                break;
        }
    }
    
    const toggleNotifica                = ( message, severity ) => {
        props.set_oNotifyOptions({ message, severity  });
    };

    /*handler per il popper con le info sulla conformità della psw
    const handlePopperClick             = (newPosition, event) => {
        event.preventDefault()
        setAnchorEl(event.target);
        setOpen(!open);
        setPlacement(newPosition);
    }
    //per chiudere il popper quando si clicca da qualsiasi altra parte della pagina
    const handleClosePopperClickAway    = (event) => {
        event.preventDefault()
        setOpen(false);
    }
    */
    // reset dei campi
    const resetFields                   = () => {
        setOldPsw('');
        setNewPsw('');
        setNewPswConfirm('');
        setShowPswInputOld(false);
        setShowPswInputNew(false);
        setShowPswInputConfirm(false);
    }

    //submit delle psw
    const handleSubmitPsw               = async(event) =>{
        
        event.preventDefault();
        const params = {
             ...( props.force  && props.showState && { pKUser: props.showState })
            ,...( !props.force && { pOldPassword: oldPsw })
            ,pNewPassword: newPsw
        };

        const { nRetVal, vErrorMessageDesc } = await FetchService.asyncPost({
             url: ( !props.force ? '/change' : '/force' ) + '-password'
            ,params
            ,displayErrorHandler: toggleNotifica
        });

        if ( nRetVal <= 0 ) {
            props.set_oNotifyOptions({ message: vErrorMessageDesc  ,severity: 'error'   });
        } else {
            resetFields();
            props.set_showState('');
            props.set_oNotifyOptions({ message: 'Password changed' ,severity: 'success' });
        }
        
    }
    
    const makePswField                  = ({ htmlName, htmlLabel, value, showState, setShowState }) => ( 
        <div className="row">
            <FormControl className="password-wrapper" >
                <InputLabel htmlFor={ htmlName }>{ htmlLabel }</InputLabel>
                <Input
                    type        ={ showState ? 'text' : 'password' }
                    name        ={ htmlName }
                    value       ={ value    }
                    onChange    ={ handleChange }
                    margin      ="dense"
                    inputProps  ={{maxLength: 50, autoComplete: 'off', spellCheck:'false' }}
                    error       ={ ( htmlName !== 'oldPsw' ) && ( newPsw !== newPswConfirm ) }
                    endAdornment={
                        <InputAdornment position="end" tabIndex={-1}>
                            <IconButton onClick={ (event) => { setShowState(!showState) } } tabIndex={-1}>
                                { showState ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </div>
    )
    
    return (
        <Dialog open={ !!props.showState } className="users editing-dialog" onClose={ () => { props.set_showState('') } }>
            <DialogTitle>{ ( !props.force ? 'Change' : 'Force new' ) + ' Password' }</DialogTitle>
            <DialogContent>
                <div className="change-password">
                    
                    <MuiThemeProvider theme={themePrimary}>

                        {   !props.sUsername ? null :
                            <TextField
                                id       ="USERNAME"
                                className="USERNAME row"
                                label    ="Username" 
                                disabled ={true}
                                value    ={ props.sUsername }
                            />
                        }
                        
                        { props.force ? null : 
                            makePswField({ 
                                htmlName: 'oldPsw'        ,htmlLabel: 'Old Password'         ,value: oldPsw        ,showState: showPswInputOld     ,setShowState: setShowPswInputOld
                        }) }
                        {   makePswField({ 
                                htmlName: 'newPsw'        ,htmlLabel: 'New Password'         ,value: newPsw        ,showState: showPswInputNew     ,setShowState: setShowPswInputNew
                        }) }
                        {   makePswField({ 
                                htmlName: 'newPswConfirm' ,htmlLabel: 'Confirm new Password' ,value: newPswConfirm ,showState: showPswInputConfirm ,setShowState: setShowPswInputConfirm
                        }) }
                        
                        <div className="row legend">
                            <div className="col-12">
                                <span>(Password length must range from { localStorage.getItem( 'PASSWORD_MIN_LENGTH') } to 50 characters)</span>
                            </div>
                        </div>
                        
                    </MuiThemeProvider>
                    
                </div>
            </DialogContent>
            <DialogActions>
                <MuiThemeProvider theme={themePrimary}>
                    <Button
                        onClick ={ handleSubmitPsw }
                        color   ="primary"
                        disabled={ newPsw !== newPswConfirm }
                    >{ ( !props.force ? 'Change' : 'Force' ) }</Button>
                    <Button onClick={ () => { props.set_showState('') } } color="secondary">cancel</Button>
                </MuiThemeProvider>
            </DialogActions>
        </Dialog>
    );

}

export default ChangePassword;
