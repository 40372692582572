import config       from '../../../config';
// import Utils     from '../../../common/CommonUtilities';
import './SimpleTable.css';
import React, {useEffect, useRef, useState} from 'react';
import {TextField, Paper, Checkbox, MenuItem, Select} from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";

const removeSpecialChars = s => ( ['string','number'].includes(typeof s) ? s : '' ).toString().replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

let timeoutIdOnHover = 0;

export function SimpleTable({ chiave,
    sTableDataType, aoRows = [], oRowOptions, aoCols, extraClasses, noHeight, noHeader, noFilter,  bOnlySelected,
    isCheckedFunction = ()=>{}, fCheckAll, fCheckOne, oCheckOptions = {}, // oCheckOptions = { isEnabled: true, isVisible: true }
    isHighlightable, isHighlightedFunction = ()=>{}, fHighlightOnlyOne,
    isEditable, editFunction,
    exception = val=>val
}) {

    // l'abilitazione delle checkbox è possibile solo se gli elementi nelle righe hanno la proprietà "FLAG_SELECTED" ( 'Y' oppure 'N' )

    const isEmpty          = aoRows.length === 0;
    const asKeyColumnNames = ( aoCols.filter( oCol => oCol.isUniqueKeyForRow ) || {} ).map( oCol => oCol.name );
    const getUniqueKey     = ( oRow, asKeyColumnNames ) => {
        return asKeyColumnNames.reduce(
            ( sUniqueKey, sKeyColumnName ) => sUniqueKey + oRow[ sKeyColumnName ],
            ''
        );
    };
    const bFiltersLimitReached = ( aoRows.filter( isCheckedFunction ).length >= ( config.FILTERS_LIMIT || 50 ) );

    const
        [ oColumnFilters   ,set_oColumnFilters   ] = useState({})           // per ogni colonna mi segno il valore da filtrare
       ,[ aoFilteredRows   ,set_aoFilteredRows   ] = useState([...aoRows])  // array interno delle sole righe filtrate
       ,[ height           ,set_height           ] = useState(0)            // serve per mantenere fissa l'altezza della finestra dopo il primo caricamento dei dati
       ,[ bSelectAll       ,set_bSelectAll       ] = useState(null)         // indica se devono essere selezionati tutti gli elementi,
       ,[ coordHover       ,set_coordHover       ] = useState([])
        // ATTENZIONE: importante che bSelectAll sia inizializzato a null e poi nella useEffect si verifichi che sia boolean
    ;

    const divElement = useRef(null);

    useEffect(() => {
        if ( !noHeight ) {
            set_height(divElement.current.clientHeight);
        }
        if ( !aoRows.length ) { console.error('Nessun dato da visualizzare') }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ( bOnlySelected ) {
            set_aoFilteredRows( [...aoRows].filter( isCheckedFunction ) );
        } else {
            set_aoFilteredRows( [...aoRows] );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bOnlySelected]);

    const aoRowsUpdatedWithAllFilters = () => {

        let aoRowsUpdated = [...aoRows];

        // applico ogni filtro inserito (compreso l'ultimo appena aggiunto)
        const asFilterKeys = Object.keys( oColumnFilters );
        for ( let nFilterKey = 0; nFilterKey < asFilterKeys.length; nFilterKey++ ) {
            const sColumnFilterName = asFilterKeys[nFilterKey];

            let sValueFiltered     = oColumnFilters[sColumnFilterName];
            const oColFound        = ( aoCols.find( oCol => !oCol.noFilter  && ( oCol.name === sColumnFilterName ) ) || {} );
            const selectTriOptions = oColFound.selectTriOptions;
            const formatFunction   = oColFound.format || ( val => val );

            const filterRows = ( sValue, aoRowsInFilter, sColKey ) => {
                return aoRowsInFilter.filter( oRow => {
                    if  ( !!selectTriOptions ) {
                        const oActualOption = selectTriOptions.find( oSelectOption => oSelectOption.value === sValue );
                        return oActualOption && oActualOption.checkFunc && oActualOption.checkFunc( oRow[sColumnFilterName] || '', sColKey );
                    } else {
                        return removeSpecialChars( formatFunction(oRow[sColumnFilterName]) ).includes( removeSpecialChars(sValue) );
                    }
                });
            }

            if ( typeof sValueFiltered === 'object' ) {
                const asColKeys = Object.keys( oColumnFilters[ sColumnFilterName ] );
                for ( let nColKey = 0; nColKey < asColKeys.length; nColKey++ ) {
                    const sColKey = asColKeys[nColKey];
                    sValueFiltered = oColumnFilters[ sColumnFilterName ][ sColKey ];
                    aoRowsUpdated  = filterRows( sValueFiltered, aoRowsUpdated, sColKey );
                }
            } else {
                aoRowsUpdated = filterRows( sValueFiltered, aoRowsUpdated );
            }

        }

        return aoRowsUpdated;

    };

    const handleFilterValues = ({ sValueToFilter, sColumnName, key }) => {

        // aggiungo l'attuale valore filtrato alla colonna interessata
        if ( key ) {
            if ( !oColumnFilters[ sColumnName ] ) {
                oColumnFilters[ sColumnName ] = {};
            }
            oColumnFilters[ sColumnName ][ key ] = sValueToFilter;
        } else {
            oColumnFilters[ sColumnName ] = sValueToFilter;
        }

        const aoRowsUpdated = aoRowsUpdatedWithAllFilters();

        // aggiorno righe e colonne
        set_oColumnFilters( oColumnFilters );
        set_aoFilteredRows( aoRowsUpdated );

    }

    const onChangeSelectAll         = (event) => {
        set_bSelectAll(!!event.target.checked);
    }

    useEffect( ()=>{
        // ATTENZIONE: importante che bSelectAll sia inizializzato a null e poi nella useEffect si verifichi che sia boolean
        if ( oCheckOptions.isVisible && ( typeof bSelectAll === 'boolean' ) ) {
            fCheckAll(bSelectAll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bSelectAll]);

    useEffect( ()=>{
        
        if ( aoRows.every( oRow => oRow.FLAG_SELECTED === 'Y' ) ) {
            set_bSelectAll(true);
        }
        
        let aoRowsToUpdate = aoRowsUpdatedWithAllFilters();
        if ( bOnlySelected ) {
            aoRowsToUpdate = aoRowsToUpdate.filter( isCheckedFunction );
        }
        
        set_aoFilteredRows( aoRowsToUpdate );
        // fix per aggiornare lo stato della tabella quando aoRows (una prop) viene aggiornata dal padre
        // ad ogni modifica di aoRows risetto lo stato associato "aoFilteredRows" tenendo conto dei filtri già applicati
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ aoRows ]);

    const isHeadersGrouped = aoCols.some( oCol => oCol.group );
    const oColsGroups     = aoCols.reduce( ( oColsGroups, oCol ) => {
        if ( !oColsGroups[ oCol.group ] ) {
            oColsGroups[ oCol.group ] = 1; // se non esiste il gruppo nell'oggetto, lo crea e gli dà valore 1
        } else { // altrimenti se esiste già aumenta il suo valore di 1
            oColsGroups[ oCol.group ] = oColsGroups[ oCol.group ] + 1;
        }
        return {...oColsGroups};
    } ,{});

    /*
    const handleCheck = ( { isChecked, oRow } ) => {
        handleChangeCheck({ isChecked, oRow });
        setAoRows( [...aoRows] );
    };

    const  handleHighlight = ( { isHighlighted, oRow } ) => {
        console.log('PRIMA ',JSON.stringify(aoRows));
        aoRows.map( row => handleChangeHighlight({ isHighlighted: false, oRow: row }) ); // toglie evidenziazione da tutte le righe
        handleChangeHighlight({ isHighlighted: !isHighlighted, oRow }); // evidenzia solo la riga interessata
        console.log('DOPO ',JSON.stringify(aoRows));
        console.log(setAoRows.toString());
        setAoRows( [...aoRows] );
    };
    */
    
    const selectAllCheckbox = () => 
        <th key="checkcolumn" className="check">
            { ( !oCheckOptions.onlyIfFlagged || ( oCheckOptions.onlyIfFlagged && aoFilteredRows.find( o => o.FLAG_SELECTED ) ) ) &&
                <Tooltip title={ bSelectAll ? 'Deselect all' : 'Select all' } >
                    <Checkbox className="check-sel-all" checked={ !!bSelectAll } onChange={ onChangeSelectAll } />
                </Tooltip>
            }
        </th>
    ;

    return <Paper
        key={chiave}
        className ={
            'SimpleTable-wrapper '
            + ( isEmpty ? ' no-data ' : ' ' )
            + ( extraClasses || ' ' )
            + ' ' + sTableDataType
        }
        ref       ={ divElement }
        style     ={ height ? { height } : {} }
    >
        <div className={ 'SimpleTable ' + chiave } key={chiave}>
            <table>
                <thead>
                {   // intestazioni dei gruppi di colonne
                    ( noHeader || !isHeadersGrouped ) ? null :
                    <tr>
                        { oCheckOptions.isVisible && !oCheckOptions.isLastCol && <th key="checkcolumn" className="check" ><div></div></th> }
                        { isEditable              && <th key="editcolumn"  className="edit"  ><div></div></th> }
                        { Object.keys(oColsGroups).map( sColGroup =>
                            <th 
                                key={sColGroup}
                                className={ sColGroup !== 'undefined' ?  ( 'colgroup ' + sColGroup ) : '' }
                                colSpan={ oColsGroups[sColGroup] }
                            >{ sColGroup === 'undefined' ? '' : sColGroup }</th>
                        ) }
                        { oCheckOptions.isVisible && oCheckOptions.isLastCol && <th key="checkcolumn" className="check" ><div></div></th> }
                    </tr>
                }
                {   // intestazioni delle colonne
                    noHeader ? null :
                    <tr>
                        { oCheckOptions.isVisible && !oCheckOptions.isLastCol && <th key="checkcolumn" className="check" ><div></div></th> }
                        { isEditable              &&    <th key="editcolumn"  className="edit"  ><div></div></th> }
                        { aoCols.map( ( oCol, nCol ) => 
                            <th key={ 'headers' + oCol.name + nCol }
                                className={ ( oCol.group ? ( 'ingroup ' + oCol.group ) : '' ) + ' ' + oCol.name }
                                style={ { minWidth: oCol.width || '', width: oCol.width || '', maxWidth: oCol.width || '' } }
                            >{oCol.title}</th>
                        ) }
                        { oCheckOptions.isVisible && oCheckOptions.isLastCol && <th key="checkcolumn" className="check" ><div></div></th> }
                    </tr>
                }
                {   // riga dei filtri
                    noFilter ? null :
                    <tr className="filter">
                        { oCheckOptions.isVisible && !oCheckOptions.isLastCol && selectAllCheckbox() }
                        { isEditable && <th key="editcolumn" className="edit" /> }
                        { // filtri sulle righe
                            aoCols.map( ( oCol, nCol ) => {
                                let sValueToFilter = oColumnFilters[ oCol.name ] || '';
                                if ( sValueToFilter && ![ 'string', 'number' ].includes( typeof sValueToFilter ) ) {
                                    sValueToFilter = sValueToFilter[ oCol.key ];
                                }
                                return <th key={ 'filters' + oCol.name + nCol } className={ oCol.name }> {
                                    oCol.noFilter ? '' :
                                    ( !!oCol.selectTriOptions )
                                        ? // filtro in versione select con solo tre opzioni: tutto, uno specifico valore oppure il valore opposto
                                            <Select
                                                value       ={ sValueToFilter || '' }
                                                onChange    ={ (event) => {
                                                    handleFilterValues({
                                                         sValueToFilter:     event.target.value
                                                        ,sColumnName:        oCol.name
                                                        ,formatFunction:     oCol.format
                                                        ,selectTriOptions:   oCol.selectTriOptions
                                                        ,key:                oCol.key
                                                    })
                                                }}
                                            >{
                                                oCol.selectTriOptions.map( oSelectItem =>
                                                    <MenuItem key={ oSelectItem.value } value={ oSelectItem.value } >{ oSelectItem.label }</MenuItem>
                                                )
                                            }</Select>
                                        : // filtro versione con input libero da parte dell'utente digitando il testo
                                            <TextField
                                                type        ="text"
                                                variant     ="standard"
                                                value       ={ sValueToFilter }
                                                onChange    ={ (event) => {
                                                    handleFilterValues({
                                                         sValueToFilter:    event.target.value
                                                        ,sColumnName:       oCol.name
                                                        ,formatFunction:    oCol.format
                                                    })
                                                }}
                                                placeholder ="Filter..."
                                                fullWidth
                                            />
                                    /* InputProps={{ endAdornment: ( <InputAdornment position="end"> <span>Cc</span> <span>W</span> <span>*</span> </InputAdornment> ) }} */
                                } </th>
                            })
                        }
                        { oCheckOptions.isVisible && oCheckOptions.isLastCol && selectAllCheckbox() }
                    </tr>
                }
                </thead>
                <tbody>
                {   // corpo della tabella
                    aoFilteredRows.map( (oRow, nRow) => {
                        const isChecked = isCheckedFunction( oRow );
                        const displayCheckbox = () => 
                            <td key="checkcolumn" className="check">
                                { ( ( oCheckOptions.onlyIfFlagged && oRow.FLAG_SELECTED ) || ( !oCheckOptions.onlyIfFlagged ) ) && 
                                    <Checkbox // disabilita checkbox se si supera il massimo di elementi selezionabili
                                        disabled ={ !oCheckOptions.isEnabled && bFiltersLimitReached }
                                        checked  ={ !!isChecked }
                                        onClick  ={((event)=>{event.stopPropagation()})}
                                        onChange ={ (event) => fCheckOne( { isChecked: event.target.checked, oRow } ) }
                                        color    ="primary"
                                    />
                                }
                            </td>
                        ;
                        
                        return <tr
                                    key       ={ getUniqueKey(    oRow, asKeyColumnNames ) }
                                    className ={ ( isHighlightable && isHighlightedFunction( oRow ) ? ' highlight ' : '' ) 
                                               + ( oRowOptions && oRowOptions.addClass ? oRowOptions.addClass(oRow) : '' ) }
                                    onClick   ={ () => { isHighlightable && fHighlightOnlyOne( oRow ) } }
                        >
                            {   // colonna per CHECKBOX
                                oCheckOptions.isVisible && !oCheckOptions.isLastCol && displayCheckbox()
                            }
                            {   // colonna per EDIT
                                isEditable && <td className="edit"><Tooltip title="Edit">
                                    <EditIcon onClick={ () => { editFunction(oRow) } } /></Tooltip>
                                </td>
                            }
                            {   // corpo della tabella (i dati)

                                aoCols.map( (oCol, nCol) => {
                                    const
                                         originalValue  = oRow[ oCol.name ]
                                        ,valueToDisplay = ['string','number'].includes( typeof originalValue ) ? originalValue : ''
                                        ,formatValue    = oCol.format ? oCol.format( valueToDisplay, oRow, oCol ) : valueToDisplay
                                        ,optionalProps  = !oCol.onHover ? {} : {
                                            onMouseEnter:() => { timeoutIdOnHover = setTimeout( () => { set_coordHover([nRow, nCol]) }, 250 ) },
                                            onMouseLeave:() => { clearTimeout(timeoutIdOnHover);  set_coordHover([]) }
                                        }
                                        ,cellContent    = ( oCol.onHover && ( coordHover[0] === nRow ) && ( coordHover[1] === nCol ) )
                                            ? oCol.onHover( formatValue, oRow.KUSER, oCol.key )
                                            : exception( formatValue, oRow, oCol, oRow.asCurrentFilters )
                                    ;
                                    return <td
                                        key       = { oCol.name + oCol.title }
                                        className = { oCol.name + ' ' 
                                            + ( oCol.key ? oCol.format( valueToDisplay ) : '' ) + ' ' 
                                            + ( ( oCol.additionalClass || ((v)=>'') )(originalValue) )
                                        }
                                        style     = { { minWidth: oCol.width || '', width: oCol.width || '', maxWidth: oCol.width || '' } }
                                        { ...optionalProps }
                                    >{
                                        oCol.tooltip ? <Tooltip title={oCol.tooltip}>{cellContent}</Tooltip> : cellContent
                                    }</td>
                                })

                            }
                            {   // colonna per CHECKBOX
                                oCheckOptions.isVisible && oCheckOptions.isLastCol && displayCheckbox()
                            }
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    </Paper>

}
/* --- esempio di utilizzo ---
    tableContainer = ({
                        key, sTableType, sTableDataType, aoColumns, aoRows, setAoRows, bChecksVisible, bOnlySelectedEnabled,
                        exception, noHeader, noFilter, isEditable, editFunction
                     }) => {

        return !( aoRows && aoRows.length ) ? null : (
            <SimpleTable

                chiave                ={ key }
                sTableDataType        ={ sTableDataType }
                aoRows                ={ aoRows    }
                aoCols                ={ aoColumns }

                noHeight              ={ true }
                noHeader              ={ noHeader }
                noFilter              ={ noFilter }
                bOnlySelected         ={ bOnlySelectedEnabled }

                oCheckOptions         ={ { isEnabled: true, isVisible: ( sTableType === 'checkable' ) && bChecksVisible } }
                isCheckedFunction     ={ isCheckedFunction }
                fCheckAll             ={ bCheckAll => fCheckAll( { bCheckAll, aoRows, setAoRows } ) }
                fCheckOne             ={ ({ isChecked, oRow }) => fCheckOne( { isChecked, oRow, aoRows, setAoRows } ) }

                isEditable            ={ !bChecksVisible && isEditable }
                editFunction          ={ isEditable && editFunction ? editFunction : () => {} }

                exception             ={ exception }

            />
        )
    }

    tableContainer({
         key:                    'users'
        ,sTableDataType:         'USERS'
        ,sTableType:             'checkable'
        ,aoColumns:              [...aoUsersColumns]
        ,aoRows:                 [...aoUsers]
        ,setAoRows:              set_aoUsers
        ,bChecksVisible:         bUsersChecks
        ,bOnlySelectedEnabled:   false
        ,isEditable:             true
        ,editFunction:           onClickEdit
    })

*/
