import React, { useState, useEffect }   from 'react';
import { ResponsivePieCanvas }          from '@nivo/pie';
import FetchService                     from '../../../common/FetchService';
import CommonUtilities                  from '../../../common/CommonUtilities';

function createTooltip(v){
    return <div className="pie-tooltip">
        {v.label}:&nbsp;&nbsp;&nbsp;<span className="value">{
            CommonUtilities.formatPositiveIntNum(v.value) + ' (' +
            CommonUtilities.formatPositiveNum( v.data.perc, 1 ) + ' %)'
        }</span>
    </div>
}
export default function GrossPerChannelChart(props) {
    
    const { isCairo, isClear, isDisco } = props.oCustomerChecks;
    const [data, setData] = useState([]);

    const
        url = isClear ? '/cubes/charts/gross-per-channel' :
              isDisco ? '/cubes/charts/gross-per-channel' :
              isCairo ? '/cubes/charts/net-per-channel'   : ''
    ;

    useEffect(() => {
        async function onLoad() {
            let data = ( url && await FetchService.asyncGet({ url }) ) || [{id:'',label:'',value:0,perc:0}];
            // dati fittizi
            // data = [{"id":"AP","label":"ADVERTISING","value":4260},{"id":"DC","label":"CHANNEL","value":50616},{"id":"SC","label":"STRATEGIC","value":13410},{"id":"DEF","label":"BROADCAST","value":15},{"id":"DMA","label":"AGENT","value":805920},{"id":"DP","label":"KRESS","value":134886},{"id":"ES1","label":"RELAYZ","value":39724},{"id":"E2","label":"AMBLAR","value":6720},{"id":"FC","label":"BLOK","value":23214},{"id":"FO","label":"RUNNAL","value":93228},{"id":"FNIT","label":"MYNTER","value":108192},{"id":"FB","label":"DEMURZ","value":226228},{"id":"GI","label":"UNWIR","value":253590},{"id":"HGTV","label":"BUREK","value":45476},{"id":"ID","label":"TATTAR","value":2091},{"id":"K2","label":"AMERC","value":363672},{"id":"MTIT","label":"MARKETING","value":121452},{"id":"DJ","label":"AKTION","value":838506},{"id":"DDSI","label":"ALLSQY","value":5},{"id":"RT","label":"KOOMB","value":1137584},{"id":"TL","label":"RECQ","value":7617},{"id":"VVID","value":29988},{"id":"YT","value":1095}] ;
            // console.log(JSON.stringify(data));
            setData(data);
        }
        onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        data.length > 0 && (
            <ResponsivePieCanvas
                data={data}
                margin={{top: 80, right: 80, bottom: 40, left: 80}}
                padAngle={1}
                cornerRadius={5}
                colors={{ scheme: 'set2' }}
                borderWidth={0}
                borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                radialLabel={(v) => v.label+" (" + CommonUtilities.formatPositiveIntNum(v.value) + ")" }
                radialLabelsSkipAngle={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{from: 'color'}}
                enableSliceLabels={false}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor="#333333"
                tooltip={v=>createTooltip(v.datum)}
                innerRadius={0.1}
                animate={false}
                enableArcLabels={true}
                arcLabel={ v => CommonUtilities.formatPositiveNum( v.data.perc, 1 ) + ' %' }
                arcLabelsSkipAngle={18.1}
                arcLabelsRadiusOffset={0.65}
                arcLinkLabelsSkipAngle={6}
                arcLinkLabel={ v => `${v.id} (${CommonUtilities.formatPositiveIntNum(v.value)})` }
            />
        )
    );
}
