import FetchService         from '../../../common/FetchService';
import CommonUtilities      from '../../../common/CommonUtilities';
import FilterCustom         from './FilterCustom';
import {createTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import Dialog               from '@material-ui/core/Dialog';
import DialogTitle          from '@material-ui/core/DialogTitle';
import DialogContent        from '@material-ui/core/DialogContent';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment               from 'moment';
import DateFnsUtils         from '@date-io/date-fns';
import itLocale             from 'date-fns/locale/it';
import Grid                 from '@material-ui/core/Grid';
import TextField            from '@material-ui/core/TextField';
import Tooltip              from '@material-ui/core/Tooltip';
import AddIcon              from '@material-ui/icons/Add';
import Paper                from '@material-ui/core/Paper';
import Chip                 from '@material-ui/core/Chip';
import DialogActions        from '@material-ui/core/DialogActions';
import Button               from '@material-ui/core/Button';
import React, {useEffect, useState} from 'react';
import Slide                from "@material-ui/core/Slide";
import IconButton           from '@material-ui/core/IconButton';
// import VisibilityIcon       from '@material-ui/icons/Visibility';
import Draggable            from 'react-draggable';
import { Input, Switch }    from '@material-ui/core';
import BlurOnIcon           from '@material-ui/icons/BlurOn';
// import {Resizable} from "re-resizable";
import config               from '../../../config';
import CircularProgress     from '@material-ui/core/CircularProgress';

// const getStringFromDate = CommonUtilities.getStringFromDate;
const momentLib   = moment; // per risolvere un problema di identificazione di moment da parte di phpstorm
const {
    getAsValuesFromAo,
    getAoFromAsValues,
    isValidNumber,
    isValidPartialNumber,
    isValidNumberOrEmpty
} = CommonUtilities;

const themeDialog = createTheme({
    overrides: { MuiTooltip: { tooltip: { fontSize: '0.9em' } } },
    palette: {
        primary: {
            main:   '#509556',
        },
        secondary: {
            main:   'rgb(33, 33, 33)'
        }
    }
});

const Transition  = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ColoredSwitch = withStyles({
    switchBase: {
        color: 'grey',
        '&$checked': {
            color: 'grey',
        },
        '&$checked + $track': {
            backgroundColor: 'grey',
        },
    },
    checked: {},
    track: {},
})(Switch);

const getColoredSwitch = ( bExcludeChecked, fHandler, isDisabled ) => <>
    <label key="include" className={ 'include' + ( bExcludeChecked  ? '' : ' checked' ) }>Include</label>
    <ColoredSwitch key="coloredswitch" className="myswitch" color="primary" onChange={ fHandler } checked={ !!bExcludeChecked } disabled={ !!isDisabled } />
    <label key="exclude" className={ 'exclude' + ( bExcludeChecked ? ' checked' : ''  ) }>Exclude</label>
</>;

const removeSpecialCharsExceptSpace = s => s.toString().replace(/[^a-zA-Z0-9 ]/g, "");

const LIKE_SEPARATOR = '‡';

/*
const mounted = useRef();
useEffect(() => {
  if (!mounted.current) {
    // do componentDidMount logic
    mounted.current = true;
  } else {
    // do componentDidUpdate logic
  }
});
*/

function PaperComponent(props) {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#alert-dialog-slide-title" cancel={'[class*="MuiDialogContent-root"]'} bounds="body">
            <Paper {...props} />
        </Draggable>
    );
}

function returnValidNumOrPreviousNumOrEmpty( num, previousNum ) {
    return isValidPartialNumber( num ) || ( num === '' ) ? num : ( ( ( previousNum === 0 ) || previousNum ) ? previousNum : '' );
}

export function ModalFilters( props ) {

    const
        isReadOnly       = props.showFilterModal === 'read_only',
        oDimensionFilter = props.aoDimensions.find( oDimension => oDimension.column === props.sColumnToFilter ), // sColumnToFilter Es. 'CHANNEL_DESC'
        oMeasureFilter   = props.aoMeasures.find(   oMeasure   => oMeasure.column   === props.sColumnToFilter ), // sColumnToFilter Es. 'GROSS'
        isDimension      = !!oDimensionFilter,
        oElementFilter   = ( isDimension ? oDimensionFilter : oMeasureFilter ) || {}
    ;
    if ( !Object.keys(oElementFilter).length ) { console.warn( props.sColumnToFilter + ' non trovato in dimensions o measures'); }
    const 
        aoElements       = ( isDimension ? props.aoDimensions : props.aoMeasures ) || [],
        bExclude         = !!oElementFilter.exclude,
        // asCurrentFilters può essere passato direttamente dalle props (per vedere l'elenco di filtri di un layout NON attualmente selezionato)
        // mentre normalmente basta sapere il nome della colonna filtrata per recuperare da aoDimensions l'elenco dei filtri
        asCurrentFilters = props.asCurrentFilters || oElementFilter.asCurrentFilters, // Es. [ 'CANALE 5', 'RAI 1' ]
        aoCurrentFilters = getAoFromAsValues( asCurrentFilters, oElementFilter.column ),
        sFilterType      = oElementFilter.filterType,       // Es. 'T' e 'P' ( elenco Totale/Parziale ), 'L' ( Lista custom ), 'R' ( Range ), 'K' ( Like )
        sFilterDataType  = oElementFilter.filterDataType,   // Es. 'C' ( Char ), 'N' ( Number ), 'D' ( Date ), 'M' ( Month )
        sDBdateFormat    = 'YYYYMMDD'
    ;
    
    // stati
    const
        [ bResetFilter            , setbResetFilter            ] = useState(false), // per resettare i filtri (svuotarli)
        [ bOpacity                , setbOpacity                ] = useState(false), // per rendere semi-trasparente la modale

        // elenco di tutti i possibili valori (presi da DB) della colonna scelta da filtrare
        [ aoFilterList            , setAoFilterList            ] = useState(aoCurrentFilters), // Es. [ { CHANNEL_DESC: 'CANALE 5' }, { CHANNEL_DESC: 'RAI 1' } ]

        // elenco degli effettivi valori scelti per filtrare la colonna
        [ asFiltersSelected       , setAsFiltersSelected       ] = useState(asCurrentFilters), // Es. [ 'CANALE 5' ]

        // indica se i valori filtrati sono da includere o escludere
        [ bExcludeValues          , setbExcludeValues          ] = useState(bExclude),

        // indica se i valori filtrati sono da includere o escludere
        [ bShowAll                , setbShowAll                ] = useState(false),
        
        // per sFilterType: 'T'/'P' ( Tabella ) // elenco degli effettivi valori scelti per filtrare la colonna in forma di array di oggetti
        [ aoFiltersCustomSelected , setAoFiltersCustomSelected ] = useState(aoCurrentFilters),    // Es. [ { CHANNEL_DESC: 'CANALE 5' } ]
        
        // per sFilterType: 'R' ( Range di date )
        [ dFilterStartDate        , setdFilterStartDate        ] = useState(null),
        [ dFilterEndDate          , setdFilterEndDate          ] = useState(null),

        // per sFilterType: 'R' ( Range di date )
        [ nFilterStartNum         , setnFilterStartNum         ] = useState(''),
        [ nFilterEndNum           , setnFilterEndNum           ] = useState(''),

        // per sFilterType: 'L' ( Lista custom ) e 'T'/'P' ( Tabella )
        [ sFilterAddChip          , setsFilterAddChip          ] = useState(''),
        
        // per sFilterType: 'K' ( Like )
        [ sFilterLike             , setsFilterLike             ] = useState(''),
        
        [ sFilterLikeInizio       , setsFilterLikeInizio       ] = useState(''),
        [ sFilterLikeCentro       , setsFilterLikeCentro       ] = useState(''),
        [ sFilterLikeFine         , setsFilterLikeFine         ] = useState(''),
            
        [ isInnerLoading          , set_isInnerLoading         ] = useState(false)
    ;

    const getFiltersFromDB = async ( sStringToSearch ) => {
        // console.log( 'getFiltersFromDB - sStringToSearch: ' + sStringToSearch );
        let aoReturnedFilters, error;
        
        const getAoFilters = ( aoElements ) => aoElements 
            .filter( oElement => oElement.asCurrentFilters && oElement.asCurrentFilters.length > 0 )
            .map(    oElement => ({
                sColumn:         oElement.column,
                sFilterType:     oElement.filterType,
                sFilterDataType: oElement.filterDataType,
                bExclude:        oElement.exclude,
                asValues:        oElement.asCurrentFilters
            }))
        ;
        
        const oParams = {
             cubeCod:           props.nCubeCod
            ,cubeDesc:          props.sCubeDesc
            ,dimensionColumn:   props.sColumnToFilter
            ,sFilterType
            ,sFilterDataType
            ,nFiltersPageRows:  config.PAGE_ROWS
            ,oFilters:        {
                 aoDimensionsFilters:   bShowAll ? [] : getAoFilters(props.aoDimensions)
                ,aoMeasuresFilters:     bShowAll ? [] : getAoFilters(props.aoMeasures)
            }        // Es. [ { sColumn: 'CHANNEL_CODE', sFilterType: 'L', asValues: [1,2,10,66] }, ... ]
            ,...( sStringToSearch && { sStringToSearch }) // viene passato solo se esiste
            ,oDrillDownFilters: props.oDrillDownFilters || ''
            ,PROCEDURE_NAME:    props.PROCEDURE_NAME
        }

        // props.loading.set( true, 'ModalFilters - getFiltersFromDB' );
        set_isInnerLoading(true);
        const displayErrorHandler = props.toggleNotifica;
        
        try { // recupero l'elenco dei possibili valori di filtri della colonna da filtrare
            aoReturnedFilters = await FetchService.asyncPost({ url: '/cubes/get-dimensions-filters', params: oParams, displayErrorHandler } );
        } catch (err) {
            console.error(err);
            error = err;
        }
        set_isInnerLoading(false);
        // props.loading.set( false, 'ModalFilters - getFiltersFromDB' );
        if ( error || !aoReturnedFilters ) { props.toggleNotifica( error || 'No data' ); }
        // console.table( aoReturnedFilters );
        setAoFilterList( aoReturnedFilters ); // TODO aggiungere default da settare in caso falsy?

    }
    
    useEffect(() => {
        
        if ( [ 'T', 'P', 'L' ].includes( sFilterType ) ) {

            if ( !props.asCurrentFilters ) {
                getFiltersFromDB();
            }
            
        } else if ( sFilterType === 'R' && asCurrentFilters && asCurrentFilters.length > 0 ) { // se è un range

            const [ firstParam, secondParam ] = asCurrentFilters;

            if ( sFilterDataType === 'D' ) { // data
                setdFilterStartDate( momentLib( firstParam,  sDBdateFormat ));
                setdFilterEndDate(   momentLib( secondParam, sDBdateFormat ));
            } else if ( sFilterDataType === 'N' ) {
                setnFilterStartNum( returnValidNumOrPreviousNumOrEmpty(firstParam ) );
                setnFilterEndNum(   returnValidNumOrPreviousNumOrEmpty(secondParam) );
            } else {
                setnFilterStartNum( firstParam  );
                setnFilterEndNum(   secondParam );
            }
            
        } else if ( sFilterType === 'K' ) {
            
            const [ firstParam ] = asCurrentFilters;
            setsFilterLike(firstParam);

            // const trovaInizio =        /^.*?(?=%)/.exec(firstParam);
            // const trovaCentro = /(?<=%)[^%]*(?=%)/.exec(firstParam);
            // const trovaFine   =   /[^%]*%([^%]*)$/.exec(firstParam );
            
            const r1 = new RegExp(`^.*?(?=${ LIKE_SEPARATOR })`);
            const r2 = new RegExp(`(?<=${ LIKE_SEPARATOR })[^${ LIKE_SEPARATOR }]*(?=${ LIKE_SEPARATOR })`);
            const r3 = new RegExp(`[^${ LIKE_SEPARATOR }]*${ LIKE_SEPARATOR }([^${ LIKE_SEPARATOR }]*)$`);
            
            const trovaInizio = r1.exec(firstParam);
            const trovaCentro = r2.exec(firstParam);
            const trovaFine   = r3.exec(firstParam );
            
            setsFilterLikeInizio( trovaInizio?.[0] || '' );
            setsFilterLikeCentro( trovaCentro?.[0] || '' );
            setsFilterLikeFine(     trovaFine?.[1] || '' );
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    /*
    useEffect( () => {
        getFiltersFromDB();
    }, [bShowAll]);
    */

    useEffect(() => {
        if ( bResetFilter ) {
            handleModalApply();
            setbResetFilter(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bResetFilter]);
    
    useEffect(() => {
        setAsFiltersSelected(asCurrentFilters);
    }, [asCurrentFilters]);

    useEffect(() => {
        oElementFilter.exclude = bExcludeValues;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bExcludeValues]);

    const
        
        handleModalApply           = ()        => {

            if ( ( sFilterType === 'P' ) || ( sFilterType === 'T' ) ) { //filtro di tipo Lista
                
                oElementFilter.asCurrentFilters = getAsValuesFromAo( aoFiltersCustomSelected );
                
            } else if ( sFilterType === 'R' ) { // filtro di tipo Range
                
                if ( sFilterDataType === 'D' ) { // di tipo data
                    
                    oElementFilter.asCurrentFilters = ( dFilterStartDate != null && dFilterEndDate != null )
                        ? [ momentLib(dFilterStartDate).format('YYYYMMDD'), momentLib(dFilterEndDate).format('YYYYMMDD') ]
                        : [];
                    
                } else if ( sFilterDataType === 'N' ) { // di tipo numerico
                    
                    oElementFilter.asCurrentFilters = [];
                    if ( isValidNumber(nFilterStartNum)           ) { oElementFilter.asCurrentFilters[0] = +nFilterStartNum; }
                    if ( isValidNumber(nFilterEndNum  )           ) { oElementFilter.asCurrentFilters[1] = +nFilterEndNum;   } 
                    
                } else { // negli altri casi riporto il valore così com'è
                    
                    if ( ( nFilterStartNum === '' ) || nFilterStartNum ) { oElementFilter.asCurrentFilters[0] = nFilterStartNum; }
                    if ( ( nFilterEndNum   === '' ) || nFilterEndNum   ) { oElementFilter.asCurrentFilters[1] = nFilterEndNum;   }
                    
                }
                
            } else if ( sFilterType === 'L' ) { // filtro di tipo Lista
                
                oElementFilter.asCurrentFilters = asFiltersSelected;
                
            } else if ( sFilterType === 'K' ) { // filtro di tipo Like
                
                oElementFilter.asCurrentFilters = [ sFilterLike ];
                
            }
            
            // se contiene solo elementi falsy (tranne lo zero, che considero valido), allora svuoto l'array
            if ( !( oElementFilter.asCurrentFilters.filter( s => ( s || ( s === 0 ) ) ).length > 0 ) ) {
                oElementFilter.asCurrentFilters = [];
            }
            
            // se l'array è vuoto o non valido, resetto exclude
            if ( !oElementFilter.asCurrentFilters || ( oElementFilter.asCurrentFilters.length === 0 ) ) {
                oElementFilter.exclude = false;
            }
            
            // una delle dimensioni o misure è stata modificata (quella da filtrare) per riferimento
            // e viene quindi ripassata al componente padre assieme alle altre
            props.refreshCubeBuilderState( { aoDimensions: props.aoDimensions, aoMeasures: props.aoMeasures, showFilterModal: false } );

        },
        
        handleModalReset           = ()        => {
            setAsFiltersSelected(      []);
            setAoFiltersCustomSelected([]);
            setdFilterStartDate(       null);
            setdFilterEndDate(         null);
            setnFilterStartNum(        '');
            setnFilterEndNum(          '');
            setsFilterLike(            '');
            setsFilterLikeInizio(      '');
            setsFilterLikeCentro(      '');
            setsFilterLikeFine(        '');
            setbResetFilter(           true);
        },
    
        handleModalClose           = ()        => {
            // in ogni caso alla chiusura della finestra dei filtri devo almeno nascondere l'icona di caricamento
            // (anche se la chiamata al DB continua lo stesso), nascondere la modale stessa e NON ricaricare da DB i dati
            // essendo asCurrentFilters uno stato di CubeBuilder devo svuotarlo all'uscita della modale
            props.loading.set( false, 'ModalFilters - handleModalClose' );
            props.refreshCubeBuilderState({ showFilterModal: false, asCurrentFilters: null }, false );
        },
        
        checkFilterDateOk          = ()        => {
            return momentLib( dFilterStartDate || '' ).isValid() && momentLib( dFilterEndDate || '' ).isValid();
        },

        checkFilterNumberOk        = ()        => (
            isValidNumberOrEmpty( nFilterStartNum ) && 
            isValidNumberOrEmpty( nFilterEndNum   ) &&
            ( ( nFilterStartNum === '' ) || ( nFilterEndNum === '' ) || ( +nFilterStartNum <= +nFilterEndNum ) )
        ),

        filterApplyDisabled        = ()        => {
            return ( sFilterType === 'R' ) && (
                   (  ( sFilterDataType === 'D' ) && !checkFilterDateOk() )
                || (  ( sFilterDataType === 'N' ) && !checkFilterNumberOk() )
            );
        },
    
        handleChangeFilterAddChip  = event     => {
            setsFilterAddChip(event.target.value || '' );
        },
        
        handleChangeFilterLike     = ( event, posizione )     => {
        
            event.preventDefault();
            const val    = event.target.value || '';
            if ( posizione === 'inizio' ) { setsFilterLikeInizio( removeSpecialCharsExceptSpace(val) ); }
            if ( posizione === 'centro' ) { setsFilterLikeCentro( removeSpecialCharsExceptSpace(val) ); }
            if ( posizione === 'fine'   ) { setsFilterLikeFine(   removeSpecialCharsExceptSpace(val) ); }
            
            let   inizio = ( ( posizione === 'inizio' ) ? val : sFilterLikeInizio ) || '';
            let   centro = ( ( posizione === 'centro' ) ? val : sFilterLikeCentro ) || '';
            let   fine   = ( ( posizione === 'fine'   ) ? val : sFilterLikeFine   ) || '';
            
            let risultato = '';
            
            if ( inizio                     ) { risultato = inizio + LIKE_SEPARATOR; }
            
            if ( !inizio && centro          ) { risultato += ( LIKE_SEPARATOR + centro + LIKE_SEPARATOR ); }
            
            if (  inizio && centro          ) { risultato += ( centro + LIKE_SEPARATOR ); }
            
            if ( !inizio && !centro && fine ) { risultato += LIKE_SEPARATOR }
            
            risultato += fine;
            setsFilterLike( risultato );
            
        },
    
        handleAddChip              = ()        => {
            if ( sFilterAddChip !== '' ) {
                setAsFiltersSelected( [ ...new Set([ ...asFiltersSelected, sFilterAddChip ]) ] );
                setsFilterAddChip('');
            }
        },
    
        handleDeleteChip           = sChipName => () => {
            setAsFiltersSelected(asFiltersSelected.filter( sFilter => sFilter !== sChipName ) );
        },

        handleChangeIncludeExclude = event     => {
            setbExcludeValues( !!event.target.checked );
        },
        
        handleToggleShowAll        = ()        => {
            setbShowAll( !bShowAll );
        }

    ;

    const sFilterDesc = ( aoElements.find( oElement => oElement.column === props.sColumnToFilter ) || {} ).description;
    
    const createKeyboardDatePicker = ({ value, onChange, label }) => <KeyboardDatePicker
        variant     ="inline"
        autoOk      ={true}
        format      ="dd/MM/yyyy"
        value       ={value}
        onChange    ={onChange}
        label       ={label}
    />;

    return (
        
        <MuiThemeProvider theme = { themeDialog }>
            
            <Dialog
                open                ={!!props.showFilterModal}
                TransitionComponent ={Transition}
                keepMounted
                onClose             ={handleModalClose}
                aria-labelledby     ="alert-dialog-slide-title"
                aria-describedby    ="alert-dialog-slide-description"
                maxWidth            ={'sm'}
                fullWidth           ={true}
                PaperComponent      ={PaperComponent}
                className           ={ 'filters dialog' + ( bOpacity ? ' opa' : '' ) + ( props.nZoom ? ' zoom' + props.nZoom : '' ) }
            >
                { /* <Resizable className="modal-resizable" defaultSize={{ height: "auto"  }} minWidth={'50vw'} minHeight={'50vh'} maxHeight={'95vh'}> */ }
                <DialogTitle id="alert-dialog-slide-title">
                    { 'Filter: ' + sFilterDesc } 
                    <Tooltip title={ bOpacity ? 'Show' : 'Hide' }><IconButton id="#button-opacity" onClick={() => setbOpacity(!bOpacity)}><BlurOnIcon /></IconButton></Tooltip>
                </DialogTitle>
                <div className="dialog-separator" />
                
                <DialogContent id="alert-dialog-slide-description" className={ 'filtertype ' + sFilterType + ( isReadOnly ? ' readonly ' : '' ) } style={{ minHeight: 150 }}>
                    
                    { isInnerLoading ? <div className="inner-loading"><CircularProgress className="loading-icon-mui" /></div> : null }
                    {
                        ( sFilterType === 'T' || sFilterType === 'P' ) && aoFilterList && ( // elenco Totale o Parziale di valori presi da database
                            <FilterCustom 
                                filterColumnName     ={props.sColumnToFilter}
                                filterType           ={sFilterType}
                                filterDataType       ={sFilterDataType}
                                filterListFromDB     ={aoFilterList}
                                filtersSelected      ={aoFiltersCustomSelected}
                                updateCustomSelected ={setAoFiltersCustomSelected}
                                bExcludeValues       ={bExcludeValues}
                                setbExcludeValues    ={setbExcludeValues}
                                bShowAll             ={bShowAll}
                                handleToggleShowAll  ={handleToggleShowAll}
                                isReadOnly           ={isReadOnly}
                                getFiltersFromDB     ={getFiltersFromDB}
                                oAllFilters          ={ { aoDimensions: [...props.aoDimensions], aoMeasures: [...props.aoMeasures] } }
                            />
                        )
                    }
                    {
                        ( sFilterType === 'R' ) && ( // Range (intervallo di valori)
                            
                            ( sFilterDataType === 'D' ) ? // Data
                                <div className="filters-date">
                                    { getColoredSwitch( bExcludeValues, handleChangeIncludeExclude, isReadOnly ) }
                                    <MuiPickersUtilsProvider libInstance={momentLib} utils={DateFnsUtils} locale={itLocale}>
                                        <Grid container justify="space-around">
                                            { createKeyboardDatePicker({ value: dFilterStartDate, onChange: setdFilterStartDate, label: 'Start date' }) }
                                            { createKeyboardDatePicker({ value: dFilterEndDate,   onChange: setdFilterEndDate,   label: 'End date'   }) }
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                                
                            :  ( sFilterDataType === 'N' ) ? // Numero
                                <div className="filters-num">
                                    <div className="coloredSwitch">{ getColoredSwitch( bExcludeValues, handleChangeIncludeExclude, isReadOnly ) }</div>
                                    <div className="boxContainer">
                                        <label htmlFor ="nFilterStartNum" key="nFilterStartNumlabel">From</label>
                                        <Input id      ="nFilterStartNum"
                                               value   ={ returnValidNumOrPreviousNumOrEmpty( nFilterStartNum ) }
                                               disabled={isReadOnly}
                                               onChange={ (event) => { setnFilterStartNum(returnValidNumOrPreviousNumOrEmpty( event.target.value, nFilterStartNum )  ); } }
                                        />
                                        <label htmlFor ="nFilterEndNum"   key="nFilterEndNumlabel">To</label>
                                        <Input id      ="nFilterEndNum"
                                               value   ={ returnValidNumOrPreviousNumOrEmpty( nFilterEndNum   ) }
                                               disabled={isReadOnly}
                                               onChange={ (event) => { setnFilterEndNum(  returnValidNumOrPreviousNumOrEmpty( event.target.value, nFilterEndNum ) ); } }
                                        />
                                    </div>
                                </div>
                                
                            :  ( sFilterDataType === 'C' ) ? // Caratteri
                                <div className="filters-str">
                                    <div className="coloredSwitch">{ getColoredSwitch( bExcludeValues, handleChangeIncludeExclude, isReadOnly ) }</div>
                                    <div className="boxContainer">
                                        <label htmlFor ="nFilterStartNum" key="nFilterStartNumlabel">From</label>
                                        <Input id      ="nFilterStartNum"
                                               value   ={ nFilterStartNum }
                                               disabled={isReadOnly}
                                               onChange={ (event) => { setnFilterStartNum(event.target.value ); } }
                                        />
                                        <label htmlFor ="nFilterEndNum"   key="nFilterEndNumlabel">To</label>
                                        <Input id      ="nFilterEndNum"
                                               value   ={ nFilterEndNum }
                                               disabled={isReadOnly}
                                               onChange={ (event) => { setnFilterEndNum( event.target.value ); } }
                                        />
                                    </div>
                                </div>
                                
                            : ''
                            
                        )
                    }
                    {
                        ( sFilterType === 'L' ) && ( // Lista semplice di valori inseriti dall'utente
                            <div>

                                { getColoredSwitch( bExcludeValues, handleChangeIncludeExclude, isReadOnly ) }

                                <div className="add-filter">
                                    <TextField
                                        name     ="filterAddChip"
                                        value    ={sFilterAddChip}
                                        label    ="New item"
                                        onChange ={handleChangeFilterAddChip}
                                        style    ={{marginBottom: 10}}
                                    />
                                    <Tooltip title="Add new item">
                                        <AddIcon onClick={handleAddChip}/>
                                    </Tooltip>
                                </div>

                                <Paper component="ul" style={{
                                    listStyle:      'none',
                                    display:        'flex',
                                    justifyContent: 'left',
                                    flexWrap:       'wrap',
                                    padding:        0,
                                    margin:         0
                                }}>
                                    { asFiltersSelected.map( ( sFilter, index) =>
                                        <li key={'li'+sFilter+index}>
                                            <Chip
                                                key     ={'chip'+sFilter+index}
                                                label   ={sFilter}
                                                onDelete={handleDeleteChip(sFilter)}
                                                style   ={{margin: 3}}
                                            />
                                        </li>
                                    )}
                                </Paper>
                                
                            </div>
                        )
                    }
                    {
                        ( sFilterType === 'K' ) && ( // per il LIKE
                            <div>
                                
                                <div>Specify the start, content or end of the wanted results.</div>
                                <div className="coloredSwitch">{ getColoredSwitch( bExcludeValues, handleChangeIncludeExclude, isReadOnly ) }</div>
                                <div>
                                    <TextField
                                        name     ="filterLikeInizio"
                                        value    ={sFilterLikeInizio}
                                        label    ="Start"
                                        onChange ={ (event) => { handleChangeFilterLike( event, 'inizio' ) } }
                                        style    ={{marginBottom: 10}}
                                    />
                                    <TextField
                                        name     ="filterLikeCentro"
                                        value    ={sFilterLikeCentro}
                                        label    ="Content"
                                        onChange ={ (event) => { handleChangeFilterLike( event, 'centro' ) } }
                                        style    ={{marginBottom: 10, marginLeft: 10}}
                                    />
                                    <TextField
                                        name     ="filterLikeFine"
                                        value    ={sFilterLikeFine}
                                        label    ="End"
                                        onChange ={ (event) => { handleChangeFilterLike( event, 'fine' ) } }
                                        style    ={{marginBottom: 10, marginLeft: 10}}
                                    />
                                </div>
                                
                            </div>
                        )
                    }
                
                </DialogContent>

                <DialogActions>
                    {
                        !isReadOnly && <>
                            <Tooltip title="Save changes and Exit">
                                <Button key="apply" onClick={handleModalApply} color="primary" disabled={ filterApplyDisabled() }>apply</Button>
                            </Tooltip>
                            <Tooltip title="Deselect all elements, Save and Exit">
                                <Button key="reset" onClick={handleModalReset} color="secondary">reset</Button>
                            </Tooltip>
                        </>
                    }
                    <Tooltip title="Exit without saving changes">
                        <Button onClick={handleModalClose} color="secondary">cancel</Button>
                    </Tooltip>
                </DialogActions>
                { /* </Resizable> */ }
            </Dialog>
            
        </MuiThemeProvider>
        
    )
    
}
