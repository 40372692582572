import React        from 'react';
import              './FilterCustom.css';
import {
    Switch,
    TextField,
    Paper,
    Checkbox,
    Chip,
    Tooltip
}                   from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import WarningIcon  from '@material-ui/icons/Warning';
import config       from '../../../config';
import Utils        from '../../../common/CommonUtilities';

const nFiltersPageRows    = config.PAGE_ROWS     || 50;
const nFiltersSelectLimit = config.FILTERS_LIMIT || 50;

const ColoredSwitch = withStyles({
    switchBase: {
        color: 'grey',
        '&$checked': {
            color: 'grey',
        },
        '&$checked + $track': {
            backgroundColor: 'grey',
        },
    },
    checked: {},
    track: {},
})(Switch);

/**
 * serve per unire due array di oggetti (con identica struttura) senza ripetere i valori uguali
 * oppure (con parametro minus = true) per rimuovere da un array di oggetti di origine gli elementi appartenenti ad un secondo array
 * @param filtersOrigin
 * @param filtersToAddOrRemove
 * @param filterType
 * @param minus
 * @returns {*[]}
 */
const mergeFiltersExceptions = ( filtersOrigin, filtersToAddOrRemove, filterType, minus = false ) => {
    
    // con minus
    // partendo da un array vuoto
    // aggiungo all'array finale i soli valori presenti nell'array di origine, tranne quelli presenti in filtersToAddOrRemove
    
    // senza minus (quindi merge)
    // partendo dall'array di origine
    // se non c'è già un valore di filtersToAddOrRemove, aggiungo quel valore all'array finale
    
    const sCol  = Utils.GETsObjectFirstKeyFromAO( [ ...filtersOrigin, ...filtersToAddOrRemove ] );
    let filters = [];
    
    if ( minus ) {
        
        const filtersValues = filtersToAddOrRemove.map( filter => filter[sCol] + '' );
        filtersOrigin.forEach( filter => {
            if ( !( filtersValues.includes( filter[sCol] + '' ) ) ) {
                filters.push(filter);
            }
        });
        
    } else {
        
        filters = [ ...filtersOrigin ];
        const filtersValues = filters.map( filter => filter[sCol] + '' );
        filtersToAddOrRemove.forEach( filter => {
            if ( !( filtersValues.includes( filter[sCol] + '' ) ) ) {
                if ( filters.length < nFiltersSelectLimit ) {
                    if ( filterType !== 'P' ) { // nel caso dei filtri di tipo Parziale
                        filter.EXCEPTION = true; // non posso indicarli come eccezioni ignorate, perché l'estrazione è limitata
                    }
                    filters.push(filter);
                }
            }
        });
        
    }
    
    const transform = (numberOrString) => Number.isNaN(+numberOrString) ? ( numberOrString + '' ).toUpperCase() : +numberOrString;
    
    filters.sort( ( a, b ) => {
        const actual = transform(a[sCol]);
        const next   = transform(b[sCol]);
        if ( actual < next ) { return -1; }
        if ( actual > next ) { return 1; }
        return 0; // a must be equal to b
    } );
    
    return filters;
    
}

class FilterCustom extends React.Component {

    constructor(props) {
        super(props);
        /* props passate a FilterCustom:
                filterType              stringa  tipo di filtro  ES. 'T' o 'P'
                filterDataType          stringa  tipo di dati    ES. 'C', 'N', 'D', 'M', 'T', 'S'
                filterList              elenco completo dei valori da database
                filtersSelected         elenco dei soli valori selezionati
                updateCustomSelected
                bExcludeValues
                setbExcludeValues
                bShowAll                funzionalità disabilitata
                handleToggleShowAll     funzionalità disabilitata
                isReadOnly              per elencare i filtri di una dimensione in un layout
                getFiltersFromDB        per ricaricare da database i filtri di tipo filterType === 'P'
                oAllFilters             oggetto contenente tutte le dimensioni e misure
        */


        // array di oggetti     risultato query Es. [ { ADVERTISER_NAME: 'FIAT' }, { ADVERTISER_NAME: 'TESLA' } ]
        const filters = mergeFiltersExceptions( props.filterListFromDB, props.filtersSelected, props.filterType );

        this.state = {
            filterListMerged:    filters,
            filterListToDisplay: filters,
            filtersSelected:     props.filtersSelected, // valori già selezionati in precedenza. Es. [ { ADVERTISER_NAME: 'TESLA' } ]
            filterColumnValues:  [ Utils.GETsObjectFirstKeyFromAO( props.filterListFromDB ) ], // Es. [ 'ADVERTISER_NAME' ]
            overSelectedItem:    false,
            bShowTable:          true,
            bInitGrid:           false,
            bFirstInitGrid:      true,
            sValueToFilter:      '',
            height:              0,
            checkAll:            false
        }

    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ height: window.innerHeight - 125 });
        });
    }

    async componentDidUpdate ( prevProps, prevState) {

        // if ( JSON.stringify(this.props) !== JSON.stringify(prevProps) ){
        //    console.log( 'props: ', Utils.getDifference(prevProps, this.props ) );
        // }

        if (
                ( this.props.filterListFromDB !== prevProps.filterListFromDB )
        ){
            // console.log( 'componentDidUpdate: props filterListFromDB o filtersSelected cambiate' );
            // console.log( this.props.filtersSelected );
            // console.log( '------------------------------------------------------' );

            const filters = mergeFiltersExceptions( this.props.filterListFromDB, this.props.filtersSelected, this.props.filterType );

            this.setState(({ sValueToFilter }) => ({
                 nTotalFilterValues:  ( this.props.filterListFromDB.find( oRow => oRow.CC_TOT ) || {} ).CC_TOT
                ,filterListMerged:    filters
                ,filterListToDisplay: ( sValueToFilter && this.props.filterType === 'P' ) ? this.props.filterListFromDB : filters
                ,filterColumnValues:  [ Utils.GETsObjectFirstKeyFromAO( filters ) ]
                ,bInitGrid:           !this.state.bInitGrid
                ,bShowTable:          false
                ,overSelectedItem:    false
            }));

        }

        if ( this.props.filtersSelected !== prevProps.filtersSelected ) {
            this.setState({ filtersSelected: this.props.filtersSelected });
        }

        if ( this.state.bInitGrid !== prevState.bInitGrid ) {

            await Utils.setStateAsync({ bShowTable: true }, this);
            await Utils.setStateAsync({
                height:         this.divElement.clientHeight + ( this.state.bFirstInitGrid ? 2 : 0 ),
                bFirstInitGrid: false
            }, this);

        }

        // if ( JSON.stringify(this.state) !== JSON.stringify(prevState) ){
        //     console.log( 'state: ',  Utils.getDifference(prevState, this.state ) );
        // }

    }


    handleChangeCheck           = ( checked, oRow ) => {

        const sFilterColumnName = this.state.filterColumnValues[0];
        let filtersSelected     = this.state.filtersSelected;
        if ( checked === true ) {
            if ( oRow ) {
                filtersSelected.push( oRow );
            }
        } else {
            filtersSelected = this.state.filtersSelected.filter( oFilter => ( oFilter[sFilterColumnName] + '' ) !== ( oRow[sFilterColumnName] + '' ) )
        }

        this.props.updateCustomSelected([...filtersSelected]);
        this.setExpandStatus(filtersSelected);

    }

    handleChangeCheckAll = () => {
        const filtersSelected   = (
            this.state.checkAll
                // togliere da this.state.filtersSelected i soli elementi in comune con this.state.filterListToDisplay
            ? mergeFiltersExceptions( this.state.filtersSelected, this.state.filterListToDisplay, 'P', true )
            : mergeFiltersExceptions( this.state.filtersSelected, this.state.filterListToDisplay, 'P' )
        );
        this.props.updateCustomSelected([...filtersSelected]);
        this.setExpandStatus(filtersSelected);
        this.setState({ checkAll: !this.state.checkAll });
    }
    
    handleDeleteChip            = ( event, itemToDelete ) => {
        let filtersSelected = this.state.filtersSelected;

        const filteredSelectedItems = filtersSelected.filter(function(item) {
            return item !== itemToDelete
        });

        this.setState({
            filtersSelected: filteredSelectedItems
        });

        this.props.updateCustomSelected(filteredSelectedItems);
        this.setExpandStatus(filteredSelectedItems);
    };

    setExpandStatus             = ( filterList ) => {
        if(filterList.length === 0){
            this.setState({
                overSelectedItem: false,
            })
        }
    }

    toggleSelectedItems         = () => {
        if(this.state.filtersSelected.length > 0) {
            this.setState({
                overSelectedItem: !this.state.overSelectedItem,
            });
        }
    }

    handleChangeIncludeExclude  = ( event ) => {
        this.props.setbExcludeValues( !!event.target.checked );
    }

    createTableRows             = ( aoRows, filtersSelected, filterCodeField ) => {
        return aoRows.map( oRow => {
            const cellValue = oRow[filterCodeField];
            const checked   = !!filtersSelected.find( oFilter => ( oFilter[filterCodeField] + '' ) === ( cellValue + '' ) );
            const cellValueToDisplay = Utils.convertDataType( cellValue, this.props.filterDataType );
            return <tr key={ cellValue } className={ oRow.EXCEPTION ? 'not-available' : '' }>
                <td><div title={ ( cellValueToDisplay === 0 ) ? 0 : ( cellValueToDisplay || '' ) } >{
                    <Checkbox
                        checked  ={ !!checked }
                        onChange ={ (event) => this.handleChangeCheck( !!event.target.checked, oRow ) }
                        color    ="primary"
                        disabled ={ !checked && ( filtersSelected.length >= nFiltersSelectLimit ) }
                    />
                }{ cellValueToDisplay }</div></td>
            </tr>
        });
    }

    handleFilterValues          = async (sValueToFilter) => {
        this.setState({ checkAll: false });
        const sValueToFilterPurged = Utils.removeSpecialChars( sValueToFilter ) ;
        if ( this.props.filterType === 'P' ) {
            this.setState(  { sValueToFilter } );
            Utils.debounce( this.props.getFiltersFromDB, 750 )( sValueToFilterPurged );
        } else { // filterType === 'T'
            this.setState( ({ filterListMerged, filterColumnValues }) => ({
                sValueToFilter,
                filterListToDisplay: filterListMerged.filter(
                    oFilter => Utils.removeSpecialChars(oFilter[ filterColumnValues[0] ]).includes( sValueToFilterPurged )
                )
            }));
        }
    }
    
    createTable                 = ({ aoRows, filtersSelected, filterCodeField }) => {
        // console.log('---------------------------------------');
        // console.log('props.filterListFromDB');
        // console.table(        this.props.filterListFromDB );
        // console.log('state.filterList');
        // console.table(this.state.filterListMerged);
        // console.log({
        //      '1 this.props.filterType':         this.props.filterType
        //     ,'2 this.props.filterListFromDB.length':  this.props.filterListFromDB.length
        //     ,'3 nFiltersLimit':                 nFiltersLimit
        //     ,'4 this.state.nTotalFilterValues': this.state.nTotalFilterValues
        // });
        return <Paper className="grid-wrapper for-filters">
            <table>
                <thead>
                    <tr>
                        <th>
                            <div className="check-all-filter-row">
                                
                                <Tooltip
                                    title={
                                        ( this.state.checkAll ? 'Deselect' : 'Select' ) + (
                                            ( !this.state.checkAll && ( this.state.filterListToDisplay.length > nFiltersSelectLimit ) )
                                            ? ( ' the first ' + nFiltersSelectLimit + ' (max selectable)' )
                                            : ( ' all ' + ( this.state.checkAll ? '' : this.state.filterListToDisplay.length ) )
                                        ) + ' listed elements'
                                    }
                                    placement="top"
                                >
                                    <Checkbox
                                        checked     ={ !!this.state.checkAll }
                                        onChange    ={ this.handleChangeCheckAll }
                                        color       ="primary"
                                        className   ={ this.state.filterListToDisplay.length ? '' : 'invisible'  }
                                    />
                                </Tooltip>
                                
                                <TextField
                                    id          ="filter-input"
                                    type        ="text"
                                    variant     ="standard"
                                    value       ={ this.state.sValueToFilter }
                                    onChange    ={ event => { this.handleFilterValues(event.target.value) } }
                                    placeholder ="Filter..."
                                    fullWidth
                                    autoFocus
                                    autoComplete="off"
                                />
                                
                            </div>
                            {/* InputProps={{ endAdornment: ( <InputAdornment position="end"> <span>Cc</span> <span>W</span> <span>*</span> </InputAdornment> ) }} */}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (
                               ( this.props.filterType === 'P' )
                            && ( this.props.filterListFromDB.length === nFiltersPageRows )
                            && ( this.state.nTotalFilterValues > nFiltersPageRows )
                        )
                        ? <tr key="warning" className="warning-limit">
                            <td>Displaying the first { nFiltersPageRows } items</td>
                          </tr>
                        : null
                    }
                    {
                        !aoRows?.length
                        ? <tr><td><div>
                                    <Checkbox
                                        checked={false}
                                        className="invisible"
                                        color="primary"
                                        disabled={true}
                                    />( NO DATA )</div></td></tr>
                        : this.createTableRows(aoRows, filtersSelected, filterCodeField)
                    }
                </tbody>
            </table>
        </Paper>
    }
    
    handleExpandHeader = () => {
        this.setState(({bHeaderExpanded}) => ({bHeaderExpanded: !bHeaderExpanded}));
    }
    
    render() {
        
        const {
            filterListMerged,
            filterListToDisplay,
            filtersSelected,
            bShowTable,
            overSelectedItem,
            filterColumnValues,
            height,
            nTotalFilterValues
        } = this.state;
        
        const bExcludeValues = this.props.bExcludeValues;
        const filterCodeField   = filterColumnValues[0];
        
        const filterList        = ( ao ) => [...ao]
            .filter( o => o.asCurrentFilters.length && ( o.column !== this.props.filterColumnName ) )
            .map( o => <div key={o.description}>{ o.description }</div> )
        ;
        
        const dimFilteredList   = filterList( this.props.oAllFilters.aoDimensions );
        const meaFilteredList   = filterList(this.props.oAllFilters.aoMeasures    );
        
        // console.log(filterList);        // [ {CHANNEL_DESC: 'CANALE 5'}, {CHANNEL_DESC: 'CNN'} ]
        // console.log(columns);           // [ {name: 'CHECK', title: ' '}, {name: 'CHANNEL_DESC', title: 'Description'} ]
        // console.log(filtersSelected);   // [ {CHANNEL_DESC: 'CANALE 5'} ]
        // console.log(filterCodeField);   // 'CHANNEL_DESC'

        return (

            <div
                className="filter-custom"
                ref={ (divElement) => { this.divElement = divElement } }
                style={ height ? { height } : {} }
            >

                <Paper className={
                    ( overSelectedItem && filtersSelected.length > 0 ? 'selected-items-over' : 'selected-items' )
                    + ( this.props.isReadOnly ? ' showAll' : '' )
                }>

                    { !this.props.isReadOnly &&
                        <div className={ ( filtersSelected.length === 0 ) ? 'expand-selected disabled' : 'expand-selected' } >
                            {
                                overSelectedItem
                                    ?
                                        <Tooltip title="Compress selected items" placement="top">
                                            <i className="fas fa-compress" onClick={this.toggleSelectedItems}/>
                                        </Tooltip>
                                    :
                                        <Tooltip title="Expand selected items" placement="top">
                                            <i className="fas fa-expand"   onClick={this.toggleSelectedItems}/>
                                        </Tooltip>
                            }
                        </div>
                    }

                    <div className="selected-items-label">
                        <span>
                            <span>{ `Selected items: ${filtersSelected.length}` }</span>
                            { !this.props.isReadOnly && nTotalFilterValues &&
                                <span>&nbsp;of {
                                    ( filterListMerged.length > nTotalFilterValues ) ? filterListMerged.length : nTotalFilterValues
                                }</span>
                            }
                        </span>
                    </div>

                    { filtersSelected.map( ( oFilter, nIndex ) =>
                        <Tooltip key={'f'+nIndex} title={ oFilter.EXCEPTION ? 'This filter is ignored' : '' /* but it was previously selected */ }>
                            <Chip
                                label    ={ Utils.convertDataType( oFilter[filterCodeField], this.props.filterDataType ) }
                                onDelete ={ !this.props.isReadOnly ? ((event) => this.handleDeleteChip( event, oFilter )) : undefined }
                                className={ 'chip' + ( oFilter.EXCEPTION ? ' not-available' : '') }
                            />
                        </Tooltip>
                    )}

                </Paper>

                <div className={ ( overSelectedItem && filtersSelected.length > 0 ) ? 'base-item-over' : 'base-item' } />

                <span className="section-label">

                    <div>
                        <label className={ 'include' + ( bExcludeValues  ? '' : ' checked' ) }>Include</label>
                        <ColoredSwitch
                            className="myswitch" color="primary" onChange={ this.handleChangeIncludeExclude }
                            checked={ !!bExcludeValues } disabled={this.props.isReadOnly}
                        />
                        <label className={ 'exclude' + ( !bExcludeValues ? '' : ' checked' ) }>Exclude</label>
                    </div>
                    
                    { ( !dimFilteredList?.length && !meaFilteredList?.length ) ? null :
                        <div><Tooltip title={
                            <>
                                <div>This list is affected by the filters:</div><br/>
                                { !dimFilteredList?.length ? null : ( <>
                                    <div>[&nbsp;Dimensions&nbsp;]</div>
                                    { dimFilteredList }
                                    <br/>
                                </> ) }
                                { !meaFilteredList?.length ? null : ( <>
                                    <div>[&nbsp;Measures&nbsp;]</div>
                                    { meaFilteredList }
                                </> ) }
                            </>
                        }><WarningIcon className="warningIcon"/></Tooltip></div>
                    }

                </span>

                { /* --- funzionalità nascosta ---
                <Tooltip title={ 'Show all values without any filter applied' }>
                    <FormControlLabel
                        className="checkShowAll"
                        control={
                                <Checkbox
                                    checked  ={this.props.bShowAll}
                                    onChange ={this.props.handleToggleShowAll}
                                    color    ="primary"
                                />
                        }
                        label="Show All"
                    />
                </Tooltip>
                */ }

                { !this.props.isReadOnly && bShowTable && this.createTable( 
                    { aoRows: filterListToDisplay, filtersSelected, filterCodeField }
                ) }

            </div>

        )

    }

}

export default FilterCustom;
