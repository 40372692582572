import { useEffect, useState }  from 'react';
import                          './CubeTable.css';
import Utils                    from '../../../common/CommonUtilities';
import ArrowUpwardIcon          from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon        from '@material-ui/icons/ArrowDownward';

const bDebug = false;

// FIX per risolvere il problema noto dei decimali (vedere sito https://0.30000000000000004.com )
function formatNum( value, decimals = 2, sZero = 0 ) {
    // const preciseRound = ( num, dec ) => +( Math.round(num + ( 'e+' + dec ) )  + ( 'e-' + dec ) ); // preciseRound( 1.005, 2 ) === 1.01
    // return value.toFixed( decimals )
    return Utils.formatNumberWithOptions( 
        value.toFixed( decimals ),
        { nOuputDecimals: decimals, sZero }
    );
}

/* per debug
function usePrevPropValue(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
*/

export function CubeTable( props ) {
    
    const sRowTotalLabel   = '<ROW TOTAL>';
    const sGrandTotalLabel = '<GRAND TOTAL>';
    
    const {

         aoQueryResults: aoQueryResultsFull
        ,aoAllDimensions            // aoDimensions = [ { column: 'CHANNEL_DESC', selected: true, sortDirection: 'ASC' } ];
        ,aoAllMeasures
        ,anMeasureColumns

        ,bTabularMode
        ,bInvertPivot
        ,bRowTotal
        ,drillDown

        ,refreshCubeBuilderState
        ,sSubTotals          = 'top' // 'top' (default), 'bottom' oppure '' (no subtotali)
        ,nInvertSortPivot    = 1     // 1 o -1
        ,bShowAllValues

    } = props;

    // aoQueryResultsFull = aoQueryResultsFull.slice( 0 ,85 ); // solo per debug
    // bDebug && console.table( aoQueryResultsFull.slice( 0,85 ) );
    
    // per debug
    // const prevProps = usePrevPropValue({...props});
    // Utils.logDifferences( '--- CubeTable (cambio di props) ---', prevProps, props );

    // bDebug && console.table( aoAllDimensions.filter( d => d.selected ) );
    
    const
         [ nRowSelected, setnRowSelected ] = useState(-1)
        ,[ tableElement, setTableElement ] = useState(<table><tbody><tr><th></th></tr></tbody></table>)
        ,isGraphEnabled = false
        ,noValue        = '-'
        ,fbIsPivotValue = ( PROG_ID ) => +PROG_ID === -1 // solo se c'è una dimensione pivot
        ,zeroOrNeg      = ( value   ) => value === 0 ? ' zero' : ( value < 0 ? ' negative' : '' )
    ;
    
    const createTableElement = () => {
        
        const
             aoDimensionsSelected   = aoAllDimensions.filter( d => d.selected && !d.pivoted )
            ,aoDimensions           = bTabularMode ? aoDimensionsSelected : aoDimensionsSelected.slice(-1)  // il TabularMode viene gestito qui
            ,oPivotDimension        = aoAllDimensions.find( oDimension => oDimension.pivoted ) || {}
            ,sPivotDimension        = oPivotDimension.column || ''
            ,aoMeasures             = aoAllMeasures.filter( m => m.selected )
            ,asMeasureCols          = [ '[#]', '[%]', '[Δ]', '[Δ%]' ]
            ,asMeasureColClasses    = [ 'COLnumber', 'COLperc', 'COLdelta', 'COLdeltaperc' ]
            ,[ oGrandTotal, ...aoResultsWithSubtotals ] = aoQueryResultsFull
            ,firstIndexPositive     = aoResultsWithSubtotals.findIndex( o => o.PROG_ID > 0 )
            ,aoSubtotals            = aoResultsWithSubtotals.slice( 0, firstIndexPositive )
            ,aoQueryResults         = aoResultsWithSubtotals.slice( firstIndexPositive )

            ,aoColDimensionValues   = !sPivotDimension
                                          ? []
                                          : aoSubtotals
                                              .filter( oRecord => fbIsPivotValue(oRecord.PROG_ID) )
                                              .map(    oRecord => ({ column: oRecord[sPivotDimension], description: oRecord[sPivotDimension], ...oRecord }) )
                                              .sort( (a,b) =>
                                                  ( a.description < b.description )
                                                      ? ( -1 * nInvertSortPivot )
                                                      : ( a.description > b.description ? nInvertSortPivot : 0 )
                                              )
            ,rigaExtra              = ( anMeasureColumns.length > 1 ) || !!sPivotDimension
            ,nFixedColumnWidth      = 350
            ,oStyleFixedcolumnWidth = ( nColumnWidth = nFixedColumnWidth ) => ({
                maxWidth: ( !bTabularMode ? nFixedColumnWidth : nColumnWidth )
             })
        ;
    
        if ( sPivotDimension && bRowTotal ) {
            const oSubtotalsTotRiga             = { ...aoSubtotals[0] } || {};
            oSubtotalsTotRiga.column            = sRowTotalLabel;
            oSubtotalsTotRiga.description       = sRowTotalLabel;
            oSubtotalsTotRiga[sPivotDimension]  = sRowTotalLabel;
            aoColDimensionValues.unshift(oSubtotalsTotRiga);
        }

        // bDebug && console.log('sPivotDimension: "'+ sPivotDimension + '"');
        // bDebug && console.log('aoColDimensionValues: "'+ aoColDimensionValues.map( o => o.description ).filter(Boolean).join(',') + '"');

        let head1, head2, headIntColonna1 = [], headIntColonna2 = [];
        
        // svuoto l'ordinamento per tutte le altre misure (solo UNA alla volta può essere ordinata)
        function resetOrderForMeasures( sMeasureCode ) {
            aoAllMeasures.forEach( m => {
                if ( m.column !== sMeasureCode ) {
                    m.sortDirection = '';
                    m.pSortingValue = '';
                }
            });
        }

        // resetto l'ordinamento per tutte le dimensioni
        function resetOrderForDimensions() {
            aoAllDimensions.forEach( d => { d.sortDirection = 'ASC'; });
        }

        function onClickSortCol( event ) {

            const
                 target               = event.currentTarget
                ,sColumnName          = target.getAttribute('data-column-name'   ) // recupero il nome della colonna da ordinare
                ,pSortingValue        = target.getAttribute('data-pivot-value'   ) // recupero il valore della colonna pivot da ordinare
                ,sActualSortDirection = target.getAttribute('data-sort-direction') // recupero lo stato attuale dell'icona ( 'ASC' o 'DESC' )
                ,oMeasure             = aoMeasures.find(   oMeasure => oMeasure.column === sColumnName )
                ,isOneMeasureOrdered  = !!aoMeasures.find( oMeasure => oMeasure.sortDirection )
            ;
            // console.log( 'onClickSortCol sColumnName: "' + sColumnName + ' ' + sActualSortDirection );

            if ( oMeasure ) { // se è una misura

                resetOrderForDimensions();
                // al 1° click su una misura senza sortDirection di default è DESC, altrimenti un normale toggle
                oMeasure.sortDirection = !sActualSortDirection || ( sActualSortDirection === 'ASC' ) ? 'DESC' : 'ASC';                    
                oMeasure.pSortingValue = pSortingValue;
                resetOrderForMeasures( oMeasure.column );

            } else { // altrimenti se è una dimensione

                resetOrderForMeasures('');

                if ( isOneMeasureOrdered ) {
                    resetOrderForDimensions();
                } else {

                    const oDimension = aoDimensions.find( oDimension => oDimension.column === sColumnName );
                    if ( oDimension ) {
                        oDimension.sortDirection = ( sActualSortDirection === 'ASC' ) ? 'DESC' : 'ASC';
                    }

                }

            }
            
            refreshCubeBuilderState({ aoDimensions: aoAllDimensions, aoMeasures: aoAllMeasures });

        }

        function assignSortIcon( { sColumnName, pSortingValue } ) {
            // al primo caricamento di questo componente recupero dal padre lo stato attuale di ordinamento
            
            const
                 oDimension                 = aoDimensions.find( oDimension => oDimension.column === sColumnName )
                ,oMeasure                   = aoMeasures.find(   oMeasure   => oMeasure.column   === sColumnName )
                ,oMeasureOrdered            = aoMeasures.find(   oMeasure   => oMeasure.sortDirection )
                ,measureOrderedSortingValue = ( oMeasureOrdered || {} ).pSortingValue
                ,isOneMeasureOrdered        = !!oMeasureOrdered // se una misura è ordinata E ( se ha il pSortingValue è incluso nei risultati )
                                              && (
                                                  !measureOrderedSortingValue
                                                  || aoColDimensionValues.some( oCol => ( oCol.description + '' ) === measureOrderedSortingValue )
                                              )
                ,sSortDirection             = oDimension
                    ? ( oDimension.sortDirection  ) // se è una dimensione prendo la sortDirection
                    : (
                        oMeasure && // se è una misura
                        // se è previsto un pSortingValue (nel caso pivot)
                        // verifico se pSortingValue è uguale a quello della misura (come stringhe)
                        // infine restituisco la sortDirection della misura
                        ( ( oMeasure.pSortingValue || '' ).toString() === ( pSortingValue || '' ).toString() ) && oMeasure.sortDirection 
                    )
                
                // classe css che serve proprio per "accendere" l'icona del sorting
                ,active                     = ( oDimension  && !isOneMeasureOrdered && oDimension.sortDirection )
                                              || ( oMeasure && isOneMeasureOrdered  && oMeasure.sortDirection   )
                                              ? ' active' : ''
                
                ,classSortingType = ( sSortDirection === 'ASC'  ) ? 'ascending' : 'descending'
                ,oArrowProps      = {
                     'data-column-name'    : sColumnName
                    ,'data-pivot-value'    : pSortingValue
                    ,'data-sort-direction' : sSortDirection || ''
                    ,'className'           : 'sortIcon ' + classSortingType + active
                    ,'onClick'             : onClickSortCol
                }
            ;
            
            // in ogni caso viene renderizzata l'icona (ma visibile solo al passaggio del mouse) per effettuare il sort, di fianco al nome della colonna
            return ( sSortDirection === 'ASC' )
                ? <span title={ 'Sort ' + classSortingType } ><ArrowUpwardIcon   { ...oArrowProps } /></span>
                : <span title={ 'Sort ' + classSortingType } ><ArrowDownwardIcon { ...oArrowProps } /></span>
            ;

        }

        function onClickDrillIcon( event, oRecord ) {

            event.stopPropagation();
            const sSelectedDimensionDrillDown = event.currentTarget.getAttribute('data-column-name');
            setnRowSelected(0);
            drillDown( { oRecord, sSelectedDimensionDrillDown } );

        }

        // -----------------------------------------------------------------------------------------------------------------
        // RIGA 1 (intestazioni delle colonne) Esempio: "Advertiser | Gross # | Spot Price # | Spot Length #"

        // ciclo sulle dimensioni (es. CHANNEL, ADVERTISER... )
        const headIntRiga = aoDimensions.map( ( { column, description: dimensionDesc, columnWidth /* filterDataType */ }, nIndex ) =>
            // es. {"code":"CHANNEL_CODE","column":"CHANNEL_DESC","description":"Channel","columnWidth":250,"filterDataType":"C"}
            // prime celle in alto a sinistra. es. 'Channel | Advertiser'
            <th
                key       ={ 'headIntRiga' + column + nIndex }
                className ={ 'dimension '  + column }
                style     ={ { minWidth: ( !bTabularMode ? nFixedColumnWidth : 'unset' ), ...oStyleFixedcolumnWidth(columnWidth) } }
            >{ dimensionDesc }{ assignSortIcon( { sColumnName: column } ) }</th>
        );

        let headIntRiga1 = rigaExtra ? aoDimensions.map( ( o, n ) => <th key={ 'headIntRiga1' + o.column + n } className="dimension"/>) : headIntRiga;
        let headIntRiga2 = rigaExtra ? headIntRiga : null;
        
        headIntColonna1 = [];
        
        // es. colDimensionDesc = '2019' (in caso di pivot, altrimenti vuoto)
        // es. oMeasure         = { code: 'GROSS', description: 'Gross', columnWidth: 150, decimals: 2 }
        
        const
             ao1stRowValues = bInvertPivot ? aoColDimensionValues   : aoMeasures
            ,ao2ndRowValues = bInvertPivot ? aoMeasures             : aoColDimensionValues
            ,s1stRowStart   = bInvertPivot ? ' colDimensionStart '  : ' measureStart ' 
            ,s2ndRowStart   = bInvertPivot ? ' measureStart '       : ' colDimensionStart '
            ,formatValue    = val => (
                ( ( val !== 0 ) && !val )
                    ? ''
                    : ( val  === sRowTotalLabel )
                        ? val
                        : ( Utils.convertDataType( val, oPivotDimension.filterDataType ) + ' ' )
            )
            ,nTotalMeasuresSummables = ao2ndRowValues.reduce( ( nTot, o ) => nTot + ( o.summable === 'Y' ? 1 : 0 ) ,0 )
        ;
        
        // ciclo sulle misure (es. GROSS, SPOT PRICE... )
        ao1stRowValues.forEach( ( { column: s1stRowColumnName, description: s1stRowDesc, summable: s1stRowSummable }, n1stRowElement ) => {
        
            const s1stRowDescToDisplay  = bInvertPivot ? formatValue(s1stRowDesc) : s1stRowDesc;
            let 
                 s1stStart              = s1stRowStart
                ,removePerc             = bRowTotal && anMeasureColumns.includes(1) ? -1 : 0 // versione per colonne nascoste
                ,removeDelta            = bRowTotal && anMeasureColumns.includes(2) ? -1 : 0 // versione per colonne nascoste
                ,removeDeltaPerc        = bRowTotal && anMeasureColumns.includes(3) ? -1 : 0 // versione per colonne nascoste
                ,removeTotalRow         = bRowTotal && ( s1stRowSummable !== 'Y' )  ? -1 : 0
            ;
            
            let nColSpan                = !bInvertPivot 
                                            ? ( anMeasureColumns.length * ( aoColDimensionValues.length || 1 ) )
                                            : ( anMeasureColumns.length * ( ao2ndRowValues.length       || 1 ) )
            ;
            
            nColSpan += !bInvertPivot ? ( removeTotalRow + removePerc + removeDelta + removeDeltaPerc ) : 0;
            
            if ( rigaExtra ) { // es. GROSS, SPOT PRICE
                headIntColonna1.push(
                    <th
                        key       ={ 'headIntColonna1' + s1stRowColumnName + n1stRowElement }
                        colSpan   ={ ( s1stRowColumnName === sRowTotalLabel ) ? nTotalMeasuresSummables : nColSpan }
                        className ={ 'measure ' + s1stRowColumnName + s1stStart }
                    >{ s1stRowDescToDisplay }</th>
                );
            }
            
            // funzione che verrà richiamata una volta sola oppure dentro ad un ciclo
            const creaIntestazione = ( { column: s2ndRowColumnName, description: s2ndRowDesc = '', summable: s2ndRowSummable  } = {}, n2ndRowElement ) => {
                
                let s2ndStart     = s2ndRowStart;
                const sColumnName = bInvertPivot ? s2ndRowColumnName : s1stRowColumnName;
                const summable    = bInvertPivot ? s2ndRowSummable   : s1stRowSummable;

                // ciclo sul tipo di misure  (es. #, %... )
                anMeasureColumns.forEach( ( nMeasureColumn, nMeasureColumnCounter ) => {  // es. '0'
                    
                    if ( nMeasureColumn > 0 ) { s2ndStart = ''; s1stStart = ''; }

                    const
                         isFirstMeasureCalc = ( nMeasureColumnCounter === 0 )
                        ,measureColumnValue = ( isFirstMeasureCalc ? ( ( bInvertPivot ? s2ndRowDesc : formatValue(s2ndRowDesc) ) + ' ' ) : '' ) + asMeasureCols[nMeasureColumn]
                        ,className          = 'measure ' + ( s2ndRowColumnName + ' ' + s1stRowColumnName ) + ' ' + s2ndRowDesc + ' ' + asMeasureColClasses[nMeasureColumn] + s1stStart + s2ndStart + ( isFirstMeasureCalc ? ' firstMeaCalc ' : '' )
                        ,sRowDesc           = ( bInvertPivot ? s1stRowDesc : s2ndRowDesc )
                     // ,isLastElement      = ( ( bInvertPivot ? ao1stRowValues : ao2ndRowValues ).length - 1 ) === ( bInvertPivot ? n1stRowElement : n2ndRowElement )
                     // ,isLastAndDelta     = isLastElement && ( ( nMeasureColumn === 2 ) || ( nMeasureColumn === 3 ) )
                        ,isToShow           = ( ( ( summable === 'Y' ) && ( sRowDesc === sRowTotalLabel ) && ![1,2,3].includes(nMeasureColumn) ) || ( sRowDesc !== sRowTotalLabel ) )
                        ,verticalSortIcon   = () => assignSortIcon( { sColumnName, pSortingValue: sRowDesc } )
                        ,nIndexNextFound    = aoColDimensionValues.findIndex( o => o.description === sRowDesc )
                        ,sNextPivotValue    = ( aoColDimensionValues[ nIndexNextFound + 1 ] || {} ).description
                    ;

                    if ( rigaExtra ) { // versione che nasconde la colonna: ( rigaExtra && !isLastAndDelta )
                        if ( isToShow ) {
                            headIntColonna2.push(
                                <th key={ 'headIntColonna2' + n2ndRowElement + n1stRowElement + nMeasureColumn + measureColumnValue }
                                    className={ className } 
                                    style={ oStyleFixedcolumnWidth(oPivotDimension.columnWidth) }
                                    title={ !sNextPivotValue || ( nMeasureColumn < 2 ) ? '' : asMeasureCols[nMeasureColumn] + ' ' + formatValue(sRowDesc) + ', ' + formatValue(sNextPivotValue) }
                                >{ verticalSortIcon(nMeasureColumn) }{ measureColumnValue }</th>
                            );
                        }
                    } else { // versione che nasconde la colonna: if ( !isLastAndDelta )
                        headIntColonna1.push(
                            <th key={ 'headIntColonna1' + n2ndRowElement + n1stRowElement + nMeasureColumn + s1stRowDesc + measureColumnValue }
                                className={ className }
                                title={ !sNextPivotValue || ( nMeasureColumn < 2 ) ? '' : asMeasureCols[nMeasureColumn] + ' ' + formatValue(sRowDesc) + ', ' + formatValue(sNextPivotValue) }
                            >{ verticalSortIcon(nMeasureColumn) }{ s1stRowDesc + ' ' + measureColumnValue }</th>
                        );
                    }

                });

            } // fine creaIntestazione

            if ( !sPivotDimension ) { // una volta sola
                creaIntestazione();
            } else { // si cicla sulla dimensione delle colonne da pivottare (es. REVENUE_YEAR: '2019', '2017'...)
                ao2ndRowValues.forEach(creaIntestazione);
            }

        });

        head1 = <tr key={'head1'}>{ headIntRiga1 }{ headIntColonna1 }</tr>;
        head2 = <tr key={'head2'}>{ headIntRiga2 }{ headIntColonna2 }</tr>;

    // ------------------------------------------------------------------------------------------------
    
        // costruisco la struttura che contiene i valori dei subtotali
        const asColumnKeys          = [
            oPivotDimension.column,
            ...aoAllDimensions.filter( d => d.selected && !d.pivoted ).map( o => o.column )
        ];
        const asColumnKeysForRowTot = [
            ...aoAllDimensions.filter( d => d.selected && !d.pivoted ).map( o => o.column )
        ];
        const makeKeyComb           = ( oRecord ) => asColumnKeys.map(          sKey => ( ( oRecord[sKey] === 0 ) || oRecord[sKey] ) ? oRecord[sKey] : '' ).filter(Boolean).join('|');
        const makeKeyCombForRowTot  = ( oRecord ) => asColumnKeysForRowTot.map( sKey => ( ( oRecord[sKey] === 0 ) || oRecord[sKey] ) ? oRecord[sKey] : '' ).filter(Boolean).join('|');
        
        const oSubtotals            = aoSubtotals.reduce( ( oFinal, oSubtotal ) => {
            
            const sKeyCombination   = makeKeyComb(oSubtotal);
            oFinal[sKeyCombination] = oSubtotal; // a quella combinazione di valori delle dimensioni interessate associo l'intero record (che contiene le misure)
            let sKeyCombinationForRowTot = makeKeyCombForRowTot(oSubtotal);
            sKeyCombinationForRowTot = [ sRowTotalLabel, sKeyCombinationForRowTot ].filter(Boolean).join('|');
            if ( !oFinal[ sKeyCombinationForRowTot ] ) {
                  oFinal[ sKeyCombinationForRowTot ] = {};
            }
            const oSubtotalRowTotal = oFinal[ sKeyCombinationForRowTot ];
            const asSubtotalKeys = Object.keys(oSubtotal);
            for ( let nKey = 0; nKey < asSubtotalKeys.length; nKey++ ) {
                const sSubtotalKey = asSubtotalKeys[nKey];
                // se è una misura
                if ( aoMeasures.find( oMea => oMea.column === sSubtotalKey ) ) {
                    // copio in un altro oggetto le chiavi e i relativi valori
                    oSubtotalRowTotal[sSubtotalKey] = ( oSubtotalRowTotal[sSubtotalKey] || 0 ) + oSubtotal[sSubtotalKey];
                }
            }
            
            return oFinal;
        }, {});
        oSubtotals[''] = oGrandTotal; // includo il record GrandTotal nei subtotali con chiave stringa vuota 
        // bDebug && console.log(oSubtotals);
        
        // dalla RIGA 2, si cicla su tutte le righe (record della query)
        // console.log('RIGA 2');

        let bodyRighe = [];
        const makeKeyCombSub        = ( oRecord, nLevel, sPivotValue ) => (
        [
            sPivotValue
            , ...aoDimensionsSelected.map( oDim => ( ( oRecord || {} )[oDim.column] || '' ) ).slice( 0, nLevel >= 0 ? nLevel : 0 )
        ].filter(Boolean).join('|')
    );

        const creaIntestazioniRiga  = ({ PROG_ID, oRecord, oRecordPrec, isSubtotalRow, nSubtotalLevel, sSubtot, isGrandRow, classSubTot }) => {
            
            const aBodyRigaIntestazioni = [];
            
            aoDimensions.forEach( ( { column, filterDataType, columnWidth, subtotal }, nDimension ) => {

                if ( ( isSubtotalRow && ( nDimension <= nSubtotalLevel ) ) || !isSubtotalRow ) { // serve per creare solo N celle e relativo COLSPAN in base al livello di subtotale

                    let value = (
                        isGrandRow
                            ? ( ( nDimension === 0 ) ? sGrandTotalLabel : '' )
                            : oRecord[ column ]
                    );
                    
                    const isBeforeSubtotCol = ( nDimension  <  nSubtotalLevel );
                    const isSubtotCol       = ( nDimension === nSubtotalLevel );
                    
                    // let nColSpan = ( !isSubtotalRow || ( nDimension !== nSubtotalLevel ) ) ?   1   :    +( aoDimensions.length - nSubtotalLevel );
                    let nColSpan = ( isSubtotalRow && isSubtotCol ) ?  +( aoDimensions.length - nSubtotalLevel )  :  1;
                    
                    const bForDrillDown = (   // condizione per attivare il drill down:
                        !isGrandRow &&        // non deve essere il Grand Total E
                        ( !bTabularMode || (  // non deve essere in modalità Tabular OPPURE 
                            // ( è in modalità Tabular E
                            ( !isSubtotalRow && // nel caso delle righe di dati
                                (
                                    ( nDimension < aoDimensions.length ) && //   per tutte le dimensioni
                                    ( PROG_ID === nRowSelected )            //   e la riga deve essere selezionata
                                )
                            ) || ( isSubtotalRow && // nel caso dei subtotali
                                ( nDimension < aoDimensions.length - 1 )
                            )
                        ))
                    );

                    let sDrillTooltip = 'Enter in drill down on:\n';
                    aoDimensions.forEach( ( oDimension, nPosition ) => {
                        if ( nPosition <= nDimension ) {
                            sDrillTooltip += oDimension.description + ': ' + (
                                Utils.convertDataType( oRecord[ oDimension.column ], oDimension.filterDataType )
                            ) + '\n'
                        }
                    });

                    const classForDrillDown = bForDrillDown ? ' drillDown ' : '';
                    value = Utils.convertDataType( value, ( isGrandRow && ( nDimension === 0 ) ) ? 'C' : filterDataType ); // qui il frontend si differenzia dall'excel
                    
                    // per visualizzare il valore della cella della colonna il cui subtotale è disabilitato (con i subtotali abilitati)

                    const
                         isSubtotalEnabled      = ( subtotal === 'Y' ) // il subtotale (di questa specifica colonna di intestazione) è disabilitato
                        ,isRecordValueChanged   = ( oRecord[ column ] !== oRecordPrec?.[ column ] ) // il suo valore è diverso da quello della riga precedente (stessa colonna)
                        ,isAlreadySubtotal      = get_as1stSubTot()?.includes( column )
                        ,aoPrevDimensions       = aoDimensions.slice(0,nDimension)
                        
                        ,arePrevSubtotsValuesChanged = (({ aoPrevDimensions, oRecord, oRecordPrec }) => {
                            
                            if ( !oRecordPrec ) { return false; }
                            
                            const asDims = aoPrevDimensions.map( o => o.column ); // [ 'REVENUE_YEAR', 'DELIVERY_FAMILY' ]
                            
                            for ( let i = 0; i < asDims.length; ++i ) {
                                const sDim = asDims[i];
                                if ( oRecord[sDim] !== oRecordPrec[sDim]) {
                                    return true;
                                } 
                            }
                            
                            return false;
                            
                        })({ aoPrevDimensions, oRecord, oRecordPrec })
                        
                        ,isOnePrevDimSubtot     = aoPrevDimensions.some( oDim => oDim.subtotal === 'Y' )
                    ;
                    
                    let isToShow = false, sSubtotAddClass = '';
                    
                    if ( sSubTotals ) { // subtotali attivi
                    
                        if ( isSubtotalRow ) { // riga subtotale
                            
                            if ( isBeforeSubtotCol ) { // cella PRIMA della colonna subtotale
                                sSubtotAddClass = 'subtotprevious';
                                if ( !isSubtotalEnabled && !isAlreadySubtotal ) { // solo se non è già subtotale ibrido => showValue
                                    if ( isOnePrevDimSubtot && arePrevSubtotsValuesChanged ) {
                                        isToShow = true;
                                    } else  {
                                        if ( isRecordValueChanged || arePrevSubtotsValuesChanged ) {
                                            // solo se è cambiato il valore E non è già subtotale ibrido => showValue
                                            isToShow = true;
                                        }
                                    }
                                }
                            } else { // cella ESATTA o SUCCESSIVA della colonna subtotale
                                
                                sSubtotAddClass = 'subtothead';
                                if ( isSubtotalEnabled ) { // showValue
                                    if ( isSubtotCol ) { // cella ESATTA della colonna subtotale
                                        isToShow = true;
                                    }
                                } else { // !isSubtotalEnabled
                                    if ( isRecordValueChanged && !isAlreadySubtotal ) {
                                        // solo se è cambiato il valore E non è già subtotale ibrido => showValue
                                        isToShow = true;
                                    }
                                }
                                
                            }
                            
                        } else { // !isSubtotalRow    // riga NON subtotale (record)
                            
                            if ( ( arePrevSubtotsValuesChanged || isRecordValueChanged ) && !isAlreadySubtotal ) {
                                // solo se è cambiato il valore o i subtotali precedenti E non è già subtotale ibrido => showValue
                                isToShow = true;
                            }
                            
                        }
                        
                    }
                    
                    // bDebug && isSubtotalRow && Utils.logObject( '- col', { as1stSubTot, nSubtotalLevel, nDimension, sCellValue: oRecord[ column ] } );
                    
                    aBodyRigaIntestazioni.push(
                        <th
                            key             ={ 'bodyIntRiga' + nDimension + column + nSubtotalLevel + sSubtot }
                            colSpan         ={ nColSpan }
                            className       ={
                                'dimension ' + column + classForDrillDown + ( ' nSubtotalLevel'+nSubtotalLevel+' ' )
                                // se è un subtotale oppure i subtotali sono abilitati ma per questa colonna sono disabilitati
                                + ( isSubtotalRow ? classSubTot   : '' )
                                + ( isToShow      ? ' showValue ' : '' )
                                + ' ' + sSubtotAddClass + ' '
                            }
                            data-column-name={ column }
                            onClick         ={ event => bForDrillDown ? onClickDrillIcon( event, oRecord ) : undefined }
                            style           ={ oStyleFixedcolumnWidth(columnWidth) }
                            title           ={ bForDrillDown ? sDrillTooltip : value }
                        >{ value }</th>
                    );
                    
                    if ( isToShow ) {
                        // serve per segnarsi che per questa colonna è già stato gestito un subtotale
                        const a = get_as1stSubTot();
                        a[ nDimension ] = column;
                        set_as1stSubTot([ ...a ].map( v => v || '' ) );
                        // Utils.logObject( 'get_as1stSubTot',get_as1stSubTot(), '', 30);
                    }
                    
                }
                
            });
            
            return aBodyRigaIntestazioni;
            
        }
        
        // serve per ottenere le posizioni equidistanti tra di loro e tra gli estremi preimpostati compresi ( 1 e 26 )
        // degli n subtotali che devono essere rappresentati
        // Esempio: per un subtotale restituirà 13, per due subtotali restituirà 9 e 17
        const calcSubtotalStyle     = (nActualSubtotalLevel) => Math.round((nActualSubtotalLevel + 1) * 26 / (aoDimensionsSelected.length + 1));
        
        const makeRow               = ({ oRecord, oRecordPrec, isSubtotalRow = false, nSubtotal: nSubtotalLevel = 0, sSubtot, isGrandRow }) => {
            
            const PROG_ID       = oRecord && oRecord['PROG_ID'];
            const calcSubtotal  = nLevel => oSubtotals[ makeKeyCombSub( oRecord, nLevel ) ] || {};
            // TODO qua si potrebbe variare con un'opzione il calcolo del subtotale decidendo se usare il GrandTotal o il livello precedente
            const bodyValori    = [];
            
            // per le colonne eseguo 3 cicli (il 3 innestato nel 2):
            
            sSubtot             = !isSubtotalRow ? '' : ( '_' + ( nSubtotalLevel + 1 ) );
            const classSubTot   = ( ' subtotal' + calcSubtotalStyle( nSubtotalLevel ) + ' ' );
            
            // 1) ciclo sulle dimensioni (es. CHANNEL, ADVERTISER... )
            const bodyIntRiga   = creaIntestazioniRiga({
                PROG_ID, oRecord, oRecordPrec, isSubtotalRow, nSubtotalLevel, sSubtot, isGrandRow, classSubTot
            }) || [];

            // 2) poi si cicla su tutte le misure (es. GROSS, SPOT PRICE... ), a partire dalla COLONNA 2
            aoMeasures.forEach( ( { column, decimals }, nMeasure ) => {  // es. { code: 'GROSS', description: 'Gross', columnWidth: 150, decimals: 2 }

                // 3) infine si cicla sul tipo di misure  (es. #, %... )
                anMeasureColumns.forEach( ( n, nMeasureColumnCounter ) => {  // es. 0
                    
                    const
                         sFirstMeasureCalc  = nMeasureColumnCounter === 0 ? ' firstMeaCalc ' : ''
                        ,nGrandRowValue     = oGrandTotal[column]
                        ,nSubtotalValue     = calcSubtotal(nSubtotalLevel + 1 )[column]
                        ,nRecordValue       = oRecord && oRecord[column]
                        ,value              = (   isGrandRow    ? nGrandRowValue
                                                : isSubtotalRow ? nSubtotalValue
                                                :                 nRecordValue
                                              ) || 0 // potrebbe non esistere il valore
                        ,getClassName       = (zeroOrNegVal) => (
                            'bodyValori measure ' + oRecord['PROG_ID'] + ' ' + nMeasure + sSubtot + n + column +
                            ( isSubtotalRow ? classSubTot : '') + ' ' + asMeasureColClasses[+n] + ' ' + zeroOrNegVal + sFirstMeasureCalc
                        )
                    ;

                    // #
                    if ( n === 0 ) {
                        const className      = getClassName(zeroOrNeg(value));
                        bodyValori.push( <td key={ className } className={ className } >{ formatNum( value, decimals ) }</td> );

                    // %
                    } else if ( n === 1 ) {

                        const
                             subTot         = (   isGrandRow    ? nGrandRowValue
                                                : isSubtotalRow ? calcSubtotal(nSubtotalLevel )[column]
                                                : ( oRecord && calcSubtotal( sSubTotals ? aoDimensions.length - 1 : nSubtotalLevel )[column] )
                                              ) || 0 // potrebbe non esistere il valore
                            
                            ,perc           = isGrandRow ? 100 : ( subTot ? ( value * 100 / subTot ) : 0 )
                            ,finalValue     = formatNum( perc )
                            
                            ,className      = getClassName(zeroOrNeg(perc))
                            ,title          = formatNum(value,decimals) + ' out of ' + formatNum(subTot,decimals)
                        ;

                        bodyValori.push( <td key={ className } className={ className } title={ title || '' } >{ finalValue }</td> );

                    }

                });

            });
            
            const sSubTotClass  = !isSubtotalRow ? '' : (
                ' subtotal '
                + aoDimensions[nSubtotalLevel]?.column
                + ( ( aoDimensions[nSubtotalLevel]?.subtotal === 'Y' ) ? '' : ' hide ' ) // qui vengono gestiti i subtotali selettivi
            );
            
            return <tr
                key       ={ 'bodyRighe ' + oRecord['PROG_ID'] + ' ' + nSubtotalLevel + sSubtot }
                className ={  isGrandRow ? ' grandTotal ' : ( ( !bTabularMode ? '' : ( ( nRowSelected === PROG_ID ) && !isSubtotalRow ? ' rowSelected ' : '' ) ) + sSubTotClass ) }
                onClick   ={
                    event => {
                        if ( !isGrandRow && !isSubtotalRow && bTabularMode ) {
                            setnRowSelected( ( nRowSelected === PROG_ID ) ? '' : PROG_ID );
                        }
                    }
                }
            >{ [ bodyIntRiga, bodyValori ] }</tr>;

        }

        const makeRowForPivot       = ({ oRecord, oRecordPrec, oRowDimension, sPrimaryKey, isSubtotalRow = false, nSubtotal: nSubtotalLevel = 0, sSubtot, isGrandRow }) => {  // es. PROG_ID = 1, 2, 3...
            
            const PROG_ID             = oRecord && oRecord['PROG_ID'];
            const commonInfoPerProgID = ( oRowDimension || {} )[ Object.keys(oRowDimension || {} )[0] || '' ];
            const calcSubtotal        = ({ nLevel, sPivotValue }) => oSubtotals[
                makeKeyCombSub(
                    commonInfoPerProgID, 
                    nLevel, 
                    sPivotValue
                )
            ] || {};
            // TODO qua si potrebbe variare con un'opzione il calcolo del subtotale decidendo se usare il GrandTotal o il livello precedente
            const bodyValori          = [];
            
            // in caso di pivot, per le colonne eseguo 4 cicli (il 3 e 4 innestati nel 2):

            sSubtot             = !isSubtotalRow ? '' : ( '_' + ( nSubtotalLevel + 1 ) );
            const classSubTot   = ( ' subtotal' + calcSubtotalStyle( nSubtotalLevel ) + ' ' );

            // 1) ciclo sulle dimensioni (es. CHANNEL, ADVERTISER... )
            const bodyIntRiga   = creaIntestazioniRiga({
                PROG_ID, oRecord, oRecordPrec, isSubtotalRow, nSubtotalLevel, sSubtot, isGrandRow, classSubTot
            }) || [];
            
            // 2) poi si cicla su tutte le misure (es. GROSS, SPOT PRICE... ), per creare il corpo della riga (che verrà messo in coda alle intestazioni di riga)
            // es. { column: 'GROSS', description: 'Gross', columnWidth: 150, decimals: 2 }
            ( bInvertPivot ? aoColDimensionValues : aoMeasures ).forEach(
                ( { column: s1stRowColumn, description: s1stRowDesc, decimals: n1stRowDecimals, summable: n1stRowSummable }, n1stColDim, ao1stRows ) => {

                let measureStart = ' measureStart';

                // 3) si cicla sulla dimensione delle colonne da pivottare (es. REVENUE_YEAR: '2019', '2017'...)
                // es. colDimensionDesc = '2019'
                ( bInvertPivot ? aoMeasures : aoColDimensionValues ).forEach(
                    ( { column: s2ndRowColumn, description: s2ndRowDesc, decimals: n2ndRowDecimals, summable: n2ndRowSummable }, n2ndColDim, ao2ndRows ) => {

                    const
                          column                = bInvertPivot ? s2ndRowColumn   : s1stRowColumn
                         ,decimals              = bInvertPivot ? n2ndRowDecimals : n1stRowDecimals
                         ,summable              = bInvertPivot ? n2ndRowSummable : n1stRowSummable

                         ,colDimensionDesc      = bInvertPivot ? s1stRowDesc     : s2ndRowDesc
                         ,nColDimension         = bInvertPivot ? n1stColDim      : n2ndColDim
                         ,aoCols                = bInvertPivot ? ao1stRows       : ao2ndRows
                        
                         ,sColDimensionDescNext = ( aoCols[ nColDimension + 1 ] || {} ).description || ''

                         ,oMeasuresRecord       = ( oRowDimension && oRowDimension[colDimensionDesc]      ) || {} // potrebbe non esistere la combinazione
                         ,oMeasuresRecordNext   = ( oRowDimension && oRowDimension[sColDimensionDescNext] ) || {} // potrebbe non esistere la combinazione
                    ;
                    
                    let colDimensionStart       = ' colDimensionStart';

                    // 4) infine si cicla sul tipo di misure  (es. #, %... )
                    anMeasureColumns.forEach( ( n, nMeasureColumnCounter ) => {  // es. 0

                        const
                             sFirstMeasureCalc  = nMeasureColumnCounter === 0 ? ' firstMeaCalc ' : ''
                            ,isLastElement      = ( ( bInvertPivot ? ao1stRowValues : ao2ndRowValues ).length - 1 ) === ( bInvertPivot ? n1stColDim : n2ndColDim )
                            ,nGrandRowValue     = ( oSubtotals[colDimensionDesc] || {} )[column]
                            ,nSubtotalValue     = calcSubtotal({ nLevel: nSubtotalLevel + 1, sPivotValue: colDimensionDesc })[column]
                            ,nRecordValue       = oMeasuresRecord && oMeasuresRecord[column]
                            ,value              = (   isGrandRow    ? nGrandRowValue
                                                    : isSubtotalRow ? nSubtotalValue
                                                    :                 nRecordValue
                                                  ) || 0 //  potrebbe non esistere il valore
                            ,valueNext          = (   isGrandRow    ? ( oSubtotals[sColDimensionDescNext] || {} )[column]
                                                    : isSubtotalRow ? calcSubtotal({ nLevel: nSubtotalLevel + 1, sPivotValue: sColDimensionDescNext })[column]
                                                    : ( oMeasuresRecordNext && oMeasuresRecordNext[column] )
                                                  ) || 0 // potrebbe non esistere il valore
                            ,getClassName       = (zeroOrNegVal) => (
                                'bodyValori measure ' + column + n2ndColDim + n + nMeasureColumnCounter +
                                ( isSubtotalRow ? classSubTot : '' ) + ' ' + colDimensionDesc + ' ' +
                                asMeasureColClasses[n] + zeroOrNegVal + measureStart + colDimensionStart + sFirstMeasureCalc
                            )
                        ;

                        if ( n > 0 ) { colDimensionStart = ''; measureStart = ''; }
                        
                        // #
                        if ( ( n === 0 ) && ( ( ( summable === 'Y' ) && ( colDimensionDesc === sRowTotalLabel ) ) || ( colDimensionDesc !== sRowTotalLabel ) ) ) {
                            
                            const
                                 zeroOrNegVal   = zeroOrNeg(value)
                                ,className      = getClassName(zeroOrNegVal)
                                ,finalValue     = formatNum( value, decimals )
                            ;
                            bodyValori.push(<td key={ className } className={ className }>{ finalValue }</td>);
    
                        // %
                        } else if ( ( n === 1 ) && ( colDimensionDesc !== sRowTotalLabel ) ) {

                            const
                                 subTot         = (   isGrandRow    ? nGrandRowValue
                                                    : isSubtotalRow ? calcSubtotal( { nLevel: nSubtotalLevel, sPivotValue: colDimensionDesc })[column]
                                                    : ( oMeasuresRecord && calcSubtotal( { nLevel: sSubTotals ? aoDimensions.length - 1 : nSubtotalLevel, sPivotValue: colDimensionDesc } )[column] )
                                                  ) || 0 // potrebbe non esistere il valore

                                ,perc           = subTot ? ( value * 100 / subTot ) : 0
                                ,zeroOrNegVal   = zeroOrNeg(perc)
                                ,className      = getClassName(zeroOrNegVal)
                                ,finalValue     = formatNum( perc )
                                ,title          = formatNum(value,decimals) + ' out of ' + formatNum(subTot,decimals)
                                ,style          = !isGraphEnabled || isGrandRow ? {} : { background: `linear-gradient( 90deg, white ${ 100 - perc }%, #d7e5d8 ${ perc }% )` }
                            ;
                            bodyValori.push(<td key={ className } title={ title || '' }
                                                className={ className } style={ style }>{ finalValue }</td>);
    
                        // Δ
                        } else if ( ( n === 2 ) && ( colDimensionDesc !== sRowTotalLabel ) ) { // versione per colonne Delta nascoste: ( n === 2 && !isLastElement )

                            const
                                 diff           = value - valueNext
                                ,zeroOrNegVal   = zeroOrNeg(diff)
                                ,className      = getClassName(zeroOrNegVal)
                                ,finalValue     = ( !zeroOrNegVal ? '+' : '' ) + formatNum( diff,  decimals, '=' )
                                ,title          = formatNum( value, decimals ) + ' - ' + formatNum(valueNext,decimals)
                            ;
                            bodyValori.push(<td key={ className } title={ isLastElement ? 'not calculable' : ( title || '' ) }
                                                className={ className }>{ isLastElement ? noValue : finalValue }</td>);
    
                        // Δ%
                        } else if ( ( n === 3 ) && ( colDimensionDesc !== sRowTotalLabel ) ) { // versione per colonne Delta nascoste: ( n === 3 && !isLastElement )

                            const
                                 diffPerc       = ( ( value === valueNext ) || ( valueNext === 0 ) ) ? 0 : ( ( value * 100 / valueNext ) - 100 )
                                ,zeroOrNegVal   = zeroOrNeg(diffPerc)
                                ,className      = getClassName(zeroOrNegVal)
                                ,finalValue     = ( !zeroOrNegVal ? '+' : '' ) + formatNum( diffPerc, 2,'=' )
                                ,title          = formatNum(value,decimals) + ' out of ' + formatNum(valueNext,decimals)
                            ;
                            bodyValori.push(<td key={ className } title={ isLastElement ? 'not calculable' : ( title || '' ) }
                                                className={ className }>{ isLastElement ? noValue : finalValue }</td>);

                        }

                    });

                });

            });
            
            const sSubTotClass  = !isSubtotalRow ? '' : (
                ' subtotal '
                + aoDimensions[nSubtotalLevel]?.column
                + ( ( aoDimensions[nSubtotalLevel]?.subtotal === 'Y' ) ? '' : ' hide ' ) // qui vengono gestiti i subtotali selettivi
            );
            
            return <tr
                key       ={ 'bodyRighe ' + PROG_ID + ' ' + sPrimaryKey + nSubtotalLevel + sSubtot }
                className ={  isGrandRow ? ' grandTotal ' : ( ( !bTabularMode ? '' : ( ( nRowSelected === PROG_ID ) && !isSubtotalRow ? ' rowSelected ' : '' ) ) + sSubTotClass ) }
                onClick   ={
                    event => {
                        if ( !isGrandRow && !isSubtotalRow && bTabularMode ) {
                            setnRowSelected( ( nRowSelected === PROG_ID ) ? '' : PROG_ID );
                        }
                    }
                }
            >{ [ bodyIntRiga, bodyValori ] }</tr>;

        }

        const checkForSubtotals     = !!sSubTotals && ( aoQueryResults.length > 1 ) && ( aoDimensions.length > 1 );

        const asColsDaControllare   = []; // [ 'CHANNEL_DESC', 'ADVERTISER_NAME' ] 
        // per ogni dimensione meno l'ultima
        for ( let nDim = 0; nDim < aoDimensions.length - 1;  nDim++ ) {
            // for ( let nDim = aoDimensions.length - 1; nDim > 0;  nDim-- ) {
            asColsDaControllare.push( aoDimensions[nDim].column );
        }
        
        const maxSubTotals = asColsDaControllare.length;
        const nStart       = ( sSubTotals === 'bottom' ) ? maxSubTotals : 0;
        const nEnd         = ( sSubTotals === 'bottom' ) ? 0            : maxSubTotals;
        const nMult        = ( sSubTotals === 'bottom' ) ? -1           : 1;
        
        let as1stSubTot = []; // questo array serve per segnarsi di quali colonne è già stato visualizzato il subtotale ibrido
        const get_as1stSubTot = () => {
            return as1stSubTot;
        };
        const set_as1stSubTot = ( as ) => {
            as1stSubTot = as;
        };
        
        if ( !sPivotDimension ) {

            let oRecordPrec;

            const makeTable = ({ oRecord, isGrandRow }) => {
                
                // Utils.logObject( '(reset set_as1stSubTot) oRecord',oRecord);
                set_as1stSubTot([]); // reset ad ogni record
                
                // DATI
                if ( sSubTotals !== 'top' ) { // se non sono attivi i subtotali (o se devono essere visualizzati in basso)
                    bodyRighe.push( makeRow({ oRecord, oRecordPrec, isGrandRow }) );
                }
                
                const checkColonna = sColDaControllare => oRecord[ sColDaControllare ] !== oRecordPrec[ sColDaControllare ];
                
                // SUBTOTALI
                if ( !isGrandRow && checkForSubtotals ) { // se sono attivi i subtotali
                    
                    // 0) ciclo per i subtotali
                    //    devo generare una riga per il valore del record e tante righe per i subtotali quante sono le dimensioni, meno una (l'ultima)

                    
                    // bDebug && console.log('---------------------------------------------------------------------');
                    for ( let nSubtotal = nStart; nSubtotal < nEnd; nSubtotal = nSubtotal + nMult ) {
                        
                        // controllo se i valori di tutte le dimensioni tranne l'ultima, sono uguali a quelli del record precedente
                        const areDimensionsDifferent = oRecordPrec && asColsDaControllare.slice( 0, ( nSubtotal + 1 ) * nMult ).some( checkColonna );
                        
                        // vuol dire che l'attuale record ha un subtotale diverso dal precedente
                        if ( ( aoDimensions[nSubtotal].subtotal === 'Y' ) && ( !oRecordPrec || areDimensionsDifferent ) ) {
                            
                            // bDebug && Utils.logObject( 'row ----', { 
                            //     as1stSubTot, nSubtotal, sSubDesc: asColsDaControllare[nSubtotal], sCellValue: oRecord[ asColsDaControllare[nSubtotal] ]
                            // } );
                            
                            bodyRighe.push( makeRow({
                                oRecord, oRecordPrec, isSubtotalRow: true, nSubtotal
                            }) );
                            
                        }

                    }

                }

                // DATI
                if ( sSubTotals === 'top' ) {  // se sono attivi i subtotali (e devono essere visualizzati in alto)
                    bodyRighe.push( makeRow({ oRecord, oRecordPrec, isGrandRow }) );
                }

                oRecordPrec = { ...oRecord }; // per record si intende un record con PROG_ID diverso

            }

            makeTable( { oRecord: oGrandTotal, isGrandRow: true } );

            // per le righe ciclo su tutti i record
            aoQueryResults.forEach( oRecord => {
                makeTable( { oRecord } )
            });

        } else { // -------------------------------- PIVOTING ---------------------------------

            const sPrimaryKey = bInvertPivot ? sPivotDimension : 'PROG_ID';
            let oRows = {};
            let oQueryRecordTOT = {};
            // creo la struttura per i dati pivottati
            for ( let nQueryRow = 0; nQueryRow < aoQueryResults.length;  nQueryRow++ ) {
                
                let oQueryRecord = aoQueryResults[nQueryRow];
                
                if ( oQueryRecord.PROG_ID !== oQueryRecordTOT.PROG_ID ) {
                    oQueryRecordTOT = {};
                }
                
                const rowDimensionDesc = ( oQueryRecord['PROG_ID']       === 0 ? 0 : ( oQueryRecord['PROG_ID']       || '' ) ) + ''; // Es. '1'
                const colDimensionDesc = ( oQueryRecord[sPivotDimension] === 0 ? 0 : ( oQueryRecord[sPivotDimension] || '' ) ) + ''; // Es. '2019'
                
                if ( !oRows[rowDimensionDesc] ) {
                    oRows[rowDimensionDesc] = {};                                // Es. '1': { '2019': { ...(campi del record) } }
                }
                
                oRows[rowDimensionDesc][colDimensionDesc] = { ...oQueryRecord }; // Es. '2019': { ...(campi del record) }
    
                if ( bRowTotal ) { // se sono abilitati i totali di riga, aggiungo nella struttura "oRows" una colonna in più di dati

                    // const oQueryRecordTotRiga     = aoQueryResultsTotRiga[ +rowDimensionDesc - 1 ];
                    if ( !oRows[rowDimensionDesc][sRowTotalLabel] ) {
                        oRows[rowDimensionDesc][sRowTotalLabel] = {};
                    }
    
                    const asRecordKeys = Object.keys(oQueryRecord);
                    for ( let nKey = 0; nKey < asRecordKeys.length; nKey++ ) {
                        const sRecordKey = asRecordKeys[nKey];
                        // se è una misura
                        if ( aoMeasures.find( oMea => oMea.column === sRecordKey ) ) {
                            // copio in un altro oggetto le chiavi e i relativi valori
                            oQueryRecordTOT[sRecordKey] = ( oQueryRecordTOT[sRecordKey] || 0 ) + oQueryRecord[sRecordKey];
                        }
                    }
                    
                    oQueryRecordTOT.PROG_ID = oQueryRecord.PROG_ID;
    
                    oRows[rowDimensionDesc][sRowTotalLabel] = { ...oQueryRecordTOT };
                    
                }

                
            }

            /* esempio dati pivottati:
            {
              '1': {
                '2017': {
                  ADVERTISER_CODE: 'A00535',
                  ADVERTISER_NAME: 'FONDAZIONE FERRERO O',
                  REVENUE_YEAR:     2017,
                  GROSS:            0,
                  SPOT_PRICE:       0,
                  SPOT_LENGTH:      70
                },
                '2019': {
                  ADVERTISER_CODE: 'A00535',
                  ADVERTISER_NAME: 'FONDAZIONE FERRERO O',
                  REVENUE_YEAR:     2019,
                  GROSS:            0,
                  SPOT_PRICE:       0,
                  SPOT_LENGTH:      90
                }
              },
              '2': {
                '2019': {
                  ADVERTISER_CODE: 'A00294',
                  ADVERTISER_NAME: 'RED BULL ITALIA SRL',
                  REVENUE_YEAR:     2019,
                  GROSS:            0,
                  SPOT_PRICE:       0,
                  SPOT_LENGTH:      0
                }
              }
            }
            
                1:
                    1: {PROG_ID: 1, AGENZIA_PROD_DESC: '-', COMPETENZA_SEMESTRE: 1, IMPORTO_NETTO: 10414618.89}
                    2: {PROG_ID: 1, AGENZIA_PROD_DESC: '-', COMPETENZA_SEMESTRE: 2, IMPORTO_NETTO: 7958066.12}
                2:
                    1: {PROG_ID: 2, AGENZIA_PROD_DESC: '2303 SRL UNIPERSONALE', COMPETENZA_SEMESTRE: 1, IMPORTO_NETTO: 64652.17}
                    2: {PROG_ID: 2, AGENZIA_PROD_DESC: '2303 SRL UNIPERSONALE', COMPETENZA_SEMESTRE: 2, IMPORTO_NETTO: 42353.25}
                    
            */

            // bDebug && console.log(oRows);

            let oRecordPrec;
            
            // per le righe prima si cicla sulla combinazione di dimensioni, cioè PROG_ID, nella struttura oRows
            const makeTableForPivot = ({ oRecord, isGrandRow }) => {
                
                // Utils.logObject( '(reset set_as1stSubTot) oRecord',oRecord, '', 0);
                set_as1stSubTot([]); // reset ad ogni record
                // Utils.logObject( 'get_as1stSubTot',get_as1stSubTot(), '', 30);
                const oRowDimension  = oRows[oRecord.PROG_ID];

                // in caso di pivot per le colonne ciclo 4 volte:

                // DATI
                if ( sSubTotals !== 'top' ) { // se non sono attivi i subtotali (o se devono essere visualizzati in basso)
                    bodyRighe.push( makeRowForPivot({ oRecord, oRecordPrec, oRowDimension, sPrimaryKey, isGrandRow }) );
                }
                
                const checkColonna = sColDaControllare => oRecord[ sColDaControllare ] !== oRecordPrec[ sColDaControllare ];

                // SUBTOTALI
                if ( !isGrandRow && checkForSubtotals ) { // se sono attivi i subtotali

                    // 0) ciclo per i subtotali
                    //    devo generare una riga per il valore del record e tante righe per i subtotali quante sono le dimensioni, meno una (l'ultima)
                    
                    // bDebug && console.log('---------------------------------------------------------------------');
                    for ( let nSubtotal = nStart; nSubtotal < nEnd; nSubtotal = nSubtotal + nMult ) {
                        
                        // controllo se i valori di tutte le dimensioni tranne l'ultima, sono uguali a quelli del record precedente
                        const areDimensionsDifferent = oRecordPrec && asColsDaControllare.slice( 0, ( nSubtotal + 1 ) * nMult ).some( checkColonna );
                                      
                        // vuol dire che l'attuale record ha un subtotale diverso dal precedente
                        if ( ( aoDimensions[nSubtotal].subtotal === 'Y' ) && ( !oRecordPrec || areDimensionsDifferent ) ) {
                            
                            // bDebug && Utils.logObject( 'row ----', {
                            //     as1stSubTot, nSubtotal, sSubDesc: asColsDaControllare[nSubtotal], sCellValue: oRecord[ asColsDaControllare[nSubtotal] ]
                            // } );
                            
                            bodyRighe.push( makeRowForPivot({
                                oRecord, oRecordPrec, oRowDimension, sPrimaryKey, isSubtotalRow: true, nSubtotal
                            }) );
                            
                        }

                    }

                }

                // DATI
                if ( sSubTotals === 'top' ) {  // se sono attivi i subtotali (e devono essere visualizzati in alto)
                    bodyRighe.push( makeRowForPivot({ oRecord, oRecordPrec, oRowDimension, sPrimaryKey, isGrandRow }) );
                }
                
                oRecordPrec = { ...oRecord }; // per record si intende un record con PROG_ID diverso

            };
            
            makeTableForPivot( { oRecord: oGrandTotal, isGrandRow: true } );
            
            const asRowsKeys = Object.keys(oRows);
            for ( let nRowKey = 0; nRowKey < asRowsKeys.length;  nRowKey++ ) {
                makeTableForPivot({
                    oRecord: Object.values( oRows[ asRowsKeys[nRowKey] ] )[0] || {}
                });
            }

        }
        
        return <table id="cubeTable" className={
            'cubeTable' +
            ( rigaExtra       ? ' rigaExtra'        : '' ) +
            ( bTabularMode    ? ' tabularMode'      : '' ) +
            ( sSubTotals      ? ' subtotalsEnabled' : '' ) +
            ( sPivotDimension ? ' pivotEnabled'     : '' ) +
            ( bShowAllValues  ? ' showAllValues'    : '' )
        }>
            <thead>{ head1 }{ rigaExtra ? head2 : null }</thead>
            <tbody>{ bodyRighe }</tbody>
        </table>;
    }

    useEffect(() => {
        setTableElement( createTableElement() );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ nRowSelected, bShowAllValues, bInvertPivot, nInvertSortPivot, bRowTotal, anMeasureColumns, aoAllMeasures ]);
    /* verrà aggiornata la tabella:
         ad ogni cambio di nRowSelected (quindi ad ogni click su riga) (incluso al primo caricamento del componente)
         ad ogni cambio di bShowAllValues
         ad ogni cambio di anMeasureColumns
         inutile aggiungere altre condizioni di caricamento in quanto sono già state messe nel componente padre (CubeGrid):
         cioè bShowTable && aoQueryResults && aoQueryResults[0]
         (quindi in caso di tabular, pivot e subtotals cambiano i risultati della query e di conseguenza la tabella si aggiorna 
    */

    return tableElement;

}
