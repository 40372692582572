import React, {Component}       from 'react'
import Paper                    from '@material-ui/core/Paper';
import {
    SortableContainer
}                               from 'react-sortable-hoc';

import                      './CubeBuilder.css';
import CubeGrid                 from './components/CubeGrid'
import {
    SortableFilterCell,
    SingleFilterCell
}                               from './components/FilterCell'
import FetchService             from '../../common/FetchService';
import { ModalFilters }         from './components/ModalFilters';
import { ModalLayouts }         from './components/ModalLayouts';
import Utils                    from '../../common/CommonUtilities';
import { SnackAlert }           from '../../common/SnackAlert';
import BarChartIcon             from '@material-ui/icons/BarChart';
import PieChartIcon             from '@material-ui/icons/PieChart';
import ShowChartIcon            from '@material-ui/icons/ShowChart';
import SearchIcon               from '@material-ui/icons/Search';
import FormControl              from '@material-ui/core/FormControl';
import InputAdornment           from '@material-ui/core/InputAdornment';
import Input                    from '@material-ui/core/Input';
import ClearIcon                from '@material-ui/icons/Clear';
import IconButton               from '@material-ui/core/IconButton';

const debug = false;
const sDefaultErrorMessage = 'Operation failed. Please retry or contact application support';


const Filters = SortableContainer((
    { filters = [], filtersType, openFilter, togglePivot, onClickFunction, onRightClickFunc, refresh, drawerOpen, saveLayout, deleteLayout }
) => {
    const mapFunction = ( filter, index ) => (
        <SortableFilterCell
            key               ={filter.column}
            index             ={index}
            filter            ={filter}
            filtersType       ={filtersType}
            openFilter        ={openFilter}
            togglePivot       ={togglePivot}
            onClickFunction   ={onClickFunction}
            onRightClickFunc  ={onRightClickFunc}
            refresh           ={refresh}
            drawerOpen        ={drawerOpen}
            saveLayout        ={saveLayout}
            deleteLayout      ={deleteLayout}
        />
    );
    if ( filters && filters.length > 0 && filters[0] ) {
        const aoSharedByMe = [...filters.filter( o =>  o.sharedByMe && !o.sharedToMe )];
        const aoSharedToMe = [...filters.filter( o => !o.sharedByMe &&  o.sharedToMe )];
        return (
            <div className={ 'filters-row' }>
                { [...filters.filter( o => !o.sharedByMe && !o.sharedToMe )].map(mapFunction) }
                { !aoSharedByMe.length ? null : <div className="shared byme" ><hr/></div> }
                { aoSharedByMe.map(mapFunction) }
                { !aoSharedToMe.length ? null : <div className="shared tome" ><hr/></div> }
                { aoSharedToMe.map(mapFunction) }
            </div>
        );
    } else {
        return <div></div>
    }
});

class CubeBuilder extends Component {
    
    constructor(props) {
        super(props);
        this.cubeGridChildRef = React.createRef();
        this.state = {

             sCubeDesc:              ''
            ,aoDimensions:           []
            ,aoMeasures:             []
            ,aoLayouts:              []     // tutti i layouts
            ,nLayoutCodSelected:     null   // layout selezionato
            ,aoGrafici:              [
                 { column: 'line' ,description: 'Line' ,icon: <ShowChartIcon/> ,selected: false }
                ,{ column: 'bar'  ,description: 'Bar'  ,icon: <BarChartIcon/>  ,selected: false }
            ]     // tutti i tipi di grafici
            ,sGraphSelected:         ''   // grafico selezionato
            ,oLayoutToEdit:          null
            ,oCubeConf:              {}     // configurazione del cubo (dimensioni e misure)
            
            ,bReloadLayout:          false
            ,bReloadAll:             false
            ,bReset:                 false
            ,showFilterModal:        false
            ,showLayoutModal:        false
            ,sColumnToFilter:        ''
            
            ,asCurrentFilters:       null // per mostrare nella modale dei filtri un elenco fisso di elementi filtrati

            ,oNotifyOptions:         { message: '', severity: '' } // severity: 'error' | 'warning' | 'info' | 'success'
            ,oSearchParams:          { dimensions: '' ,measures: '' ,layouts: '' }

        }
    }

    componentDidUpdate       = async ( prevProps, prevState ) => {
                
        // debug && Utils.logDifferences( '--- CubeBuilder (cambio di props) ---', prevProps, this.props );
        // debug && Utils.logDifferences( '--- CubeBuilder (cambio di state) ---', prevState, this.state );

        if ( this.props.nCubeCod !== prevProps.nCubeCod ) {
            // al cambio di cubo ripristino la situazione iniziale e riseleziono l'eventuale layout di default
            await Utils.setStateAsync({ nLayoutCodSelected: null }, this);
            const aoLayouts = await this.getLayouts();
            const oCubeConf = await this.getConf();
            await Utils.setStateAsync({ aoLayouts, oCubeConf }, this);
            await this.initCube();
        }
        
        if (
               (   ( this.cubeGridChildRef.current.state.bTabularMode && (this.state.aoDimensions||[]).filter( o => o.selected ).length < 2 ) 
                 || !this.cubeGridChildRef.current.state.bTabularMode )
            && (this.state.aoDimensions||[]).filter( o => o.pivoted  ).length < 1
            && (this.state.aoMeasures  ||[]).filter( o => o.selected ).length < 2
        ) {
            if ( !this.state.aoGrafici.find( o => o.column === 'pie' ) ) {
                // console.log('aggiungo torta');
                let aoGrafici = this.state.aoGrafici;
                aoGrafici.push({ column: 'pie'  ,description: 'Pie'  ,icon: <PieChartIcon/>  ,selected: false });
                await Utils.setStateAsync({ aoGrafici }, this);
            }
        } else {
            if ( this.state.aoGrafici.find( o => o.column === 'pie' ) ) {
                // console.log('tolgo torta');
                let aoGrafici = this.state.aoGrafici;
                aoGrafici = aoGrafici.filter(o => o.column !== 'pie');
                await Utils.setStateAsync({aoGrafici}, this);
            }
        }

    }
    
    getLayouts               = async () => {
        
        // caricamento dei layouts
        debug && console.log('--- CubeBuilder: INIZIO chiamata a DB "get-layouts" (nCubeCod:'+this.props.nCubeCod+') ---');
        let aoLayouts = await FetchService.asyncPost({ url: '/cubes/get-layouts', params: { cubeCod: this.props.nCubeCod } }) || [];
        debug && console.log('--- CubeBuilder: FINE   chiamata a DB "get-layouts" (nCubeCod:'+this.props.nCubeCod+') ---');

        const nLayoutCodSelected = this.state.nLayoutCodSelected;
        
        // CREAZIONE LAYOUTS
        aoLayouts = aoLayouts.map( (lay) => ({
            column:      lay.LAYOUT_COD,
            description: lay.LAYOUT_NAME,
            data_ins:    lay.DATA_INSERIMENTO,
            data_mod:    lay.DATA_ULTIMA_MODIFICA,
            default:     lay.FLAG_DEFAULT === 'Y',
            selected:    ( nLayoutCodSelected != null ) ? ( nLayoutCodSelected === lay.LAYOUT_COD ) : ( lay.FLAG_DEFAULT === 'Y' ),
            sharedByMe:  lay.FLAG_SHARED_TO_OTHERS === 'Y',
            sharedToMe:  lay.FLAG_SHARED_TO_USER   === 'Y',
            ownerCod:    lay.LAYOUT_OWNER_COD,
            ownerDesc:   lay.LAYOUT_OWNER_DESC,
            oStructure:  lay.LAYOUT_PARAMETERS
        }));

        return aoLayouts || [];
    }
    
    getConf                  = async () => {
        debug && console.log('--- CubeBuilder: INIZIO chiamata a DB "get-conf" (nCubeCod:'+this.props.nCubeCod+') ---');
        const oConf = await FetchService.asyncGet({
            url: '/cubes/get-conf',
            params: { cubeCod: this.props.nCubeCod },
            displayErrorHandler: (error) => {
                console.error(error);
                this.setState({ oNotifyOptions: { message: error.toString() } });
            }
        });
        debug && console.log('--- CubeBuilder: FINE   chiamata a DB "get-conf" (nCubeCod:'+this.props.nCubeCod+') ---');
        return oConf;

    }
    
    reset                    = async() => {
        await Utils.setStateAsync(
            ({ bReset }) => ({ bReset: !bReset, aoDimensions: [], aoMeasures: [] }),
            this
        );
    }
    
    initCube                 = async ( sTabToSelect = 'dimensions' ) => {
        debug && console.log('init cube');

        this.props.loading.set( true, 'CubeBuilder - initCube' );
        await this.reset();
        
        // caricamento dei layouts
        let aoLayouts         = this.state.aoLayouts;
        const oDefaultLayout  = aoLayouts.find( oLayout => oLayout.selected );
        if ( !this.state.nLayoutCodSelected && oDefaultLayout ) {
            await Utils.setStateAsync({ nLayoutCodSelected: oDefaultLayout.column }, this);
        }
        const oLayoutSelected = aoLayouts.find( oLayout => oLayout.column === this.state.nLayoutCodSelected );  // ATTENZIONE: non mettere default, meglio undefined

        // console.log('initCube - oLayoutSelected: ' + ( oLayoutSelected || {} ).description );

        //caricamento configurazione cubo
        let oCubeConf         = this.state.oCubeConf;

        if ( oCubeConf && oCubeConf['sCubeDesc'] ) {

            const sCubeDesc = oCubeConf['sCubeDesc'];
            
            const oStructure              = oLayoutSelected?.oStructure || {};
            
            // CREAZIONE DIMENSIONI
            
            const aoLayoutDimensions      = oStructure?.aoDimensions || [];
            const asLayoutDimensionsCols  = aoLayoutDimensions.map( dim => dim.column );
            const asConfDimensionsCols    = oCubeConf['dimensions'].map( dim => dim.DIMENSION_COLUMN );
            
            // escludo eventuali dimensioni presenti nel layout ma non più esistenti nel cubo
            const aoValidLayoutDimensions = aoLayoutDimensions.filter(
                oLayoutDimension => asConfDimensionsCols.includes(oLayoutDimension.column)
            );
            
            // trasformo l'array delle dimensioni in un oggetto che ha come chiavi le "column"
            const oConfDimensions = oCubeConf['dimensions'].reduce( ( oConfDim, oDim ) => ({
                ...oConfDim,
                [oDim.DIMENSION_COLUMN]: {
                        // proprietà fisse
                         cod:               oDim.KDIMENSION
                        ,column:            oDim.DIMENSION_COLUMN
                        ,description:       oDim.DIMENSION_DESC
                        ,tooltip:           oDim.TOOLTIP_DESC
                        ,columnWidth:       oDim.COLUMN_WIDTH
                        ,flagAlignment:     oDim.FLAG_ALIGNMENT
                        ,filterType:        oDim.FILTER_TYPE
                        ,filterDataType:    oDim.FILTER_DATATYPE
                        ,filterFlagPivot:   oDim.FLAG_PIVOT
                        ,...( oDim.FLAG_PIVOT_SORTING_TYPE && { FLAG_PIVOT_SORTING_TYPE: oDim.FLAG_PIVOT_SORTING_TYPE } )
                }
            }), {});
            
            // partendo dall'array delle dimensioni del layout mantengo l'ordinamento che era stato salvato con il layout
            // infine aggiungo in fondo a aoDimensions le eventuali dimensioni in più presenti nella configurazione ma non nel layout
            const aoExtraDimensionsFromConf = Object.values(oConfDimensions).filter( oDim => !asLayoutDimensionsCols.includes(oDim.column) );
            let aoDimensions = [
                ...aoValidLayoutDimensions,
                ...aoExtraDimensionsFromConf
            ].map( ( oDim, nIndex, aoDims ) => {
                return {
                    ...oConfDimensions[oDim.column], // prima prendo dalla configurazione tutte le proprietà
                    ...{                             // poi inserisco altre proprietà con valori di default
                                                     // eventualmente sovrascrivendole con quelle che sono presenti nel layout
                        // proprietà modificabili dall'utente
                         selected:          !!oDim.selected       || ( nIndex === 0 )
                        ,pivoted:           !!oDim.pivoted
                        ,exclude:           !!oDim.exclude
                        ,asCurrentFilters:  oDim.asCurrentFilters || []
                        ,sortDirection:     oDim.sortDirection    || 'ASC'
                        ,drillDownValue:    [ 'string', 'number' ].includes( typeof oDim.drillDownValue ) ? oDim.drillDownValue : ''
                        ,subtotal:          oDim.subtotal         || (
                            // se sono attivi i subtotali preseleziono con subtotal a Y le dimensioni selezionate tranne l'ultima  
                            !!oStructure.sSubTotals && ( !!oDim.selected && ( nIndex === ( aoDims.length - 1 ) ) ) ? 'Y' : ''
                        )
                    }
                };
            });
            
            if ( aoDimensions.find( o => o.pivoted ) ) {
                aoDimensions = Utils.arrayMove( aoDimensions, aoDimensions.findIndex( o => o.pivoted ), aoDimensions.length - 1 );
            }

            // CREAZIONE MISURE
            
            const aoLayoutMeasures      = ( ( oLayoutSelected || {} ).oStructure || {} ).aoMeasures || [];
            const asLayoutMeasuresCols  = aoLayoutMeasures.map( mea => mea.column );
            const asConfMeasuresCols    = oCubeConf['measures'].map( mea => mea.MEASURE_COLUMN );

            // escludo eventuali misure presenti nel layout ma non più esistenti nel cubo
            const aoValidLayoutMeasures = aoLayoutMeasures.filter(
                oLayoutMeasure => asConfMeasuresCols.includes(oLayoutMeasure.column)
            );

            // trasformo l'array delle misure in un oggetto che ha come chiavi le "column"
            const oConfMeasures = oCubeConf['measures'].reduce( ( oConfDim, oMea ) => ({
                ...oConfDim,
                [oMea.MEASURE_COLUMN]: {
                    // proprietà fisse
                         cod:               oMea.KMEASURE
                        ,column:            oMea.MEASURE_COLUMN
                        ,description:       oMea.MEASURE_DESC
                        ,tooltip:           oMea.TOOLTIP_DESC
                        ,columnWidth:       oMea.COLUMN_WIDTH
                        ,decimals:          oMea.DECIMALS
                        ,filterType:        oMea.FILTER_TYPE
                        ,filterDataType:    oMea.FILTER_DATATYPE
                        ,summable:          oMea.FLAG_SUM
                }
            }), {});

            // partendo dall'array delle misure del layout mantengo l'ordinamento che era stato salvato con il layout
            // infine aggiungo in fondo a aoMeasures le eventuali misure in più presenti nella configurazione ma non nel layout
            const aoExtraMeasuresFromConf = Object.values(oConfMeasures).filter( oMea => !asLayoutMeasuresCols.includes(oMea.column) );
            let aoMeasures = [
                ...aoValidLayoutMeasures,
                ...aoExtraMeasuresFromConf
            ].map( ( oMea, nIndex ) => {
                return {
                    ...oConfMeasures[oMea.column],   // prima prendo dalla configurazione tutte le proprietà
                    ...{                             // poi inserisco altre proprietà con valori di default
                                                     // eventualmente sovrascrivendole con quelle che sono presenti nel layout
                        // proprietà modificabili dall'utente
                         selected:          !!oMea.selected
                        ,exclude:           !!oMea.exclude
                        ,asCurrentFilters:  oMea.asCurrentFilters || []
                        ,sortDirection:     oMea.sortDirection    || ''
                        ,pSortingValue:     oMea.pSortingValue    || ''
                    }
                };
            });

            const oFirstMeasureSelected = aoMeasures.find( oMeasure => oMeasure.selected );
            if ( !oFirstMeasureSelected ) { aoMeasures[0].selected = true; } // se nessuna è preselezionata, seleziono la prima

            // solo se non è stato selezionato un layout, per la prima misura preimposto l'ordinamento a decrescente
            if ( !oLayoutSelected ) {
                aoMeasures.find( oMeasure => oMeasure.selected ).sortDirection = 'DESC';
            }

            await ( async () => {
                this.props.setTabSelected(sTabToSelect);
                this.props.setLayoutDesc( ( oLayoutSelected || {} ).description || '' );
            })()
            
            await Utils.setStateAsync({
                 sCubeDesc
                ,aoDimensions
                ,aoMeasures
                ,aoLayouts
            }, this);
            
            await Utils.setStateAsync(({ bReloadLayout }) => ({ bReloadLayout: !bReloadLayout }), this);
            
            await Utils.setStateAsync(({ bReloadAll }) => ({ bReloadAll: !bReloadAll }), this);

        } else {
            await Utils.setStateAsync({ oNotifyOptions: { message: 'No configuration for the selected cube.' } }, this);
        }

    }

    toggleNotifica           = ( message, severity ) => {
        this.setState({ oNotifyOptions: { message, severity } });
    }

    handleCloseAlert         = (event, reason) => {
        // TODO decidere se buttare fuori l'utente alla chiusura della notifica
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ oNotifyOptions: { message: '', severity: 'error' } });
    };

    togglePivot              = async ( sDimensionPivotedColumnName ) => {
        await this.cubeGridChildRef.current.gridTogglePivot(sDimensionPivotedColumnName );
    }

    toggleLayout             = async ( nLayoutCod, bInit = true, bForce = false ) => {
    
        const aoLayouts       = this.state.aoLayouts;
        const oLayoutSelected = ( bForce || ( nLayoutCod !== this.state.nLayoutCodSelected ) )
                                    ? ( aoLayouts.find( oLayout => oLayout.column === nLayoutCod ) || { column: 0 } )
                                    : { column: 0 }; // No Layout
        aoLayouts.forEach( oLayout => { oLayout.selected = false; } );
        oLayoutSelected.selected = true;
        
        await Utils.setStateAsync({ nLayoutCodSelected: ( oLayoutSelected && oLayoutSelected.column ) || 0 }, this);
        
        await Utils.setStateAsync({
             oLayoutToEdit:      null
            ,aoLayouts
        }, this);

        if ( bInit ) {
            await this.initCube('layouts'); // re-inizializza e rimane sul tab layout
        }

    }

    saveLayout               = async ({ nLayoutCod, sLayoutName, bFlagDefault, aoUserGroups, aoUsers } ) => {
        
        this.props.loading.set( true, 'CubeBuilder - saveLayout' );

        // save di un layout
        let
            url           = ( nLayoutCod > 0 ) ? '/cubes/mod-layout' : '/cubes/ins-layout',
            params        = {
                 pLayoutName:        sLayoutName || ( this.state.aoLayouts.find( oLayout => oLayout.column === nLayoutCod ) || {} ).description || ''
                ,pFlagDefault:       bFlagDefault ? 'Y' : 'N'
                ,pLayoutParameters:  await this.cubeGridChildRef.current.gridGetParamsForLayout()
            }
        ;
        
        if ( nLayoutCod > 0 ) {
            params.pLayoutCod = nLayoutCod;
        }

        if ( !nLayoutCod || nLayoutCod < 1 ) {
            params.pCubeCod   = this.props.nCubeCod;
        }

        debug && console.log('--- CubeBuilder: chiamata a DB "' + url + '" (pLayoutCod:'+nLayoutCod+') ---');
        const { nRetVal: nRetValModIns, vErrorMessageDesc } = await FetchService.asyncPost({ url, params }) || {};
        const nLayoutCodSelected = ( !nLayoutCod || nLayoutCod < 1 ) ? nRetValModIns : nLayoutCod;
        let oNotifyOptions = null;

        if ( vErrorMessageDesc || nRetValModIns < 1 ) {
            this.props.loading.set( false, 'CubeBuilder - saveLayout' );
            Utils.setStateAsync({ oNotifyOptions: { message: vErrorMessageDesc || sDefaultErrorMessage, severity: 'error' } }, this);
        } else {
            
            // salvataggio dei gruppi e degli utenti a cui è stato condiviso il layout
            let
                url           = '/cubes/share-layout',
                params        = {
                     pLayoutCod:  nLayoutCodSelected
                    ,pGroupList:  aoUserGroups.filter( o => o.FLAG_SELECTED === 'Y' ).map( o => o.CODE ).join(',')
                    ,pUserList:        aoUsers.filter( o => o.FLAG_SELECTED === 'Y' ).map( o => o.CODE ).join(',')
                }
            ;

            debug && console.log('--- CubeBuilder: chiamata a DB "' + url + '" (pLayoutCod:'+params.pLayoutCod+') ---');
            const { nRetVal: nRetValShare, vErrorMessageDesc } = await FetchService.asyncPost({ url, params }) || {};
            oNotifyOptions = {
                 message:  nRetValShare > 0 ? 'Layout saved' : ( vErrorMessageDesc || sDefaultErrorMessage )
                ,severity: nRetValShare > 0 ? 'success'      : 'error'
                ,timeout:  5
            };
            
            // caricamento dei layouts e preselezione di quello appena salvato
            
            const aoLayouts = await this.getLayouts();
            
            await Utils.setStateAsync({
                 aoLayouts              // anche se l'array è vuoto, li carico nello stato
                ,oNotifyOptions
                ,showLayoutModal: false
                ,oLayoutToEdit:   null
             // ,bReloadAll:      !this.state.bReloadAll
            }, this);

            await this.toggleLayout(nLayoutCodSelected, false, true );

            this.props.loading.set( false, 'CubeBuilder - saveLayout' );
            this.props.setLayoutDesc( sLayoutName || '' );

        }

    }
    
    deleteLayout             = async nLayoutCod => {
        
        // delete di un layout
        debug && console.log('--- CubeBuilder: chiamata a DB "del-layouts" (pLayoutCod:'+nLayoutCod+') ---');
        const { nRetVal: nRetValDel, vErrorMessageDesc } = await FetchService.asyncPost({
             url:    '/cubes/del-layout'
            ,params: { pLayoutCod: nLayoutCod }
        }) || {};
        
        this.props.loading.set( false, 'CubeBuilder - deleteLayout' );
        await Utils.setStateAsync(({ aoLayouts }) => ({
             ...( ( nRetValDel > 0 ) && { aoLayouts: aoLayouts.filter( (o) => o.column !== nLayoutCod ) })
            ,showLayoutModal: false
            ,oNotifyOptions:  {
                 message:  nRetValDel > 0 ? 'Layout removed' : vErrorMessageDesc || sDefaultErrorMessage
                ,severity: nRetValDel > 0 ? 'success'        : 'error'
            }
        }), this);
        await this.toggleLayout(0);

    }
    
    changeDimensionsOrder    = async ({ oldIndex, newIndex }) => {
        // modifica posizione degli elementi
        if ( newIndex !== oldIndex ) { // se non cambia l'indice: nessun movimento

            let bReload           = true; // ricarico nella maggior parte dei casi
            
            // verifico in quale modalità mi trovo ( DrillDown o Tabular )
            const isDrillDownMode = !this.cubeGridChildRef.current.state.bTabularMode;

            const aoDimensions    = [...this.state.aoDimensions];
            
            // sposto l'elemento in posizione "oldIndex" nella nuova posizione "newIndex"
            let   newDimensions   = Utils.arrayMove( aoDimensions, oldIndex, newIndex );
            
            // recupero la posizione dell'ultima dimensione selezionata
            let   nOldIndexLastSelected = ( ( aoDimensions.filter( oDimension => oDimension.selected && !oDimension.pivoted ).length - 1 ) || 0 );

            // controllo di sicurezza: questo permette di selezionare almeno il primo elemento
            if ( nOldIndexLastSelected < 1 ) { nOldIndexLastSelected = 0; }

            let sLogCases = '';
            let nNewIndexLastSelected = nOldIndexLastSelected;

            newDimensions.forEach(
                ( oDim, nPosition ) => {

                    sLogCases = '';
                    
                    // in drillDown
                    if ( isDrillDownMode ) {
                        sLogCases += ' DrillDown';
                        
                        // se l'elemento si trova in quelli SELEZIONATI
                        if ( oldIndex <=  nOldIndexLastSelected ) {
                            sLogCases += ' SELEZIONATO';
                            
                            // in particolare se è l'ultimo SELEZIONATO
                            if ( oldIndex === nOldIndexLastSelected ) {
                                sLogCases += ' (ultimo)';
                                
                                // se viene spostato in quelli SELEZIONATI escluso l'ultimo
                                if ( newIndex  <  nOldIndexLastSelected ) {
                                    
                                    sLogCases = 'case 01:' + sLogCases + ' -> SELEZIONATI (non ultimo) \n la selezione deve terminare con il nuovo indice';
                                    nNewIndexLastSelected = newIndex; // la selezione deve terminare con il nuovo indice
                                    // (perché questo elemento DrillDown non ha un DrillDownValue e i successivi non sarebbero validi)

                                // se l'elemento viene spostato in quelli NON SELEZIONATI
                                } else {
                                    sLogCases = 'case 02:' + sLogCases + ' -> NON SELEZIONATI  \n il numero di selezionati non cambia, viene deselezionato l\'elemento e viene selezionato il successivo';
                                    // il numero di selezionati non cambia, viene deselezionato l'elemento e viene selezionato il successivo
                                }
                                
                            // in particolare se è nei SELEZIONATI escluso l'ultimo
                            } else {
                                sLogCases += ' (non ultimo)';

                                // se l'elemento viene spostato in quelli SELEZIONATI escluso l'ultimo
                                if ( newIndex < nOldIndexLastSelected ) {

                                    sLogCases = 'case 03:' + sLogCases + ' -> SELEZIONATO (non ultimo) \n il numero di selezionati non cambia, l\'elemento rimane non selezionato (perché sono ancora validi i valori di DrillDown)';
                                    // il numero di selezionati non cambia, l'elemento rimane non selezionato (perché sono ancora validi i valori di DrillDown)

                                // se viene spostato nella posizione dell'ULTIMO SELEZIONATO
                                } else if ( newIndex === nOldIndexLastSelected ) {
                                    
                                    sLogCases = 'case 04:' + sLogCases + ' -> SELEZIONATO (ultimo) \n la selezione deve terminare con il vecchio indice';
                                    nNewIndexLastSelected = oldIndex; // la selezione deve terminare con il vecchio indice
                                    // (perchè da quell'elemento in poi i DrillDown non sono più validi) 
                                    
                                // se l'elemento viene spostato in quelli NON SELEZIONATI
                                } else {

                                    sLogCases = 'case 05:' + sLogCases + ' -> NON SELEZIONATI \n la selezione deve terminare con il vecchio indice';
                                    nNewIndexLastSelected = oldIndex; // la selezione deve terminare con il vecchio indice
                                    // (perchè da quell'elemento in poi i DrillDown non sono più validi) 
                                    
                                }
                                
                            }
                            
                        }
                        
                        // se l'elemento si trova in quelli NON SELEZIONATI
                        if ( oldIndex  >  nOldIndexLastSelected ) {
                            sLogCases += ' NON SELEZIONATO';
                            
                            // se viene spostato in quelli NON SELEZIONATI
                            if ( newIndex  >  nOldIndexLastSelected ) {

                                sLogCases = 'case 06:' + sLogCases + ' -> NON SELEZIONATI \n il numero di selezionati non cambia, l\'elemento rimane non selezionato';
                                // il numero di selezionati non cambia, l'elemento rimane non selezionato
                                bReload = false; // non ricarico

                            // se l'elemento viene spostato in quelli SELEZIONATI
                            } else {
                                sLogCases += ' -> SELEZIONATI';

                                // in particolare se viene spostato in quelli SELEZIONATI escluso l'ultimo
                                if ( newIndex  <  nOldIndexLastSelected ) {

                                    sLogCases = 'case 07:' + sLogCases + ' (non ultimo) \n la selezione deve terminare con il nuovo indice';
                                    nNewIndexLastSelected = newIndex; // la selezione deve terminare con il nuovo indice
                                    // (perché questo elemento DrillDown non ha un DrillDownValue e i successivi non sarebbero validi)

                                // in particolare se viene spostato nella posizione dell'ULTIMO SELEZIONATO
                                } else {

                                    sLogCases = 'case 08:' + sLogCases + ' (ultimo) \n il numero di selezionati non cambia, viene deselezionato l\'elemento e viene selezionato il successivo';
                                    // il numero di selezionati non cambia, viene deselezionato l'elemento e viene selezionato il successivo
                                    
                                }
                                
                            }
                            
                        }
                        
                    // Tabular Mode
                    } else {
                        sLogCases += ' Tabular';
                        
                        // se l'elemento si trova in quelli SELEZIONATI
                        if ( oldIndex <=  nOldIndexLastSelected ) {
                            sLogCases += ' SELEZIONATO';
                            
                            // se l'elemento viene spostato in quelli SELEZIONATI
                            if ( newIndex <= nOldIndexLastSelected ) {
                                
                                sLogCases = 'case 09:' + sLogCases + ' -> SELEZIONATO \n il numero di selezionati non cambia, l\'elemento rimane selezionato';
                                // il numero di selezionati non cambia, l'elemento rimane selezionato
                                
                            // se l'elemento viene spostato in quelli NON SELEZIONATI
                            } else {
                                
                                sLogCases = 'case 10:' + sLogCases + ' -> NON SELEZIONATI \n il numero di selezionati non cambia, l\'elemento viene deselezionato';
                                // il numero di selezionati non cambia, l'elemento viene deselezionato
                                
                            }
                            
                        // se l'elemento si trova in quelli NON SELEZIONATI
                        } else {
                            sLogCases += ' NON SELEZIONATO';
                            
                            // se l'elemento viene spostato in quelli SELEZIONATI
                            if ( newIndex <= nOldIndexLastSelected ) {
                                
                                sLogCases = 'case 11:' + sLogCases + ' -> SELEZIONATO \n il numero di selezionati non cambia, l\'elemento viene selezionato';
                                // il numero di selezionati non cambia, l'elemento viene selezionato
                                
                            // se l'elemento viene spostato in quelli NON SELEZIONATI
                            } else {
                                
                                sLogCases = 'case 12:' + sLogCases + ' -> NON SELEZIONATI \n il numero di selezionati non cambia, l\'elemento rimane deselezionato';
                                // il numero di selezionati non cambia, l'elemento rimane deselezionato
                                bReload = false; // non ricarico
                                
                            }
                            
                        }
                        
                    }
                    
                    // imposto effettivamente le proprietà "selected" e "drillDownValue"
                    oDim.selected = ( nPosition <= nNewIndexLastSelected  ); // && ( !isDrillDownMode && nPosition === nMinimumPosition )
                    if ( nPosition >= nNewIndexLastSelected ) {
                        oDim.drillDownValue = '';
                    }
                    
                }
            );
            
            debug && console.log(sLogCases);
            
            // controllo di sicurezza: se non rimangono elementi selezionati
            if ( !newDimensions.some( oDim => oDim.selected && !oDim.pivoted ) ) {
                // trovo la prima dimensione non pivottata
                const oFirstNotPivoted = newDimensions.find( oDim => !oDim.selected && !oDim.pivoted );
                // e la seleziono
                oFirstNotPivoted.selected = true; 
            }
            
            // rimuovo subtotal da tutte le dimensioni per cui non è necessario
            const nLastSelectedIndex = newDimensions.findLastIndex( oDimension => oDimension.selected && !oDimension.pivoted );
            newDimensions.slice( nLastSelectedIndex ).filter( oDim => oDim.subtotal ).forEach( oDim => {
                delete oDim.subtotal;
            });
            if (
                this.cubeGridChildRef.current.state.sSubTotals &&
                !newDimensions.find( oDim => oDim.subtotal )
            ) {
                newDimensions[0].subtotal = 'Y';
            }
            
            const oStateToUpdate = { aoDimensions:   newDimensions };
            if ( bReload && this.cubeGridChildRef.current.state.bAutoReload ){
                oStateToUpdate['bReloadAll'] = !this.state.bReloadAll;
            }
            await Utils.setStateAsync(oStateToUpdate, this);
            return newDimensions;
        }
    };

    changeMeasuresOrder      = ({oldIndex, newIndex}) => {
        //modifica posizione degli elementi //se non cambia l'indice è stato fatto un click: nessun movimento
        if( newIndex !== oldIndex ) {
            const aoMeasures  = [...this.state.aoMeasures];
            const newMeasures = Utils.arrayMove(aoMeasures, oldIndex, newIndex);
            this.setState({ aoMeasures: newMeasures });
        }
    };


    /* refreshCubeBuilderState
     * 
     * @param oNewState:      object  - oggetto contenente tutti gli stati di CubeBuilder da aggiornare contemporaneamente
     * @param bRefreshReload: boolean - per forzare ricaricamento (solo se è stato passato almeno uno stato)
     * @returns {Promise<void>}
     */
    refreshCubeBuilderState  = async ( oNewState = {}, bRefreshReload = true, bForceRefreshReload = false ) => {
        
        let   bStateChanged     = false;
        const oNewStateToChange = {};
        if ( bRefreshReload ) {
            oNewStateToChange['bReloadAll'] = !this.state.bReloadAll;
        }
        
        Object.keys(oNewState).forEach( sState => {
            if ( typeof this.state[ sState ] !== 'undefined' ) { // solo se esiste già uno stato con quel nome
                oNewStateToChange[ sState ] = oNewState[ sState ];
                bStateChanged = true;
            } else {
                console.error('Errore in refreshCubeBuilderState. Stato non presente: "' + sState + '"' );
            }
        });

        if ( bStateChanged || bForceRefreshReload ) { // se è stato indicato come stato da cambiare almeno uno stato già esistente
            await Utils.setStateAsync(oNewStateToChange, this);
        }
        
    }

    toggleMeasureVisibility  = async ( sMeasureDesc ) => { 
        
        const aoMeasures         = this.state.aoMeasures;
        const nMeasureIndex      = aoMeasures.findIndex( oMeasure => oMeasure.column === sMeasureDesc );
        const aoMeasuresSelected = aoMeasures.filter(    oMeasure => oMeasure.selected                );
        const isOneMeasureSorted = aoMeasures.some(      oMeasure => oMeasure.sortDirection           ); // se almeno una è ordinata

        if (
              ( aoMeasuresSelected.length >   1 ) ||
            ( ( aoMeasuresSelected.length === 1 ) && ( aoMeasuresSelected[0].column !== sMeasureDesc ) )
        ) {
            
            aoMeasures[nMeasureIndex].selected = !aoMeasures[nMeasureIndex].selected;

            // rimuovo l'ordinamento per le misure non attualmente selezionate
            aoMeasures.filter( oMeasure => !oMeasure.selected ).forEach( oMeasure => {
                oMeasure.sortDirection = '';
            });

            if ( isOneMeasureSorted ) {  // se almeno una era ordinata

                // per le misure non attualmente selezionate
                aoMeasures.filter( oMeasure => !oMeasure.selected ).forEach( oMeasure => {
                    oMeasure.sortDirection = ''; // rimuovo l'ordinamento
                    oMeasure.pSortingValue = ''; // compreso l'eventuale ordinamento in caso di pivot
                });

                /* tentativo di forzare un default di ordinamento sulle misure, ma con il pivot non riesco a settare un pSortingValue
                const aoActualMeasuresSelected = aoMeasures.filter( oMeasure => oMeasure.selected );
                if ( aoActualMeasuresSelected.every( oMeasure => !oMeasure.sortDirection ) ) { // se nessuna di quelle rimaste è ordinata
                    aoActualMeasuresSelected[0].sortDirection = 'DESC'; // forzo l'ordinamento della prima selezionata
                } */

            }
            
            await Utils.setStateAsync({
                 aoMeasures
                , ...( this.cubeGridChildRef.current.state.bAutoReload && { bReloadAll: !this.state.bReloadAll })
            }, this);

        }

    }

    displayMeasures          = ({ aoElements: aoMeasures, bReloadAll }) => {
        return (
            <Filters
                filters           ={ aoMeasures }
                filtersType       ={'measure'}

                openFilter        ={this.openFilter}
                togglePivot       ={this.togglePivot}
                onClickFunction   ={this.toggleMeasureVisibility}
                onRightClickFunc  ={this.onDblClickonMeasure}
                
                refresh           ={bReloadAll}
                drawerOpen        ={this.props.drawerOpen}

                onSortEnd         ={this.changeMeasuresOrder}
                useDragHandle     ={false}
                axis              ="y"
                lockAxis          ="y"
                lockToContainerEdges={true}
                shouldCancelStart ={this.shouldCancelStart}
                disableAutoscroll={false}
            />
        );
    }
    
    displayLayouts           = ({ aoElements: aoLayouts, bReloadAll }) => {
        // const nLayoutCodSelected = this.state.nLayoutCodSelected || 0;
        return ([
            // per salvare un nuovo layout
            <SingleFilterCell
                key               ="new-layout"
                index             ={-1}
                filter            ={ { column: -1, description: 'Save as new Layout' } }
                filtersType       ={'layout'}
                refresh           ={bReloadAll}
                drawerOpen        ={this.props.drawerOpen}
                onClickFunction   ={ (event) => { this.openLayoutModal(-1) } }
            />,
            <Filters
                key               ="layouts-list"
                filters           ={ aoLayouts }
                filtersType       ={'layout'}
                openFilter        ={this.openLayoutModal}
                onClickFunction   ={this.toggleLayout}

                refresh           ={bReloadAll}
                drawerOpen        ={this.props.drawerOpen}
                
                useDragHandle     ={true} // forzo l'uso di un elemento per il drag, ma nascondo l'elemento in css per impedire il drag in generale
            />
        ]);
        // onSortEnd         ={this.onSortEndLayouts}
        // shouldCancelStart ={this.shouldCancelStart}
    }
    
    
    toggleGrafico            = async ( sGraph, bInit = false, bForce = true ) => {
        
        const aoGrafici       = this.state.aoGrafici;
        const oGraphSelected = ( bForce || ( sGraph !== this.state.sGraphSelected ) )
                                ? ( aoGrafici.find( oGrafico => oGrafico.column === sGraph ) || { column: '' } )
                                : { column: '' }; // No Grafico
        aoGrafici.forEach( oGrafico => { oGrafico.selected = false; } );
        oGraphSelected.selected = true;
        const isOneSelected = aoGrafici.find( oGrafico => oGrafico.selected );
        if ( !isOneSelected ) {
            ( aoGrafici[0] || {} ).selected = true;
        }
        
        await Utils.setStateAsync({
             sGraphSelected: ( oGraphSelected && oGraphSelected.column ) || ''
            ,aoGrafici
        }, this);
        
    }
    
    displayGrafici           = ({ aoElements: aoGrafici }) => {
        return <Filters
            key               ="grafici-list"
            filters           ={ aoGrafici }
            filtersType       ={'grafico'}
            onClickFunction   ={this.toggleGrafico}
            drawerOpen        ={this.props.drawerOpen}
            useDragHandle     ={true} // forzo l'uso di un elemento per il drag, ma nascondo l'elemento in css per impedire il drag in generale
        />
    }

    // per risolvere bug di trascinamento delle dimensioni tenendo premuto il pulsante sinistro del mouse sull'icona del filtro (lente ingrandimento)
    shouldCancelStart        = (event) => {
        return [ 'fa-filter', 'chip-filter-handle', 'chip-pivot', 'span-pivot' ].some( sClassName => event.target.classList.value.includes(sClassName) );
    }
    
    displaySearchBox         = () => {
        return <div className={ 'chip-wrapper ' }>
            <FormControl variant="outlined">
                <Input
                    key             ="search-box"
                    label           ="Search"
                    value           ={ this.state.oSearchParams[ this.props.sTabSelected ] || '' }
                    placeholder     ="Search"
                    autoComplete    ="off"
                    autoFocus       ={true}
                    onFocus         ={ e => { e.target.select(); } } 
                    onChange        ={ e => {
                        const value = ( e.target.value || '' ).toLowerCase().trim();
                        const applychange = ao => {
                            // versione per "tagliare" i risultati
                            // return ao.filter( o => o.description.toLowerCase().includes( value.toLowerCase() ) );
                            // versione per "opacizzare" i risultati ( ma in css decido anche se nasconderli proprio)
                            return ao.map( o => {
                                o.searched = ( o.description || '' ).toLowerCase().trim().includes( value ) 
                                             || ( o.tooltip  || '' ).toLowerCase().trim().includes( value );
                                return o;
                            })
                            /* versione mista
                            return ao.filter(
                                o => o.selected || ( !o.selected && o.description.toLowerCase().includes( value.toLowerCase() ) )
                            ).map( o => {
                                o.searched = o.description.toLowerCase().includes( value.toLowerCase() );
                                return o;
                            }) */
    
                        };
                        this.setState({
                             oSearchParams: {
                                 ...this.state.oSearchParams
                                 ,[ this.props.sTabSelected ]: e.target.value || ''
                             }
                            ,aoDimensions   : applychange(this.state.aoDimensions)
                            ,aoMeasures     : applychange(this.state.aoMeasures)
                            ,aoLayouts      : applychange(this.state.aoLayouts)
                        });
                    } }
                    type            ="text"
                    size            ="small"
                    className       ="search-box"
                    startAdornment  ={ <InputAdornment position="end"><SearchIcon/></InputAdornment> }
                    endAdornment={
                        this.state.oSearchParams[ this.props.sTabSelected ] &&
                        <InputAdornment position="end">
                            <IconButton onClick={this.resetOnTabchange} className="search-cancel-X">
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </div>
    }
    
    /*
                        startAdornment    ={
                            <InputAdornment position="end">
                                <IconButton onClick={ undefined }>
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
    */

    displayPivotDimension    = ({ aoElements: aoDimensions, bReloadAll, isThisTabSelected }) => {
        const dimensionPivoted = aoDimensions.find( d => d.pivoted );
        return (
            <Paper elevation={0} className={ 'pivot-dimensions showInMenu ' + ( isThisTabSelected && !!dimensionPivoted ? 'show' : 'hide' ) }>
                <span>Pivot</span>
                <div className='pivot-dimensions-container'>
                    <Filters
                        filters           ={ [dimensionPivoted] }
                        filtersType       ={'dimension'}
    
                        openFilter        ={this.openFilter}
                        togglePivot       ={this.togglePivot}
    
                        refresh           ={bReloadAll}
                        drawerOpen        ={this.props.drawerOpen}
    
                        useDragHandle     ={true}
                        axis              ="y"
                        lockAxis          ="y"
                        lockToContainerEdges={true}
                        shouldCancelStart ={this.shouldCancelStart}
                        disableAutoscroll={false}
                    />
                </div>
            </Paper>
        )
    }
    
    openFilter               = sColumnToFilter => {
        this.setState({
             sColumnToFilter
            ,showFilterModal: true
        });
    }

    openLayoutModal          = async nLayoutCodToEdit => {
                              // trovo l'oggetto Layout in base al suo codice                 oppure creo un nuovo layout con i dati correnti
        const oLayoutToEdit = this.state.aoLayouts.find( o => o.column === nLayoutCodToEdit ) || {
             column:     nLayoutCodToEdit
            ,oStructure: await this.cubeGridChildRef.current.gridGetParamsForLayout()
        };
        this.setState({
             oLayoutToEdit
            ,showLayoutModal: true
        });
    }

    onDblClickonDimension    = ( sDimensionDescToGoUp ) => {
        const aoDims = this.state.aoDimensions;
        const oDim   = aoDims.find( oDim => oDim.column === sDimensionDescToGoUp );
        if ( oDim && !oDim.selected  ) {
            const oldIndex = aoDims.findIndex( oDim => oDim.column === sDimensionDescToGoUp );
            const newIndex = aoDims.findIndex( oDim => !oDim.selected && !oDim.pivoted );
            return this.changeDimensionsOrder({ oldIndex, newIndex });  
        }
    }
    
    onDblClickonMeasure      = ( sMeasureDescToGoUp ) => {
        const aoMeas = this.state.aoMeasures;
        const oMea   = aoMeas.find( oMea => oMea.column === sMeasureDescToGoUp );
        if ( oMea ) {
            const oldIndex = aoMeas.findIndex( oMea => oMea.column === sMeasureDescToGoUp );
            const newIndex = aoMeas.findIndex( oMea => !oMea.selected );
            this.changeMeasuresOrder({ oldIndex, newIndex });
        }
    }
    
    resetOnTabchange         = () => {
        const resetSearch = ao => ao.map( o => { delete o.searched; return o; });
        this.setState({
             sGraphSelected : ''
            ,oSearchParams  : { dimensions: '' ,measures: '' ,layouts: '' }
            ,aoDimensions   : resetSearch(this.state.aoDimensions)
            ,aoMeasures     : resetSearch(this.state.aoMeasures)
            ,aoLaytous      : resetSearch(this.state.aoLayouts)
        });
    }
    
    render() {
        const {
             sCubeDesc
            ,aoDimensions
            ,aoMeasures
            ,aoLayouts
            ,nLayoutCodSelected
            ,oLayoutToEdit
            ,aoGrafici
            
            ,bReloadLayout
            ,bReloadAll
            ,bReset
            ,showFilterModal
            ,showLayoutModal
            ,sColumnToFilter
            ,oNotifyOptions
            ,asCurrentFilters
            ,sGraphSelected
        } = this.state;
        
        const sPivotDimension = ( aoDimensions.find( oDimension => oDimension.pivoted ) || {} ).column || '';
        const oLayoutSelected = aoLayouts.find( oLayout => oLayout.column === nLayoutCodSelected ); // ATTENZIONE: non mettere default, meglio undefined
        // console.log('- Cubebuilder - render - aoDimensions PUNTO_ORA_INI_BREAK filterType: ', aoDimensions.find( o => o.column === 'PUNTO_ORA_INI_BREAK' )?.filterType);
        return ( <div id="cube-builder" className="cube-builder">

            { // popup di notifica degli errori
                <SnackAlert oNotifyOptions={ { ...oNotifyOptions, handleCloseAlert: this.handleCloseAlert } } />
            }
            
            { // popup finestra modale per filtrare i valori di una dimensione
                showFilterModal && <ModalFilters
                    id                      = "ModalFilters"
                    nCubeCod                = { this.props.nCubeCod }
                    showFilterModal         = { showFilterModal }
                    bReloadAll              = { bReloadAll }
                    aoDimensions            = { Utils.deepCopyArrayOrObject(aoDimensions) }
                    aoMeasures              = { Utils.deepCopyArrayOrObject(aoMeasures  ) }
                    refreshCubeBuilderState = { this.refreshCubeBuilderState }
                    sColumnToFilter         = { sColumnToFilter }
                    oDrillDownFilters       = { ( this.cubeGridChildRef.current.getParamsForDB() || {} ).oDrillDownFilters }
                    asCurrentFilters        = { asCurrentFilters }
                    toggleNotifica          = { this.toggleNotifica }
                    nZoom                   = { /* this.props.nZoom */ 0 }
                    loading                 = { this.props.loading }
                    PROCEDURE_NAME          = { this.props.PROCEDURE_NAME }
                />
            }

            { // popup finestra modale per visualizzare e modificare i layout
                showLayoutModal && <ModalLayouts
                    id                      = "ModalLayouts"
                    oLayoutToEdit           = { oLayoutToEdit }
                    showLayoutModal         = { showLayoutModal }
                    saveLayout              = { this.saveLayout }
                    deleteLayout            = { this.deleteLayout }
                    bReloadAll              = { bReloadAll }
                    aoDimensions            = { aoDimensions }
                    aoMeasures              = { aoMeasures }
                    refreshCubeBuilderState = { this.refreshCubeBuilderState }
                    toggleNotifica          = { this.toggleNotifica }
                    nZoom                   = { /* this.props.nZoom */ 0 }
                    loading                 = { this.props.loading }
                />
            }

            <div id="filters-wrap" className={ 'filters-wrap ' + ( this.props.sTabSelected !== 'menu' ? '' : 'close' ) + ( this.props.drawerOpen ? '' : ' drawerClosed' ) }>
    
                <div id="body-search" className={
                    'body search-container showInMenu ' +
                    ( [ 'dimensions' ,'measures' ,'layouts' ].includes(this.props.sTabSelected) ? 'show' : 'hide' )
                }>{
                    this.displaySearchBox()
                }</div>
                
                <div id="body-dimensions" className={
                    'body dimensions-container showInMenu ' +
                    ( this.props.sTabSelected === 'dimensions' ? 'show' : 'hide' ) +
                    ( sPivotDimension ? ' pivoted ' : '')
                }>
                    {/*<Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200} >*/}
                        <Filters
                            filters             ={aoDimensions}
                            filtersType         ={'dimension'}

                            openFilter          ={this.openFilter}
                            togglePivot         ={this.togglePivot}

                            refresh             ={bReloadAll}
                            drawerOpen          ={this.props.drawerOpen}
                            onRightClickFunc    ={this.onDblClickonDimension}

                            onSortEnd           ={this.changeDimensionsOrder}
                            useDragHandle       ={false}
                            axis                ="y"
                            lockAxis            ="y"
                            lockToContainerEdges={true}
                            shouldCancelStart   ={this.shouldCancelStart}
                            disableAutoscroll   ={false}
                        />
                    {/*</Scrollbars>*/}
                </div>

                {
                        this.displayPivotDimension({
                             aoElements:         aoDimensions
                            ,bReloadAll
                            ,isThisTabSelected:  this.props.sTabSelected === 'dimensions'
                        })
                }

                <div id="body-measures" className={ 'body measures-container showInMenu ' + ( this.props.sTabSelected === 'measures' ? 'show' : 'hide' ) }>
                    {
                        this.displayMeasures({
                             aoElements:         aoMeasures
                            ,bReloadAll
                        })
                    }
                </div>

                <div id="body-layouts" className={ 'body layouts-container showInMenu ' + ( this.props.sTabSelected === 'layouts' ? 'show' : 'hide' ) }>
                    {
                        this.displayLayouts({
                             aoElements:         aoLayouts
                            ,bReloadAll
                        })
                    }
                </div>
    
    
                <div id="body-grafici" className={ 'body grafici-container showInMenu ' + ( this.props.sTabSelected === 'grafici' ? 'show' : 'hide' ) }>
                    {
                        this.displayGrafici({
                             aoElements:         aoGrafici
                            ,bReloadAll
                        })
                    }
                </div>

                <div className={ 'maniglia menu '       + ( this.props.sTabSelected === 'menu'       ? 'enabled' : '' ) }
                     onClick={ () => { this.props.setTabSelected('menu');       this.resetOnTabchange(); } }
                >Menu</div>
                
                <div className={ 'maniglia dimensions ' + ( this.props.sTabSelected === 'dimensions' ? 'enabled' : '' ) }
                     onClick={ () => { this.props.setTabSelected('dimensions'); this.resetOnTabchange(); } }
                >Dimensions</div>
                
                <div className={ 'maniglia measures '   + ( this.props.sTabSelected === 'measures'   ? 'enabled' : '' ) }
                     onClick={ () => { this.props.setTabSelected('measures');   this.resetOnTabchange(); } }
                >Measures</div>
                
                <div className={ 'maniglia layouts '    + ( this.props.sTabSelected === 'layouts'    ? 'enabled' : '' ) }
                     onClick={ async () => {
                         await Utils.setStateAsync({ aoLayouts: await this.getLayouts() }, this);
                         // await this.cubeGridChildRef.current.forceAutoReload(); RIMOSSO, NON NECESSARIO PER QUESTA TAB
                         this.props.setTabSelected('layouts');
                         this.resetOnTabchange();
                     } }
                >Layouts</div>
                
                <div className={ 'maniglia grafici '    + ( this.props.sTabSelected === 'grafici'    ? 'enabled' : '' ) }
                     onClick={ async () => {
                         this.resetOnTabchange();
                         await this.cubeGridChildRef.current.forceAutoReload();
                         this.toggleGrafico('line');
                         this.props.setTabSelected('grafici');
                     } }
                >Charts</div>
                
            </div>

            {
                
                <CubeGrid
                    id                      ="CubeGrid"
                    nCubeCod                ={this.props.nCubeCod}
                    sCubeDesc               ={sCubeDesc}
                    cubeDateTime            ={ this.props.cubeDateTime }
                    PROCEDURE_NAME          ={this.props.PROCEDURE_NAME}
                    maxTabDim               ={this.props.maxTabDim}
                    aoDimensions            ={aoDimensions}
                    aoMeasures              ={aoMeasures}
                    oLayout                 ={oLayoutSelected}
                    bReloadLayout           ={bReloadLayout}
                    bReloadAll              ={bReloadAll}
                    bReset                  ={bReset}
                    sTabSelected            ={this.props.sTabSelected}
                    ref                     ={this.cubeGridChildRef}
                    toggleNotifica          ={this.toggleNotifica}
                    loading                 ={this.props.loading}
                    refreshCubeBuilderState ={this.refreshCubeBuilderState}
                    onClickFiltersBtn       ={ (event) => { this.openLayoutModal(-2) } }
                    sGraphSelected          ={sGraphSelected}
                    onDblClickonDimension   ={this.onDblClickonDimension}
                />
                
            }

        </div> )
    }
}

export default CubeBuilder;
