import React, {useEffect, useState} from 'react';
import "./UserGroups.css";
import {createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FetchService from "../../common/FetchService";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
    overrides: { MuiTooltip: { tooltip: { fontSize: '0.9em' } } },
    palette: {
      primary: {
        main: '#519657'
      },
      secondary: {
        main: 'rgb(33, 33, 33)'
      },      
    },
    typography: {
        useNextVariants: true,
        fontSize: 13,
    }    
  });

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 550,
        marginTop: 10,
    },   
    formControl: {
        marginBottom: 15,
        minWidth: 120,
        display: 'block'
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function Group(props) {
    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState(0);
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = React.useState(null);
    const [cubes, setCubes] = useState([]);
    const [cube, setCube] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [users, setUsers] = useState([]);
    const [dimensions, setDimensions] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [loading, setLoading] = React.useState(true);
    const [showEditGroupModal, setShowEditGroupModal] = React.useState(false);
    const [modalMode, setModalMode] = React.useState('new');
    const [txtUserGroup, setTxtUserGroup] = useState([]);
    const [showDeleteGroupModal, setShowDeleteGroupModal] = React.useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    

    const loadUsers = async (groupCod) => {
        setUsers( await FetchService.asyncGet({ url: '/settings/get-user-group-users', params: { groupCod } }) || [] );
    }

    const loadDimensions = async (groupCod, nCubeCod) => {
        setDimensions( await FetchService.asyncGet({ url: '/settings/get-user-group-dimensions', params: { groupCod, cubeCod: nCubeCod } }) || [] );
    }
    
    const loadMeasures = async (groupCod, nCubeCod) => {
        setMeasures( await FetchService.asyncGet({ url: '/settings/get-user-group-measures', params: { groupCod, cubeCod: nCubeCod } }) || [] );
    }

    const loadGroups = async () => {
        return await FetchService.asyncGet({ url: '/settings/get-user-groups' }) || [];
    }

    useEffect(() => {

        const loadCubes = async () => {
            return await FetchService.asyncGet({ url: '/settings/get-cubes' }) || [];
        }

        async function init() {
            const groups = await loadGroups();
            setGroups(groups);

            const selectedGroup = groups[0] || {};
            setGroup(selectedGroup);

            const cubes = await loadCubes();
            setCubes(cubes);

            const selectedCube = cubes[0];
            setCube(selectedCube);

            await loadUsers(selectedGroup.GROUP_COD);

            await loadDimensions(selectedGroup.GROUP_COD, selectedCube.CUBE_COD);

            await loadMeasures(selectedGroup.GROUP_COD, selectedCube.CUBE_COD);

            setLoading(false);
        }

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.reload ]);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangeIndexTab = (index) => {
        setTabValue(index);
    };

    const selectGroup = (event, groupCod) => {
        event.stopPropagation();
        
        const selectedGroup = groups.find((item) => item.GROUP_COD === groupCod);
        if(selectedGroup !== null){
            setGroup(selectedGroup);
            loadUsers(selectedGroup.GROUP_COD);
            loadDimensions(selectedGroup.GROUP_COD, cube.CUBE_COD);
            loadMeasures(selectedGroup.GROUP_COD, cube.CUBE_COD);
        }
    }

    const handleChangeCube = (event) => {
        const selectedCube = cubes.find((item) => item.CUBE_COD === event.target.value);
        setCube(selectedCube);

        loadDimensions(group.GROUP_COD, selectedCube.CUBE_COD);

        loadMeasures(group.GROUP_COD, selectedCube.CUBE_COD);
    };
    
    const handleCloseCube = () => {
        setOpen(false);
    };
    
    const handleOpenCube = () => {
        setOpen(true);
    };   
    
    const handleSetUsers = async (event, kUser, flagSelected) => {

        const params = {
            kUser,
            groupCod: group.GROUP_COD,
            flagSelected
        };

        const response = await FetchService.asyncPost({ url: "/settings/set-user-group-users", params });
        const retVal   = ( response || {} ).nRetVal || 0;

        if ( retVal > 0 ) {
            loadUsers(group.GROUP_COD);
        } else {

            if(flagSelected === 'Y'){
    
                let errorMessage = "";
                switch (retVal) {
                    case 0:
                        errorMessage = 'Oops, an error has occurred';
                        break;                    
                    case -1:
                        errorMessage = 'Group not valid';
                        break;
                    case -2:
                        errorMessage = 'User not valid';
                        break;                    
                    case -3:
                        errorMessage = 'User already exists in the current group';
                        break;
                    case -4:
                        errorMessage = 'Maximum number of licences exceeded';
                        break;
                    case -24:
                        errorMessage = 'Insufficient privileges to complete the operation';
                        break;                                              
                    default:
                        errorMessage = 'Oops, an error has occurred';
                        break;
                }
    
                setErrorMessage(errorMessage);
                setOpenMessage(true);
    
            }else if(flagSelected === 'N'){
                let errorMessage = "";
                switch (retVal) {
                    case 0:
                        errorMessage = 'Oops, an error has occurred';
                        break; 
                    case -1:
                        errorMessage = 'User not found in the current group';
                        break;
                    case -24:
                        errorMessage = 'Insufficient privileges to complete the operation';
                        break;                     
                    default:
                        errorMessage = 'Oops, an error has occurred';
                        break;
                }
    
                setErrorMessage(errorMessage);
                setOpenMessage(true);     
    
            } else {
                console.error('flagSelected non valorizzato', flagSelected);
            }
            
        }
    }

    const handleSetDimensions = async (event, kDimension, flagSelected) => {

        const params = {
            kDimension,
            groupCod: group.GROUP_COD,
            cubeCod: cube.CUBE_COD,
            flagSelected
        };

        const retVal = await FetchService.asyncPost({ url: "/settings/set-user-group-dimensions", params });

        if ( retVal > 0 ) {
            loadDimensions(group.GROUP_COD, cube.CUBE_COD);
        } else { 
                
            if(flagSelected === 'Y'){
                let errorMessage = "";
                switch (retVal) {
                    case 0:
                        errorMessage = 'Oops, an error has occurred';
                        break;                    
                    case -1:
                        errorMessage = 'Group not valid';
                        break;
                    case -2:
                        errorMessage = 'Dimension not valid';
                        break;    
                    case -3:
                        errorMessage = 'Dimension already exists in the current group';
                        break;                                     
                    case -24:
                        errorMessage = 'Insufficient privileges to complete the operation';
                        break;                      
                    default:
                        errorMessage = 'Oops, an error has occurred';
                        break;
                }
                setErrorMessage(errorMessage);
                setOpenMessage(true);
    
            }else if(flagSelected === 'N'){
                let errorMessage = "";
                switch (retVal) {
                    case 0:
                        errorMessage = 'Oops, an error has occurred';
                        break;                    
                    case -1:
                        errorMessage = 'Dimension not found in the current group';
                        break;                                  
                    case -24:
                        errorMessage = 'Insufficient privileges to complete the operation';
                        break;                      
                    default:
                        errorMessage = 'Oops, an error has occurred';
                        break;
                }
                setErrorMessage(errorMessage);
                setOpenMessage(true);
            } else {
                console.error('flagSelected non valorizzato', flagSelected);
            }
            
        }
    }    

    const handleSetMeasures = async (event, kMeasure, flagSelected) => {

        const params = {
            kMeasure,
            groupCod: group.GROUP_COD,
            cubeCod: cube.CUBE_COD,
            flagSelected
        };
        
        const retVal = await FetchService.asyncPost({ url: "/settings/set-user-group-measures", params });

        if ( retVal > 0 ) {
            loadMeasures(group.GROUP_COD, cube.CUBE_COD);
        } else {

            if(flagSelected === 'Y'){
    
                let errorMessage = "";
                switch (retVal) {
                    case 0:
                        errorMessage = 'Oops, an error has occurred';
                        break;
                    case -1:
                        errorMessage = 'Group not valid';
                        break;
                    case -2:
                        errorMessage = 'Measure not valid';
                        break;
                    case -3:
                        errorMessage = 'Measure already exists in the current group';
                        break;
                    case -24:
                        errorMessage = 'Insufficient privileges to complete the operation';
                        break;
                    default:
                        errorMessage = 'Oops, an error has occurred';
                        break;
                }
                setErrorMessage(errorMessage);
                setOpenMessage(true);
            }else if(flagSelected === 'N'){
                let errorMessage = "";
                switch (retVal) {
                    case 0:
                        errorMessage = 'Oops, an error has occurred';
                        break;
                    case -1:
                        errorMessage = 'Group not valid';
                        break;
                    case -24:
                        errorMessage = 'Measure not found in the current group';
                        break;
                    default:
                        errorMessage = 'Oops, an error has occurred';
                        break;
                }
                setErrorMessage(errorMessage);
                setOpenMessage(true);
            } else {
                console.error('flagSelected non valorizzato', flagSelected);
            }
            
        }
    }    

    const handleOpenGroupModalApply = () => {
        setModalMode('new');
        setTxtUserGroup('');
        setShowEditGroupModal(true);
    }

    const handleEditGroupModalApply = async () => {
        let retVal = null;

        if(modalMode === 'new'){
            retVal = await FetchService.asyncPost({ url: "/settings/ins-user-group", params: { groupDesc: txtUserGroup } });
        }else{
            const params = {
                groupCod:  group.GROUP_COD,
                groupDesc: txtUserGroup,
            };
            retVal = await FetchService.asyncPost({ url: "/settings/mod-user-group", params });
        }


        if (retVal > 0) {
            const myGroups = await loadGroups();
            setGroups(myGroups);

            let selectedGroupCod = null;
            if (modalMode === 'new') {
                selectedGroupCod = retVal;

            } else {
                selectedGroupCod = group.GROUP_COD
            }
            const selectedGroup = myGroups.find((item) => item.GROUP_COD === selectedGroupCod);
            setGroup(selectedGroup);
            loadUsers(selectedGroup.GROUP_COD);
            loadDimensions(selectedGroup.GROUP_COD, cube.CUBE_COD);
            loadMeasures(selectedGroup.GROUP_COD, cube.CUBE_COD);
        } else {
            let errorMessage = "";
            switch (retVal) {
                case 0:
                    errorMessage = 'Oops, an error has occurred';
                    break;
                case -1:
                    errorMessage = 'Group name not specified';
                    break;
                case -2:
                    errorMessage = 'Group name already exists';
                    break;
                case -24:
                    errorMessage = 'Insufficient privileges to complete the operation';
                    break;
                default:
                    errorMessage = 'Oops, an error has occurred';
                    break;
            }
            setErrorMessage(errorMessage);
            setOpenMessage(true);
        }

        setShowEditGroupModal(false);
    }

    const handleEditGroupModalCancel = () => {
        setShowEditGroupModal(false);
    }

    const handleChangeTxtUserGroup = (event) => {
        setTxtUserGroup(event.target.value);
    }

    const handleOpenDeleteGroupModalApply = (event, groupCod) => {
        event.stopPropagation();

        const selectedGroup = groups.find((item) => item.GROUP_COD === groupCod);
        if(selectedGroup !== null){
            setGroup(selectedGroup);
            loadUsers(selectedGroup.GROUP_COD);
            loadDimensions(selectedGroup.GROUP_COD, cube.CUBE_COD);
            loadMeasures(selectedGroup.GROUP_COD, cube.CUBE_COD);
            setTabValue(0);
        }

        setShowDeleteGroupModal(true);
    }

    const handleDeleteGroupModalCancel = () => {
        setShowDeleteGroupModal(false);
    }

    const handleDeleteGroupModalApply = async () => {
        deleteGroup();
        setShowDeleteGroupModal(false);
    }

    const deleteGroup = async () => {
        
        const retVal = await FetchService.asyncPost({ url: "/settings/del-user-group", params: { groupCod: group.GROUP_COD } });

        if (retVal > 0) {
            const groups = await loadGroups();
            setGroups(groups);
        } else {
            let errorMessage = "";
            switch (retVal) {
                case 0:
                    errorMessage = 'Oops, an error has occurred';
                    break;
                case -1:
                    errorMessage = 'Group not found';
                    break;
                case -24:
                    errorMessage = 'Insufficient privileges to complete the operation';
                    break;
                default:
                    errorMessage = 'Oops, an error has occurred';
                    break;
            }
            setErrorMessage(errorMessage);
            setOpenMessage(true);
        }
        
    }

    const handleOpenEditGroupModal = (event, groupCod) => {
        const selectedGroup = groups.find((item) => item.GROUP_COD === groupCod);
        setTxtUserGroup(selectedGroup.GROUP_DESC);
        setModalMode('edit');
        setShowEditGroupModal(true);
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMessage(false);
    };

    return (
        loading
            ?
                <div>&nbsp;</div>
            :
                <div className="user-groups">
                    <MuiThemeProvider theme={theme}>

                        <Snackbar open={openMessage}
                                autoHideDuration={6000}
                                onClose={handleCloseAlert}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                        >
                            <Alert onClose={handleCloseAlert} severity="error">
                                {errorMessage}
                            </Alert>
                        </Snackbar>

                        <Dialog
                            open={showDeleteGroupModal}
                            TransitionComponent={Transition}
                            onClose={handleDeleteGroupModalCancel}
                        >
                            <DialogTitle>Delete User group</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                Are you sure you want to remove the selected User Group?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDeleteGroupModalApply}color="primary">
                                    delete
                                </Button>
                                <Button onClick={handleDeleteGroupModalCancel} color="secondary">
                                    cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={showEditGroupModal}
                            TransitionComponent={Transition}
                            onClose={handleEditGroupModalCancel}
                        >
                            <DialogTitle>{modalMode === 'new' ? 'New User group' : 'Edit User group'}</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="user-group-desc"
                                    label="User group name"
                                    type="text"
                                    style={{width: 250}}
                                    value={txtUserGroup}
                                    onChange={handleChangeTxtUserGroup}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleEditGroupModalApply}color="primary">
                                    apply
                                </Button>
                                <Button onClick={handleEditGroupModalCancel} color="secondary">
                                    cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Grid item xs={12} md={12} lg={12}>
                            <Paper>
                                <div className="main">
                                    <div className="grid-parent">
                                        <div className="grid-child left">
                                            <Typography variant="subtitle1" component="h1">
                                                Groups
                                                <Tooltip title="Add Group">
                                                    <Fab color="primary" 
                                                         aria-label="add" 
                                                         size="small" 
                                                         style={{marginLeft: 20}}
                                                         onClick={handleOpenGroupModalApply} >
                                                        <AddIcon />
                                                    </Fab>
                                                </Tooltip>
                                            </Typography>

                                            <TableContainer component={Paper} style={{width: 370, marginTop: 10, marginBottom: 30}}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell component="th" align="center" style={{width: 20}}>&nbsp;</TableCell>
                                                            <TableCell component="th" align="left" style={{width: 350}}>Name</TableCell>
                                                            <TableCell component="th" align="center" style={{width: 20}}>&nbsp;</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            groups.map((item, index) => {
                                                                return (
                                                                    <TableRow key={index} 
                                                                              className={item.GROUP_COD === group.GROUP_COD ? "row selected" : "row"} 
                                                                              onClick={(event) => selectGroup(event, item.GROUP_COD)} >
                                                                        <TableCell align="center" className="icon-link-cell" style={{width: 20}} onClick={(event) => handleOpenEditGroupModal(event, item.GROUP_COD)}>
                                                                            <Tooltip title="Edit">
                                                                                <EditIcon />
                                                                            </Tooltip>
                                                                        </TableCell>                                                                
                                                                        <TableCell align="left" style={{width: 350}}>{item.GROUP_DESC}</TableCell>
                                                                        <TableCell align="left" className="icon-link-cell" style={{width: 20}} onClick={(event) => handleOpenDeleteGroupModalApply(event, item.GROUP_COD)}>
                                                                                <Tooltip title="Remove">
                                                                                    <DeleteIcon  />
                                                                                </Tooltip>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>

                                        <div className="grid-child right">
                                            <Typography variant="subtitle1" component="h1">
                                                Selected Group: <span className="selected-group">{group.GROUP_DESC}</span>
                                            </Typography>

                                            <div className={classes.root + ' users-dimensions-measures'}>
                                                <AppBar position="static" color="default">
                                                    <Tabs
                                                    value={tabValue}
                                                    onChange={handleChangeTab}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    variant="fullWidth"
                                                    aria-label="full width tabs example"
                                                    >
                                                        <Tab label="Users" />
                                                        <Tab label="Dimensions / Measures" />
                                                    </Tabs>
                                                </AppBar>
                                                <SwipeableViews
                                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                    index={tabValue}
                                                    onChangeIndex={handleChangeIndexTab}
                                                >
                                                    <TabPanel value={tabValue} index={0} dir={theme.direction}>

                                                        <div className="outer-container">
                                                            
                                                            <div className="select-all">
                                                                <span onClick={ (event) => handleSetUsers(event, '', 'Y') } >Select all</span>
                                                                <span onClick={ (event) => handleSetUsers(event, '', 'N') } >Deselect all</span>
                                                            </div>
    
                                                            <TableContainer component={Paper} style={{width: 340, marginTop: 10, marginBottom: 30}}>
                                                                <Table className={classes.table}>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell component="th" align="center" style={{width: 20}}>&nbsp;</TableCell>
                                                                            <TableCell component="th" align="left" style={{width: 150}}>Name</TableCell>
                                                                            <TableCell component="th" align="center" style={{width: 20}}>&nbsp;</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            users.map((item, index) => {
                                                                                return (
                                                                                    <TableRow key={index}>                                                              
                                                                                        <TableCell align="left" style={{width: 20}} className={item.FLAG_ATTIVO === 'Y' ? 'cell-user' : 'cell-user disabled' }>
                                                                                            { item && item.FLAG_ATTIVO === 'Y'
                                                                                                ? 
                                                                                                item.FLAG_ADMIN === 'Y' 
                                                                                                    ?
                                                                                                    <Tooltip title="Administrator"><SupervisorAccountIcon /></Tooltip> 
                                                                                                    :
                                                                                                    ''
                                                                                                : 
                                                                                                <Tooltip title="Disabled"><CloseIcon /></Tooltip> 
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell align="left" style={{width: 240}} className={item.FLAG_ATTIVO === 'Y' ? 'cell-user' : 'cell-user disabled' }>{item.FULL_NAME}</TableCell>
                                                                                        {
                                                                                            item.FLAG_SELECTED === 'Y'
                                                                                            ?
                                                                                            <TableCell align="left" 
                                                                                                       className="icon-link-cell check-selected" 
                                                                                                       style={{width: 20}} 
                                                                                                       onClick={(event) => handleSetUsers(event, item.KUSER, 'N')} >
                                                                                                <CheckIcon />
                                                                                            </TableCell>
                                                                                            : 
                                                                                            <TableCell align="left" 
                                                                                                       className="icon-link-cell check-not-selected" 
                                                                                                       style={{width: 20}}
                                                                                                       onClick={(event) => handleSetUsers(event, item.KUSER, 'Y')} 
                                                                                                       >
                                                                                                <CheckIcon />
                                                                                            </TableCell>
                                                                                        }
                                                                                    </TableRow>
                                                                                )
                                                                            })
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            
                                                        </div>
                                                        
                                                    </TabPanel>
                                                    <TabPanel value={tabValue} index={1} dir={theme.direction}  className="tab-panel-dimensions-measures">
                                                        
                                                        <FormControl className={classes.formControl}>
                                                            <InputLabel htmlFor="select-cube">Selected Cube</InputLabel>
                                                            <Select
                                                                labelId="select-cube-label"
                                                                id="select-cube"
                                                                open={open}
                                                                onClose={handleCloseCube}
                                                                onOpen={handleOpenCube}
                                                                value={cube.CUBE_COD}
                                                                onChange={handleChangeCube}
                                                            >
                                                                {
                                                                    cubes.map((cube, index) => (
                                                                        <MenuItem value={cube.CUBE_COD}>{cube.CUBE_DESC}</MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>

                                                        <div className="outer-container">
                                                            
                                                            <div className="select-all">
                                                                <span onClick={ (event) => handleSetDimensions(event, '', 'Y') } >Select all</span>
                                                                <span onClick={ (event) => handleSetDimensions(event, '', 'N') } >Deselect all</span>
                                                            </div>
                                                            

                                                            <TableContainer component={Paper} style={{width: 220, marginTop: 10, marginBottom: 30, marginRight: 10}}>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell component="th" align="left" style={{width: 200}}>Dimension</TableCell>
                                                                            <TableCell component="th" align="center" style={{width: 20}}>&nbsp;</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            dimensions.map((item, index) => {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell align="left" style={{width: 100}}>{item.DIMENSION_DESC}</TableCell>
                                                                                        {
                                                                                            item.FLAG_SELECTED === 'Y'
                                                                                            ?
                                                                                            <TableCell align="left" 
                                                                                                       className="icon-link-cell check-selected" 
                                                                                                       style={{width: 20}}
                                                                                                       onClick={(event) => handleSetDimensions(event, item.KDIMENSION, 'N')} >
                                                                                                <CheckIcon />
                                                                                            </TableCell>
                                                                                            : 
                                                                                            <TableCell align="left" 
                                                                                                       className="icon-link-cell check-not-selected" 
                                                                                                       style={{width: 20}}
                                                                                                       onClick={(event) => handleSetDimensions(event, item.KDIMENSION, 'Y')} >
                                                                                                <CheckIcon />
                                                                                            </TableCell>
                                                                                        }
                                                                                    </TableRow>
                                                                                )
                                                                            })
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </div>
                                                        
                                                        <div className="outer-container">
                                                            
                                                            <div className="select-all">
                                                                <span onClick={ (event) => handleSetMeasures(event, '', 'Y') } >Select all</span>
                                                                <span onClick={ (event) => handleSetMeasures(event, '', 'N') } >Deselect all</span>
                                                            </div>

                                                            <TableContainer component={Paper} style={{width: 220, marginTop: 10, marginBottom: 30}}>
                                                                <Table >
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell component="th" align="left" style={{width: 200}}>Measures</TableCell>
                                                                            <TableCell component="th" align="center" style={{width: 20}}>&nbsp;</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            measures.map((item, index) => {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell align="left" style={{width: 100}}>{item.MEASURE_DESC}</TableCell>
                                                                                        {
                                                                                            item.FLAG_SELECTED === 'Y'
                                                                                            ?
                                                                                            <TableCell align="left" 
                                                                                                       className="icon-link-cell check-selected" 
                                                                                                       style={{width: 20}}
                                                                                                       onClick={(event) => handleSetMeasures(event, item.KMEASURE, 'N')} >
                                                                                                <CheckIcon />
                                                                                            </TableCell>
                                                                                            : 
                                                                                            <TableCell align="left" 
                                                                                                       className="icon-link-cell check-not-selected" 
                                                                                                       style={{width: 20}}
                                                                                                       onClick={(event) => handleSetMeasures(event, item.KMEASURE, 'Y')} >
                                                                                                <CheckIcon />
                                                                                            </TableCell>
                                                                                        }
                                                                                    </TableRow>
                                                                                )
                                                                            })
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            
                                                        </div>

                                                    </TabPanel>
                                                </SwipeableViews>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    </MuiThemeProvider>
                </div>
    );
}