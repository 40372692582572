import React from 'react';
import '../css/NoMatch.css';
import LockIcon from '@material-ui/icons/Lock';
import {useHistory} from "react-router-dom";

export default function NoMatch403() {
    /*
    const quotes = ["All those moments will be lost in time, like tears in rain.",
        "Well, nobody’s perfect"];
    const random = Math.floor(Math.random() * quotes.length);
    const quote = quotes[random];

     */

    const history      = useHistory();
    const goToHomePage =  () => {
        history.push('/dashboard');
    };

    return (
        <div className="no-match">
            <div className="logo">
                <a href="/">
                    <img src="/roobeek_marchio.svg" alt="roobeek" title="roobeek" style={{width: 130}}/>
                </a>
            </div>

            <div className="container">

                <div className="four-oh-four"><h1><LockIcon />&nbsp;Unauthorized</h1></div>

                <div className="warning">
                    <p>It seems you don't have permission to view this page.</p>

                    {
                        /*
                    <h2>"{quote}"</h2>
                         */
                    }

                </div>

                <div className="back">
                    <span onClick={goToHomePage}>Return to the home page</span>
                </div>

            </div>

        </div>
    );
}