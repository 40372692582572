import React, { useState, useEffect } from 'react';
import { ResponsiveLine }             from '@nivo/line';
import { ResponsiveBar  }             from '@nivo/bar';
import { ResponsivePie  }             from '@nivo/pie';
import utils                          from './CommonUtilities';
import                                './Graph.css';

const separator = '●';
const bDebug    = false;

export default function Graph({ oGraphContent = {} , className }) {
    
    const
         [ data        , set_data       ] = useState([])
        ,[ asX         , set_asX        ] = useState([])
        ,[ asY         , set_asY        ] = useState([])
        ,[ asZ         , set_asZ        ] = useState([])
        ,[ sChartType  , set_sChartType ] = useState('')
        ,[ oMinMax     , set_oMinMax    ] = useState({ nMin: 0 , nMax: 0 })
        
        ,formatNum0dec  = v => utils.formatNumberWithOptions(v, { nOuputDecimals: 0 })
        ,formatNum1dec  = v => utils.formatNumberWithOptions(v, { nOuputDecimals: 1 })
        ,formatDate     = v => `${utils.formatDateTime(v, {
             input  : 'YYYY-MM-DDThh:mm:ss.SSSZ'
            ,output : 'ddd D MMM YYYY'
        })}`
        ,isLine         = ( sChartType === 'line' )
        ,isBar          = ( sChartType === 'bar'  )
        ,isPie          = ( sChartType === 'pie'  )
        
        ,MAXLABELLENGTH = 18
        ,nPieceLength   = ~~( ( MAXLABELLENGTH - 3 ) / 2 )
        ,fsCompressText = (s) => {
            const s1 = ( ( s === 0 ? 0 : ( s || '' ) ) + '' );
            const s2 = s1.replaceAll(separator, separator.trim() ); 
            return ( s1.length < MAXLABELLENGTH ) ? s1 : ( s2.slice(0,nPieceLength) + '...' + s2.slice(-nPieceLength) );
        }
    ;
    
    const fTooltipFormat = v => {
        
        const
             xIndexValue    = ((isLine ? v.point.data.xFormatted :
                                isBar  ? v.indexValue            :
                                isPie  ? v.datum.label           : '' ) || '' ) + ''
            ,color          = ((isLine ? v.point.serieColor      :
                                isBar  ? v.color                 :
                                isPie  ? v.datum.color           : '' ) || '' ) + ''
            ,id             = ((isLine ? v.point.serieId         :
                                isBar  ? v.id                    :
                                isPie  ? asY[0]                  : '' ) || '' ) + ''
            ,value          = ( isLine ? v.point.data.yFormatted :
                                isBar  ? ( ( oMinMax.nMax - oMinMax.nMin ) < 10 ? formatNum1dec : formatNum0dec )(v.value) :
                                isPie  ? ( ( oMinMax.nMax - oMinMax.nMin ) < 10 ? formatNum1dec : formatNum0dec )(v.datum.value)  : 0 ) || 0
            ,as_xFormatted  = ( typeof xIndexValue === 'string' ? xIndexValue : '' ).split(separator)
        ;
    
        
        return <table className="graph-tooltip">
            <tbody>
                {
                    asX[0].split(separator).map(
                        ( sElementX, n ) => <tr key={sElementX+n} className="graph-tooltip-row">
                            <td></td><td>{ sElementX }:</td><td className="graph-tooltip-value">{ as_xFormatted[n] }</td>
                        </tr>
                    )
                }
                <tr className="graph-tooltip-row"><td><div style={{ backgroundColor: color ,width:"10px" ,height:"10px" }}></div></td><td>{ id }:</td><td className="graph-tooltip-value">{ value }</td></tr>
            </tbody>
        </table>
    }
    
    
    const legends = [
        {
             dataFrom           : 'keys'
            ,anchor             : 'top-right'
            ,direction          : 'column'
            ,justify            : false
            ,translateX         : -50
            ,translateY         : -50
            ,itemsSpacing       : 0
            ,itemDirection      : 'left-to-right'
            ,itemWidth          : 80
            ,itemHeight         : 20
            ,itemOpacity        : 1
            ,symbolSize         : 12
            ,symbolShape        : 'square'
            ,symbolBorderColor  : 'rgba(0, 0, 0, 0.5)'
            ,toggleSerie        : true
        }
    ];
    const margins = { top: 100, right: 100, bottom: 100, left: 100 };
    
    useEffect( () => {
        set_sChartType(oGraphContent.sChartType);
    }, [oGraphContent]);
    
    
    /*
    const oFieldsMap = oGraphContent.oFieldsMap;        // es. { 'DATA_RIF': v => moment(v,'YYYYMMDD').format('ddd D MMM YYYY') }
    if ( oFieldsMap ) {
        aoValues = aoValues.map( oRow => Object.keys(oRow).reduce( ( o, sKey ) => ({ ...o, [sKey]: oFieldsMap[sKey]( oRow[sKey] ) }) ,{}) )
    }
    */
    
    useEffect(() => {
        (async function () {
            
            let 
                 aoRows         = [ ...( oGraphContent.aoRows     || [] ) ]
                ,oDrillDown     = { ...( oGraphContent.oDrillDown || {} ) }
                ,asElementX     = [ ...( oGraphContent.asElementX || [] ) ]  // dimensioni es. [ 'DATA_RIF' ]
                ,asElementY     = [ ...( oGraphContent.asElementY || [] ) ]  // misure     es. [ 'BACINO' ,'BACINO_MM_FAST' ,'BACINO_MM_SLOW' ]
                ,asElementZ     = [ ...( oGraphContent.asElementZ || [] ) ]  // dimensione pivottata es. [ 'YEAR' ]
                ,oMappingField  = { ...( oGraphContent.oMappingField || {} ) }
                ,oGrandTotal    = {}
                ,aoValues
            ;
            bDebug && console.log('asElementX', asElementX);
            bDebug && console.log('asElementY', asElementY);
            bDebug && console.log('asElementZ', asElementZ);
            
            if ( oGraphContent.excludeTOT ) {
                oGrandTotal     = aoRows.shift(); // rimuovo il primo record (il GRAND TOTAL)
                // TODO visualizzare il GRAND TOTAL da qualche parte
            }
            
            const nFirstIndexPositive   = aoRows.findIndex( o => o.PROG_ID > 0 );
         // const aoSubtotals           = aoRows.slice( 0, nFirstIndexPositive );
            
            aoRows = aoRows.slice( nFirstIndexPositive );
            aoRows = aoRows.slice(0,100); // solo i primi 100 valori
            
            if ( oMappingField ) {
                
                aoRows     = aoRows.map( oRow => Object.keys(oRow).reduce(
                    ( oRowModified, sKey ) => 
                        ({
                            ...oRowModified
                            ,[ ( oMappingField[sKey] || {} ).description || sKey ]: // mappo il campo database con la relativa description
                                // converto il valore associato a quel campo in base al tipo indicato in filterDataType
                                utils.convertDataType( oRow[sKey], ( oMappingField[sKey] || {} ).filterDataType || sKey )
                        })
                        ,{}
                ) );
                
                const mappingElement = sElement => ( oMappingField[sElement] || {} ).description || sElement;
                asElementX = asElementX.map( mappingElement );
                asElementY = asElementY.map( mappingElement );
                asElementZ = asElementZ.map( mappingElement );
                oDrillDown = Object.keys(oDrillDown).reduce( ( o, k ) => ({ ...o, [mappingElement(k)]: oDrillDown[k] }), {});
                
            }
            
            bDebug && console.table(aoRows);
            
            if ( asElementX.length > 1 ) {
                const sNewKey   = asElementX.join(separator);
                aoRows          = aoRows.map( oRow => ({
                     ...oRow
                    ,[sNewKey]: asElementX.map( sElementX => ( oRow[sElementX] || oDrillDown[sElementX] || '' ) ).join(separator)
                }) );
                asElementX      = [ sNewKey ];
            }
            
            let nMin = 0, nMax = 0;
            aoRows.forEach( oRow => {
                asElementY.forEach( sY => {
                    nMin = Math.min( ( nMin || 0 ), oRow[sY] || 0 );
                    nMax = Math.max( ( nMax || 0 ), oRow[sY] || 0 );
                });
            });
            set_oMinMax({ nMin, nMax })
            
            if ( ( sChartType === 'line' ) ||  ( sChartType === 'bar' ) ) {
                bDebug && console.log('sChartType: ',sChartType);
                /* risultato finale:
                    [
                        {
                             "id"    : "japan"
                            ,"data"  : [
                                          { "x": "plane"         ,"y": 91 }
                                         ,{ "x": "helicopter"    ,"y": 85 }
                                       ]
                        }
                    ]
                */
                
                // [ 'SPOT_YEAR 2020' ]: { PROG_ID: 1 ,CHANNEL_DESC: 'CNN' ,SPOT_YEAR: '2020' ,SPOT_LENGTH: 213456 }
    
                const sElementX = asElementX[0];
                
                if ( asElementZ && asElementZ.length ) {
                    
                    let
                         oRecordPrec         = {}
                        ,aoRowsPivoted       = []
                        ,nRowsPivotedCounter = -1
                        ,oElementY           = {}
                    ;
                    
                    aoRows.forEach( oRecord => {
                        
                        if ( oRecord.PROG_ID !== oRecordPrec.PROG_ID ) {
                            
                            oRecordPrec = { ...oRecord };
                            nRowsPivotedCounter++;
                            
                        } 
                        
                        asElementZ.forEach( sElementZ => {
                            
                            asElementY.forEach( sElementY => {
                                
                                const sKey = ( oRecord[sElementZ] ? ( oRecord[sElementZ] + ' ' ) : '' ) + sElementY;
                                
                                aoRowsPivoted[nRowsPivotedCounter] = {
                                     ...( aoRowsPivoted[nRowsPivotedCounter] || oRecord || {} )      // se stesso se esiste
                                    ,[ sKey ] : oRecord[sElementY]  // [ 'SPOT_YEAR 2020' ]: 213456 }
                                };
                                
                                oElementY[ sKey ] = true;
                                delete aoRowsPivoted[nRowsPivotedCounter][sElementY];
                                
                            });
                            
                            delete aoRowsPivoted[nRowsPivotedCounter][sElementZ];
                            
                        });
                        
                    });
                    
                    asElementY      = Object.keys(oElementY);
    
    
                    if ( sChartType === 'bar' ) {
                        bDebug && console.log(oGraphContent.sChartType);
        
                        /* esempio bar
                            [
                                {
                                     "country"  : "AD"
                                     
                                    ,"burger"   : 132
                                    ,"sandwich" : 125
                                    ,"kebab"    : 256
                                }
                            ]
                        */
        
                        aoValues        = aoRowsPivoted;
                        asElementZ      = [];
        
                    } else {
    
                        aoValues        = asElementY.map(
                            sElementY => ({
                                 id: sElementY
                                 // ,color: 'red'
                                ,data: aoRowsPivoted.map( oRow => ({ x: oRow[sElementX] || '-' ,y: oRow[sElementY] || 0 }) )
                            })
                        );
        
                    }
                    
                } else {
                    
                    if ( sChartType === 'bar' ) {
                        bDebug && console.log(oGraphContent.sChartType);
        
                        /* esempio bar
                            [
                                {
                                     "country"  : "AD"
                                     
                                    ,"burger"   : 132
                                    ,"sandwich" : 125
                                    ,"kebab"    : 256
                                }
                            ]
                        */
        
                        aoValues        = aoRows;
        
                    } else {
                        
                        aoValues        = asElementY.map(
                            sElementY => ({
                                 id: sElementY
                                 // ,color: 'red'
                                ,data: aoRows.map( oRow => ({ x: oRow[sElementX] || '-' ,y: oRow[sElementY] || 0 }) )
                            })
                        );
                        
                    }
    
                }
                
            } else if ( sChartType === 'pie' ) {
                bDebug && console.log(oGraphContent.sChartType);
                
                /* esempio pie
                    [
                        {
                             "id"   : "python"
                            ,"label": "python"
                            ,"value": 90
                        }
                    ]
                */
                
                // const oFieldsMap = oGraphContent.oFieldsMap;        // es. { 'DATA_RIF': v => moment(v,'YYYYMMDD').format('ddd D MMM YYYY') }
                aoValues = aoRows.map( oRow => ({ id: oRow[asElementX[0]] ,label: oRow[asElementX[0]] ,value: oRow[asElementY[0]] }) );
    
            }
    
            bDebug && console.table(aoValues);
            bDebug && console.log('asElementX', asElementX);
            bDebug && console.log('asElementY', asElementY);
            bDebug && console.log('asElementZ', asElementZ);
            
            set_asX(    asElementX  || []);
            set_asY(    asElementY  || []);
            set_asZ(    asElementZ  || []);
            set_data(   aoValues    || []);
            
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sChartType]);
    
    return <div style={{ height: 'calc( 100vh - 168px )' }} className={ 'graph ' + className }>{
    
        ( data != null ) && ( data.length > 0 ) && (
            
            ( sChartType === 'bar' ) ? (
            
                <ResponsiveBar
                    data                    ={data}
                    keys                    ={asY}
                    indexBy                 ={ asZ[0] || asX[0] || null }
                    margin                  ={margins}
                    padding                 ={0.35}
                    innerPadding            ={2}
                    groupMode               ="grouped"
                    valueScale              ={{ type:   'linear' }}
                    indexScale              ={{ type:   'band'   , round: true }}
                    colors                  ={{ scheme: 'category10'   }}
                    borderWidth             ={0.5}
                    borderColor             ={{ from:   'color'  , modifiers: [ [ 'darker', 0.5 ] ] }}
                    axisTop                 ={null}
                    axisRight               ={null}
                    axisBottom              ={{
                         tickSize        : 5
                        ,tickPadding     : 5
                        ,tickRotation    : 45
                        ,format          : fsCompressText
                    }}
                    axisLeft                ={{
                         tickSize        : 5
                        ,tickPadding     : 5
                        ,tickRotation    : 0
                        ,format          : ( oMinMax.nMax - oMinMax.nMin ) < 10 ? formatNum1dec : formatNum0dec
                    }}
                    labelSkipWidth          ={12}
                    labelSkipHeight         ={12}
                    labelTextColor          ={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    legends                 ={legends}
                    animate                 ={false}
                    motionStiffness         ={90}
                    motionDamping           ={15}
                    enableLabel             ={false}
                    tooltip                 ={fTooltipFormat}
                    minValue                ={oMinMax.nMin}
                    maxValue                ={oMinMax.nMax}
                />
                
            ) : ( sChartType === 'line' ) ? (
                // sliceTooltip    ={ v => `${ v.slice.points[0].data.xFormatted } : ${ v.slice.points[0].serieId } ${ v.slice.points[0].data.yFormatted }` }
                // enableSlices    ={'x'}
                <ResponsiveLine
                    data                    ={data}
                    margin                  ={margins}
                    enableGridX             ={false}
                    animate                 ={false}
                    xScale                  ={{ type: 'point' }}
                    xFormat                 ={ v => v }
                    tooltip                 ={fTooltipFormat}
                    yScale                  ={{
                         type           : 'linear'
                        ,min            : oMinMax.nMin
                        ,max            : oMinMax.nMax
                        ,stacked        : false
                        ,reverse        : false
                    }}
                    yFormat                 ={ ( oMinMax.nMax - oMinMax.nMin ) < 10 ? formatNum1dec : formatNum0dec }
                    axisTop                 ={null}
                    axisRight               ={null}
                    axisBottom              ={{
                         orient         : 'bottom'
                        ,tickSize       : 5
                        ,tickPadding    : 5
                        ,tickRotation   : 45
                        ,format         : fsCompressText
                    }}
                    axisLeft                ={{
                         orient         : 'left'
                        ,tickSize       : 5
                        ,tickPadding    : 5
                        ,tickRotation   : 0
                        ,format         : ( oMinMax.nMax - oMinMax.nMin ) < 10 ? formatNum1dec : formatNum0dec
                    }}
                    pointSize               ={3}
                    pointColor              ={ 'white' }
                    pointBorderWidth        ={1}
                    pointBorderColor        ={'black'}
                    pointLabel              ={ d => `${d.x} : ${d.y}`}
                    useMesh                 ={true}
                    legends                 ={legends}
                    enablePointLabel        ={false}
                    pointLabelYOffset       ={0}
                    enableArea              ={false}
                    areaOpacity             ={0.8}
                    colors                  ={{ scheme: 'category10'   }}

                />
                
            ) : ( sChartType === 'pie' ) ? (
                
                <ResponsivePie 
                    data                    ={data}
                    margin                  ={margins}
                    padAngle                ={0}
                    cornerRadius            ={5}
                    colors                  ={{ scheme: 'set2' }}
                    borderWidth             ={0}
                    borderColor             ={{from: 'color', modifiers: [['darker', 0.2]]}}
                    valueFormat             ={formatNum0dec}
                    enableSliceLabels       ={false}
                    sliceLabelsSkipAngle    ={10}
                    sliceLabelsTextColor    ="#333333"
                    innerRadius             ={0.2}
                    animate                 ={false}
                    enableArcLabels         ={true}
                    arcLabelsSkipAngle      ={18.1}
                    arcLabelsRadiusOffset   ={0.65}
                    arcLinkLabelsSkipAngle  ={6}
                    arcLinkLabel            ={ v => v.id }
                    tooltip                 ={fTooltipFormat}
                />
                
        ) : <></>
        
        )
        
    }</div>
    
}

//                     arcLabel                ={ v => v.data.perc }
//                     tooltip                 ={ v => v.datum }

