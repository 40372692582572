import React, { useEffect, useState } from 'react';
import                      './CubesSettings.css';
import FetchService         from '../../common/FetchService';
import Utils                from '../../common/CommonUtilities';
import { SnackAlert }       from '../../common/SnackAlert';
import { PopupConfirm }     from '../../common/PopupConfirm';
import { SimpleTable }      from '../cubes/components/SimpleTable';
import {
       createTheme,
       MuiThemeProvider
}                           from '@material-ui/core/styles';
import Paper                from '@material-ui/core/Paper';
import Table                from '@material-ui/core/Table';
import TableBody            from '@material-ui/core/TableBody';
import TableCell            from '@material-ui/core/TableCell';
import TableContainer       from '@material-ui/core/TableContainer';
import TableHead            from '@material-ui/core/TableHead';
import TableRow             from '@material-ui/core/TableRow';
import Tooltip              from '@material-ui/core/Tooltip';
import TextField            from '@material-ui/core/TextField';
import InputLabel           from '@material-ui/core/InputLabel';
import Dialog               from '@material-ui/core/Dialog';
import DialogTitle          from '@material-ui/core/DialogTitle';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogContentText    from '@material-ui/core/DialogContentText';
import DialogActions        from '@material-ui/core/DialogActions';
import Button               from '@material-ui/core/Button';
import Select               from '@material-ui/core/Select';
import MenuItem             from '@material-ui/core/MenuItem';
import Cached               from '@material-ui/icons/Cached';

const theme = createTheme({
    overrides: { MuiTooltip: { tooltip: { fontSize: '0.9em' } } },
    palette: {
      primary: {
        main: '#519657'
      },
      secondary: {
        main: 'rgb(33, 33, 33)'
      },
    },
    typography: {
        useNextVariants: true,
        fontSize: 13,
    }
  });

function PopupEdit( { options } ) {

    const
         { bShowDialog, oDialogOptions = {}, isLoading, set_bShowDialog, sTitle, sConfirmButton, oMapLabelAndValues, reload, toggleNotifica } = options
        ,[ oItem        ,set_oItem      ] = useState(oDialogOptions.oItem || {})
        ,[ oParamsMod   ,set_oParamsMod ] = useState( null )
        ,isDimension = oItem.TYPE === 'DIMENSION'
        ,isMeasure   = oItem.TYPE === 'MEASURE'
    ;

    const actions = ({ url, params }) => {
        if ( Object.keys( params || {} ).length ) {
            ( async () => {
                const { nRetVal, vErrorMessageDesc } = await FetchService.asyncPost({
                     url
                    ,params
                    ,displayErrorHandler: toggleNotifica
                });

                if ( +nRetVal > 0 ) {
                    set_bShowDialog( false );
                    reload();
                } else {
                    toggleNotifica( vErrorMessageDesc || 'Error', 'error' );
                }
            })();
        }
    };

    useEffect(() => {
        actions({
             url:    isDimension ? '/settings/mod-cube-dimension' : '/settings/mod-cube-measure'
            ,params: oParamsMod
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oParamsMod ]);

    const makeTextField = ( sFieldName ) => {
        const oMap = oMapLabelAndValues[sFieldName];
        if ( oMap ) {
            const format = oMap.format || (val => val);
            return <TextField
                key      ={sFieldName}
                id       ={sFieldName}
                className={sFieldName + ' textfield' }
                label    ={ oMap.label }
                value    ={ format( oItem[sFieldName] ) || '' }
                onChange ={ (event) => {
                    set_oItem( { ...oItem ,[sFieldName]: format( event.target.value ) });
                } }
                onClick  ={((event)=>{event.stopPropagation()})}
                disabled ={ !oMap.enabled }
                inputProps={{maxLength: 100}}
            />
        }
    };

    const makeSelectField = ( sFieldName ) => {
        const oMap = oMapLabelAndValues[sFieldName];
        if ( oMap ) {
            const format = oMap.format || (val => val);
            return <div className={ sFieldName + ' container' }>
                <InputLabel id={ sFieldName + ' label' }>{ oMap.label }</InputLabel>
                <Select
                    className={ sFieldName + ' select' }
                    labelId  ={ sFieldName + ' label' }
                    id       ={ sFieldName }
                    value    ={ format( oItem[sFieldName] ) || '' }
                    onChange ={ (event)=>{
                        set_oItem( { ...oItem ,[sFieldName]: format( event.target.value ) });
                    } }
                >
                    {
                        oMap.selectValues.map( val =>
                            <MenuItem key={val} value={val} >{val}</MenuItem>
                        )
                    }
                </Select>
            </div>
        }
    };

    const mapping = {
         ...( isMeasure   && { 'COD'     : 'pMeasureCod'    } )
        ,...( isMeasure   && { 'DESCR'   : 'pMeasureDesc'   } )
        ,...( isDimension && { 'COD'     : 'pDimensionCod'  } )
        ,...( isDimension && { 'DESCR'   : 'pDimensionDesc' } )
        ,'DESCRIPTION'    : 'pDescription'
        ,'FLAG_STARTUP'   : 'pFlagStartup'
    };

    const mapItem = ( o ) => {
        const newO = {};
        Object.keys(o).forEach( k => {
            newO[ mapping[k] ]  = o[k];
        });
        return newO;
    }

    return <Dialog open={ bShowDialog } className="editing-dialog" maxWidth="xs" fullWidth={true}>

        <DialogTitle>{ sTitle }</DialogTitle>

        <DialogContent>
            { isLoading() ? <div>&nbsp;<br/>&nbsp;</div> : <DialogContentText>{

                <form className="editFields" autoComplete="off">
                    { makeTextField('DESCR') }
                    { makeTextField('DESCRIPTION') }
                    { makeSelectField('FLAG_STARTUP') }
                </form>

            }</DialogContentText> }
        </DialogContent>

        <DialogActions>
            { isLoading() ? null :
                <>
                    <Button onClick={ ()=>{
                        set_oParamsMod( mapItem( oItem ) );
                    } } color="primary">{ sConfirmButton }</Button>
                    <Button onClick={ ()=> {
                        set_bShowDialog(false);
                        reload();
                    } } color="secondary">cancel</Button>
                </>
            }
        </DialogActions>

    </Dialog>
}

export default function CubesSettings(props) {

    const
         [ bReload         ,set_bReload         ] = useState(props.reload)
        ,[ aoCubes         ,set_aoCubes         ] = useState([])
        ,[ oCubeSelected   ,set_oCubeSelected   ] = useState({})
        ,[ aoDimensions    ,set_aoDimensions    ] = useState([])
        ,[ aoMeasures      ,set_aoMeasures      ] = useState([])
        ,[ oItemToModify   ,set_oItemToModify   ] = useState(null)
        ,[ sPopupType      ,set_sPopupType      ] = useState('')
        ,[ isRebuilding    ,set_isRebuilding    ] = useState(false)
        ,[ oNotifyOptions  ,set_oNotifyOptions  ] = useState({ message: '', severity: '' }) // severity: 'error' | 'warning' | 'info' | 'success'
        ,[ bShowEditDialog ,set_bShowEditDialog ] = useState(false)
        ,{ get: isLoading  ,set: set_isLoading  } = props.loading // get: isLoading
        ,reload                                   = () => { set_bReload( !bReload ); }
    ;

    useEffect(() => {
        (async function() {
            set_isLoading(true);
            const aoCubes = await FetchService.asyncGet({ url: '/settings/get-cubes' }) || [];
            set_aoCubes([...aoCubes]);
            const oFirstCube = ( aoCubes || [] )[0] || {};
            const oNewCubeSelected =
                oCubeSelected.CUBE_COD ? oCubeSelected :
                oFirstCube.CUBE_COD    ? oFirstCube    :
                {}
            ;
            set_oCubeSelected({...oNewCubeSelected}); // riforzo in ogni caso il set del cubo selected per triggerare la useEffect[oCubeSelected]
            set_isLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ bReload ]);

    useEffect(() => {
        if ( oCubeSelected.CUBE_COD ) {
            (async function() {
                set_isLoading(true);
                const { aoDimesions, aoMeasures } = await FetchService.asyncGet({
                     url:     '/cubes/get-all-dimensions-measures'
                    ,params:  { pCubeCod: oCubeSelected.CUBE_COD }
                }) || {};
                set_aoDimensions(  aoDimesions || [] );
                set_aoMeasures(    aoMeasures  || [] );
                set_isLoading(false);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oCubeSelected ]);

    useEffect(() => {
        if ( !sPopupType ) {
            set_isRebuilding(false);
        }
    }, [ sPopupType ]);

    const

         handleCloseAlert = (event, reason) => {
            // if (reason === 'clickaway') { return; }
            set_oNotifyOptions({ message: '' });
        }

        ,toggleNotifica   = ( message, severity ) => {
            set_isLoading(false);
            set_sPopupType('');
            set_oNotifyOptions({ message, severity  });
        }

        ,rebuildCube      = async () => {

            set_isLoading(true);
            set_isRebuilding(true);
            const response = await FetchService.asyncPost({ url: '/settings/rebuild-cube', params: { pCubeCod: oCubeSelected.CUBE_COD } });
            const sDefaultErrorMessage = 'Operation failed. Please retry or contact application support';
            if ( !response || ( response instanceof Error ) ) {
                toggleNotifica( sDefaultErrorMessage, 'error' );
            } else {
                const { nRetVal, vOutputRowcounts, vErrorMessageDesc } = response;
                if ( nRetVal > 0 ) {
                    if ( ( typeof vOutputRowcounts === 'string' ) && ( +vOutputRowcounts.split('-').join('') ) === 0 ) {
                        // se il nRetVal è 1 e vOutputRowcounts è "0-0-0-0-0-0"
                        toggleNotifica( 'Rebuild executed. No changes.', 'warning' );
                    } else {
                        toggleNotifica( 'Rebuild completed', 'success' );
                    }
                } else {
                    toggleNotifica( vErrorMessageDesc || sDefaultErrorMessage, 'error' );
                }
                set_bReload(!bReload);
            }

        }

        ,selectCubeShowConfirm = ( oCube ) => {
            set_oCubeSelected(oCube);
            set_sPopupType('confirm');
        }

        ,listCubes        = ( oCube, index) =>
            <TableRow
                key       ={ index + oCube.CUBE_DESC }
                className ={ 'row ' + ( oCube.CUBE_COD === oCubeSelected.CUBE_COD ? 'selected' : '' ) }
                onClick   ={ () => set_oCubeSelected( oCube ) }
            >
                <TableCell align="left"><strong>{oCube.CUBE_DESC}</strong>
                <div align="right">
                    <Tooltip title={ !oCube.CUBE_COD ? '' : ( Utils.formatDateTime(oCube.LAST_REBUILD_DATE, { output: '', fromNow: true } ) ) }>
                        <span>
                            { !oCube.CUBE_COD ? '' : (
                                Utils.formatDateTime(oCube.LAST_REBUILD_DATE, { output: 'D MMM YYYY HH:mm', useTimezone: true } )
                            )}
                        </span>
                    </Tooltip>
                </div>
                </TableCell>
                {
                    ( oCube.FLAG_REBUILD === 'Y' )
                        ? <TableCell align="center" className="icon-link-cell" style={{width: 20}}
                            onClick={() => selectCubeShowConfirm(oCube)}
                          >
                            <Tooltip title={oCube.CUBE_COD ? ('Rebuild Cube ' + oCube.CUBE_DESC) : ''}><Cached/></Tooltip>
                          </TableCell>
                        : <TableCell/>
                }
            </TableRow>

        ,editFunction     = ( oItem ) => {
            set_oItemToModify(oItem);
            set_bShowEditDialog(true);
        }

        ,aoDimensionsCols = [
             { name: 'DESCR'        ,width: 170 ,title: 'Dimension (' + aoDimensions.length + ')'  ,isUniqueKeyForRow: true }
            ,{ name: 'DESCRIPTION'              ,title: 'Description' }
            ,{ name: 'FLAG_STARTUP' ,width: 70  ,title: 'Sort'       }
        ]

        ,aoMeasuresCols = [
             { name: 'DESCR'        ,width: 170 ,title: 'Measure (' + aoMeasures.length + ')'      ,isUniqueKeyForRow: true }
            ,{ name: 'DESCRIPTION'              ,title: 'Description' }
            ,{ name: 'FLAG_STARTUP' ,width: 70  ,title: 'Sort'       }
        ]
        ,oMapLabelAndValues = {
             'DESCR'        : { label: 'Name'               ,enabled: true  }
            ,'DESCRIPTION'  : { label: 'Full description'   ,enabled: true  }
            ,'FLAG_STARTUP' : { label: 'Sort group'         ,enabled: true  ,selectValues: [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'] }
        }

    ;

    return (
        <div className="cubes-settings">

            { !aoCubes[0] ? <div>&nbsp;</div> :
                <MuiThemeProvider theme={theme}>

                    { /* ----- notifiche ----- */ }
                    <SnackAlert oNotifyOptions={ { ...oNotifyOptions, handleCloseAlert } } />

                    { /* ----- popup di conferma operazione ----- */ }
                    <PopupConfirm
                        { ...{
                            oPopupOptions: {
                                 DialogTitle:       ( !isRebuilding ? 'Rebuild ' : 'Rebuilding ' ) +
                                                    ( oCubeSelected.CUBE_COD ? ( 'cube ' + oCubeSelected.CUBE_DESC ) : 'ALL cubes' )
                                ,DialogContentText: (
                                    isRebuilding
                                        ? <div>&nbsp;<br/>&nbsp;</div>
                                        : <span>Are you sure you want to rebuild {
                                            oCubeSelected.CUBE_COD
                                                ? <span>the cube <span className="bold">{ oCubeSelected.CUBE_DESC }</span></span>
                                                : <span className="bold">ALL cubes</span>
                                        } ?</span>
                                )
                                ,onClick:           rebuildCube
                                ,firstButtonLabel:  'rebuild'
                            }
                            ,sPopupType
                            ,set_sPopupType
                        } }
                    />

                    { /* ----- popup di modifica dati ----- */ }
                    { bShowEditDialog && oItemToModify ? <PopupEdit
                        options ={{ bShowDialog: bShowEditDialog, oDialogOptions: { oItem: oItemToModify }, isLoading, set_bShowDialog: set_bShowEditDialog,
                            sTitle: 'Edit', sConfirmButton: 'Save', oMapLabelAndValues, reload, toggleNotifica }}
                    /> : null }

                    { /* ----- contenuto della pagina ----- */ }

                    <Paper className="body-structure">
                        <div>
                            <TableContainer component={Paper} className="cubes-list">
                                <Table>
                                    <TableHead>{ listCubes( { CUBE_DESC: 'Cubes' }, -1 ) }</TableHead>
                                    <TableBody>{ aoCubes.map( listCubes ) }</TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div>
                            <SimpleTable
                                chiave                ={ 'dimensions' }
                                sTableDataType        ={ 'dimensions' }
                                aoRows                ={ aoDimensions }
                                aoCols                ={ aoDimensionsCols }
                                noHeight              ={ true }
                                isEditable            ={ true }
                                editFunction          ={ editFunction }
                            />
                        </div>
                        <div>
                            <SimpleTable
                                chiave                ={ 'measures' }
                                sTableDataType        ={ 'measures' }
                                aoRows                ={ aoMeasures }
                                aoCols                ={ aoMeasuresCols }
                                noHeight              ={ true }
                                isEditable            ={ true }
                                editFunction          ={ editFunction }
                            />
                        </div>
                    </Paper>

                </MuiThemeProvider>
            }
        </div>
    );
}
