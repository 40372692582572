import React from "react";
import Snackbar             from '@material-ui/core/Snackbar';
import MuiAlert             from '@material-ui/lab/Alert';

// ----- popup per le notifiche (positive e negative) ----- 
// severity: 'error' | 'warning' | 'info' | 'success'
export function SnackAlert( { oNotifyOptions } ){
    const { severity = 'error', message, timeout, handleCloseAlert } = oNotifyOptions;
    return (
        !message
            ? null
            : <Snackbar
                open             = { true }
                className        = "snackAlert"
                anchorOrigin     = { { vertical: 'top', horizontal: 'center' } }
                autoHideDuration = { ( +timeout > 0 ) ? ( +timeout * 1000 ) : null }
                onClose          = { handleCloseAlert }
            >
                <MuiAlert elevation={6} variant="filled" onClose={ handleCloseAlert } severity={ severity }>{ message }</MuiAlert>
            </Snackbar>
    )
}

/* ----- esempio di utilizzo -----

    const handleCloseAlert    = (event, reason) => {
        // if (reason === 'clickaway') { return; }
        set_oNotifyOptions({ message: '' });
    };
    
    { ----- notifiche ----- }
    <SnackAlert oNotifyOptions={ { ...oNotifyOptions, handleCloseAlert } } />

    set_oNotifyOptions({ message, severity  });

*/