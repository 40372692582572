import React, { useState, useEffect }   from 'react';
import { ResponsiveBar }                from '@nivo/bar';
import FetchService                     from '../../../common/FetchService';
import CommonUtilities                  from '../../../common/CommonUtilities';
import CircularProgress                 from '@material-ui/core/CircularProgress';
        

export default function GrossPerChannelYearChart(props) {
    
    const { isSole } = props.oCustomerChecks;
    
    const
         url        = isSole  ? '/cubes/charts/bacino-per-data' : ''
        ,formatNum  = v => `${CommonUtilities.formatPositiveIntNum(v)}`
        ,formatDate = v => `${CommonUtilities.formatDateTime(v, {
             input       : 'YYYYMMDD'
            ,output      : 'ddd D MMM YYYY'
        })}`
    ;
    
    const [data, setData] = useState([]);
    const [keys, setKeys] = useState([]);
    const [sFieldX, set_sFieldX] = useState('');
    const [chartFormat, /* setChartFormat */ ] = useState(1);

    useEffect(() => {
        async function onLoad() {
            const params = {
                chartFormat: props.chartFormat
            }
            let responseFromBE = ( url && await FetchService.asyncGet({ url, params }) ) || { keys: [], rows: [] };
                /*  dati fittizi
                    data.rows = [
                        {"2018":894,"2019":57,"channelDesc":"ADVERTISING"},
                        {"2018":20165,"2019":16248,"2020":5460,"2021":2640,"channelDesc":"CHANNEL"},
                        {"2018":8214,"2019":3144,"2020":3432,"2021":996,"channelDesc":"STRATEGIC"},
                        {"2018":170645,"2019":139495,"2020":94644,"2021":54024,"channelDesc":"BROADCAST"},
                        {"2018":27120,"2019":28035,"2020":15093,"2021":7356,"channelDesc":"AGENT"},
                        {"2018":22890,"2019":6327,"2020":480,"channelDesc":"KRESS"},
                        {"2018":4188,"2019":1464,"channelDesc":"RELAYZ"},
                        {"2018":3852,"2019":5718,"2020":2637,"2021":820,"channelDesc":"AMBLAR"},
                        {"2018":15456,"2019":0,"channelDesc":"BLOK"},
                        {"2018":28960,"2019":52036,"2020":60600,"2021":30852,"channelDesc":"RUNNAL"},
                        {"2018":41358,"2019":60308,"2020":81342,"2021":7698,"channelDesc":"MYNTER"},
                        {"2018":104145,"2019":122016,"2020":89148,"2021":16056,"channelDesc":"DEMURZ"},
                        {"2020":46020,"2021":18495,"channelDesc":"UNWIR"},
                        {"2018":1350,"2019":320,"channelDesc":"BUREK"},
                        {"2018":57856,"2019":50019,"2020":67375,"2021":5428,"channelDesc":"TATTAR"},
                        {"2018":52230,"2019":99156,"2020":59736,"2021":14224,"channelDesc":"AMERC"},
                        {"2018":422958,"2019":395700,"2020":177450,"2021":70287,"channelDesc":"MARKETING"},
                        {"2018":274280,"2019":332645,"2020":174237,"2021":92410,"channelDesc":"AKTION"},
                        {"2018":4888,"2019":228,"channelDesc":"ALLSQY"},
                        {"2019":11255,"2020":10535,"2021":3846,"channelDesc":"KOOMB"},
                        {"2020":310,"2021":790,"channelDesc":"RECQ"}
                    ];
                */
    
            setKeys(    responseFromBE.keys);
            setData(    responseFromBE.rows);
            set_sFieldX(responseFromBE.sFieldX);
    
        }
        onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        ( data != null && data.length > 0 ) ? (
            <ResponsiveBar
                data            ={data}
                keys            ={keys}
                indexBy         ={sFieldX}
                margin          ={{ top: 20, right: 0, bottom: 130, left: 80 }}
                padding         ={0.2}
                groupMode       ="grouped"
                valueScale      ={{ type:   'linear' }}
                indexScale      ={{ type:   'band'   , round: true }}
                colors          ={{ scheme: 'nivo'   }}
                borderColor     ={{ from:   'color'  , modifiers: [ [ 'darker', 1.6 ] ] }}
                axisTop         ={null}
                axisRight       ={null}
                axisBottom      ={{
                     tickSize        : 5
                    ,tickPadding     : 5
                    ,tickRotation    : -45
                    ,legend          : ''
                    ,legendPosition  : 'middle'
                    ,legendOffset    : 32
                    ,format          : formatDate
                }}
                axisLeft    ={{
                     tickSize        : 5
                    ,tickPadding     : 5
                    ,tickRotation    : 0
                    ,legend          : ''
                    ,legendPosition  : 'middle'
                    ,legendOffset    : -40
                    ,format          : formatNum
                }}
                labelSkipWidth  ={12}
                labelSkipHeight ={12}
                labelTextColor  ={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                legends={[
                    {
                         dataFrom        : 'keys'
                        ,anchor          : 'bottom'
                        ,direction       : 'column'
                        ,justify         : false
                        ,translateX      : 0
                        ,translateY      : 100
                        ,itemsSpacing    : 2
                        ,itemWidth       : 100
                        ,itemHeight      : 15
                        ,itemDirection   : 'left-to-right'
                        ,itemOpacity     : 0.85
                        ,symbolSize      : 10
                        ,effects         : [
                            {
                                on      : 'hover',
                                style   : { itemOpacity: 1 }
                            }
                        ]
                    }
                ]}
                animate         ={false}
                motionStiffness ={90}
                motionDamping   ={15}
                enableLabel     ={false}
                tooltipFormat   ={null}
                innerPadding    ={5}
            />
        )
        : <CircularProgress className="loading-icon-mui" />
    );
}
