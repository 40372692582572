import { useState }     from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute     from './PrivateRoute'
import { AuthContext }  from "./context/auth";
import Main             from './components/Main';
import Login            from './components/profile/Login';
import NoMatch403       from './components/NoMatch403';
import NoMatch404       from './components/NoMatch404';

import './css/App.css';

function App(props) {
    
    const
         existingTokens     = ( localStorage.getItem("token")      !== 'undefined' ) ? localStorage.getItem("token")      : ''
        ,existingPrivileges = ( localStorage.getItem("privileges") !== 'undefined' ) ? localStorage.getItem("privileges") : ''
        ,existingKUser      = ( localStorage.getItem("kUser")      !== 'undefined' ) ? localStorage.getItem("kUser")      : ''
        ,existingName       = ( localStorage.getItem("fullname")   !== 'undefined' ) ? localStorage.getItem("fullname")   : ''

        ,[ authTokens,     setAuthTokens     ] = useState(existingTokens)
        ,[ authPrivileges, setAuthPrivileges ] = useState(existingPrivileges)
        ,[ authKUser,      setAuthKUser      ] = useState(existingKUser)
        ,[ authFullName,   setAuthFullName   ] = useState(existingName)
    ;
    
    const setTokens = (data) => {
        
        const { token, privileges, kUser, fullname } = ( data || {} );
        
        localStorage.setItem( 'token', token );
        setAuthTokens( token );

        localStorage.setItem( 'privileges', privileges );
        setAuthPrivileges( privileges );

        localStorage.setItem( 'kUser', kUser );
        setAuthKUser( kUser );

        localStorage.setItem( 'fullname', fullname );
        setAuthFullName( fullname );

    }

    return (
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens, authPrivileges, authKUser, authFullName }}>
            <Router>
                <Switch>
                    
                    <PrivateRoute exact path="/"                        component={Main} />
                    <PrivateRoute exact path="/dashboard"               component={Main} />
                    <PrivateRoute exact path="/settings/user-groups"    component={Main} />
                    <PrivateRoute exact path="/settings/cubes-settings" component={Main} />
                    <PrivateRoute exact path="/settings/users-settings" component={Main} />
                    <PrivateRoute exact path="/settings/users-editing"  component={Main} />
                    <PrivateRoute       path="/cubes/"                  component={Main} />
                    <PrivateRoute exact path="/change-password"         component={Main} />
                    <PrivateRoute exact path="/impersonate-user"        component={Main} />
                    
                    <Route        exact path="/login"                   component={Login}/>
                    <Route        exact path="/403"                     component={NoMatch403} />
                    <Route                                              component={NoMatch404} />
                    
                </Switch>
            </Router>
        </AuthContext.Provider>
    );
    
}

export default App;