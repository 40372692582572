import React from "react";
import "./ImpersonateUser.css";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import FetchService from "../../common/FetchService";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

const theme = createTheme({
    overrides: { MuiTooltip: { tooltip: { fontSize: '0.9em' } } },
    palette: {
        primary: {
            main: '#509556',
        },
    },
    typography: {
        fontSize: 13,
    }
});

class ImpersonateUser extends React.Component {

    constructor(props) {
        super(props);

        const kUser = localStorage.getItem("kUser");

        this.state = {
            kUser: kUser,
            users: [],
            utenteCod: "",
        };
    }

    async componentDidMount() {
        this.loadUsers();
    }

    async loadUsers(){
        let users = [];
        const usersFromDb = await FetchService.asyncGet({ url: "/lookup/user-list" });
        if ( !usersFromDb || !usersFromDb.length ) {
            return;
        }

        for(let i=0; i< usersFromDb.length; i++){
            if( usersFromDb[i] && usersFromDb[i].KUSER && ( +usersFromDb[i].KUSER !== +this.state.kUser ) && ( usersFromDb[i].FLAG_ATTIVO === 'Y' ) ) {
                users.push(usersFromDb[i]);
            }
        }
        this.setState({users: users});
    }

    handleChange(event){
        this.setState({ [event.target.name] : event.target.value});
    }

    async handleSubmitImpersonate(event){
        //Come invoker user id si utilizza sempre il kUser originale: per evitare impersonate a catena
        const invokerUserId = localStorage.getItem('originalKUser');

        const params = {
            invokerUserId: invokerUserId,
            kUserToImpersonate: this.state.utenteCod,
        };

        const responseRetVal = await FetchService.asyncPost({ url: "/impersonate", params });

        this.props.userImpersonate(responseRetVal);
    }

    render() {
        const {
            users,
            utenteCod,
        } = this.state;

        let optionUsers = users.map((user) =>
            <MenuItem key={user.KUSER} value={user.KUSER}>{user.FULL_NAME}</MenuItem>
        );

        return (
            <div className="impersonate-user">
                <MuiThemeProvider theme={theme}>
                    <div className="row">
                        <FormControl>
                            <InputLabel htmlFor="utenteCod">User to impersonate</InputLabel>
                            <Select
                                value={utenteCod}
                                style={{minWidth:250}}
                                onChange={this.handleChange.bind(this)}
                                inputProps={{
                                    name: 'utenteCod',
                                    id: 'utenteCod',
                                }}
                            >
                                {optionUsers}
                            </Select>
                        </FormControl>
                    </div>

                    <div className="submit-buttons">
                        <span className="button">
                            <Button variant="contained"
                                    color="primary"
                                    onClick={(event) => this.handleSubmitImpersonate(event)}
                                    disabled={utenteCod===''}
                            >Impersonate</Button>
                        </span>
                    </div>

                </MuiThemeProvider>
            </div>
        );
    }
}

export default ImpersonateUser;
