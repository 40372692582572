import React, { useState, useEffect }   from 'react';
import { ResponsivePieCanvas }          from '@nivo/pie';
import FetchService                     from '../../../common/FetchService';
import CommonUtilities                  from '../../../common/CommonUtilities';

function createTooltip(v){
    return <div className="pie-tooltip">
        {v.label}:&nbsp;&nbsp;&nbsp;<span className="value">{
            CommonUtilities.formatPositiveIntNum(v.value) + ' (' +
            CommonUtilities.formatPositiveNum( v.data.perc, 1 ) + ' %)'
        }</span>
    </div>
}
export default function GrossPerYearChart(props) {
    
    const { isCairo, isClear, isDisco } = props.oCustomerChecks;
    const [data, setData] = useState([]);
    
    const
        url = isClear ? '/cubes/charts/gross-per-year' :
              isDisco ? '/cubes/charts/gross-per-year' :
              isCairo ? '/cubes/charts/net-per-year'   : ''
    ;

    useEffect(() => {
        async function onLoad() {
            setData( ( url && await FetchService.asyncGet({ url }) ) || [] );
        }
        onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        data.length > 0 && (
            <ResponsivePieCanvas
                data={data}
                margin={{top: 80, right: 80, bottom: 40, left: 80}}
                padAngle={1}
                cornerRadius={5}
                colors={{ scheme: 'set2' }}
                borderWidth={0}
                borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                radialLabel={(v) => v.label+" (" + CommonUtilities.formatPositiveIntNum(v.value) + ")" }
                radialLabelsSkipAngle={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{from: 'color'}}
                enableSliceLabels={false}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor="#333333"
                tooltip={v=>createTooltip(v.datum)}
                innerRadius={0.1}
                animate={false}
                enableArcLabels={true}
                arcLabel={ v => CommonUtilities.formatPositiveNum( v.data.perc, 1 ) + ' %' }
                arcLabelsSkipAngle={18.1}
                arcLabelsRadiusOffset={0.65}
                arcLinkLabelsSkipAngle={6}
                arcLinkLabel={ v => `${v.id} (${CommonUtilities.formatPositiveIntNum(v.value)})` }
            />
        )
    );
}
