import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Dialog               from '@material-ui/core/Dialog';
import DialogTitle          from '@material-ui/core/DialogTitle';
import DialogContent        from '@material-ui/core/DialogContent';
import TextField            from '@material-ui/core/TextField';
import Tooltip              from '@material-ui/core/Tooltip';
import Paper                from '@material-ui/core/Paper';
import DialogActions        from '@material-ui/core/DialogActions';
import Button               from '@material-ui/core/Button';
import React, {useEffect, useState} from 'react';
import Slide                from '@material-ui/core/Slide';
import IconButton           from '@material-ui/core/IconButton';
import BlurOnIcon           from '@material-ui/icons/BlurOn';
import Draggable            from 'react-draggable';
import {FormControlLabel}   from '@material-ui/core';
import Checkbox             from '@material-ui/core/Checkbox';
import Accordion            from '@material-ui/core/Accordion';
import AccordionSummary     from '@material-ui/core/AccordionSummary';
import AccordionDetails     from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon       from '@material-ui/icons/ExpandMore';
import ListAltIcon          from '@material-ui/icons/ListAlt';
// import Utils             from '../../../common/CommonUtilities';
import FetchService         from '../../../common/FetchService';
import DialogContentText    from '@material-ui/core/DialogContentText';
import {SimpleTable}        from './SimpleTable';
import CubeCommons          from './CubeCommons';


/* const getStringFromDate = CommonUtilities.getStringFromDate;
const momentLib   = moment; // per risolvere un problema di identificazione di moment da parte di phpstorm
const {
    getAsValuesFromAo,
    getAoFromAsValues,
    isValidNumber
} = CommonUtilities;*/

const themeDialog = createTheme({
    overrides: { MuiTooltip: { tooltip: { fontSize: '0.9em' } } },
    palette: {
        primary: {
            main:   '#509556'
        },
        secondary: {
            main:   'rgb(33, 33, 33)'
        },
        terziary: {
            main:   '#FFFFFF'
        }

    }
});

const Transition  = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


function PaperComponent(props) {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#alert-dialog-slide-title" cancel={'[class*="MuiDialogContent-root"]'} bounds="body">
            <Paper {...props} />
        </Draggable>
    );
}

/*

    #sette_casi_per_i_layout                                                                   SEZIONE SHARE
                                                                           NOT SHARED     VISIBILE     MODIFICABILE

    1) nuovo                                                                    ✓             ✓             ✓

    2) esistente, di MIA proprietà,     NON condiviso,      selezionato         ✓             ✓             ✓
    3) esistente, di MIA proprietà,     NON condiviso,  NON selezionato         ✓                           

    4) esistente, di MIA proprietà,     condiviso,          selezionato                       ✓             ✓
    5) esistente, di MIA proprietà,     condiviso,      NON selezionato                       ✓             
    
    6) esistente, proprietà di ALTRI,   condiviso,          selezionato                                     
    7) esistente, proprietà di ALTRI,   condiviso,      NON selezionato                                     

*/
export function ModalLayouts( props ) {
    
    // props
    const oLayout    = props.oLayoutToEdit || {};

    const loadUsers  = async () => {
        let users = await FetchService.asyncPost({
            url: '/cubes/get-layouts-users',
            params: { pLayoutCod: oLayout.column },
            displayErrorHandler: props.toggleNotifica
        }) || [];
        setaoUsers( [...users]  );
        setaoOriginalUsers( JSON.parse(JSON.stringify(users))  );
    }

    const loadGroups = async () => {
        let groups = await FetchService.asyncPost({
            url: '/cubes/get-layouts-groups',
            params: { pLayoutCod: oLayout.column },
            displayErrorHandler: props.toggleNotifica
        }) || [];
        setaoUserGroups( [...groups] );
        setaoOriginalUserGroups( JSON.parse(JSON.stringify(groups)) );
    }

    // stati
    const
        
        isNewLayout             = oLayout.column === -1,
        isModalFiltersRecap     = oLayout.column === -2,
        
         [ bOpacity             , setbOpacity             ] = useState( false ) // per rendere semi-trasparente la modale
        ,[ sLayoutName          , setsLayoutName          ] = useState( oLayout.description || '' ) // Es. 'MioLayout1'
        ,[ bFlagDefault         , setbFlagDefault         ] = useState( !!oLayout.default )
        ,[ bOptionsExpanded     , setbOptionsExpanded     ] = useState(false)
        ,[ bSharedExpanded      , setbSharedExpanded      ] = useState(false)

        ,[ sPopupType           , setsPopupType           ] = useState('')
        ,[ aoUserGroups         , setaoUserGroups         ] = useState([])
        ,[ aoOriginalUserGroups , setaoOriginalUserGroups ] = useState([])
        ,[ aoUsers              , setaoUsers              ] = useState([])
        ,[ aoOriginalUsers      , setaoOriginalUsers      ] = useState([])
        ,[ aoFiltersRowsOptions , setaoFiltersRowsOptions ] = useState(
            CubeCommons.getAoFiltersRowsOptions({
                oLayout ,aoDimensions: props.aoDimensions ,aoMeasures: props.aoMeasures ,withFLAG_SELECTED: true
            })
        )
        
        ,nUserGroupsSelected         = aoUserGroups.filter(          o => o.FLAG_SELECTED === 'Y' ).length
        ,nOriginalUserGroupsSelected = aoOriginalUserGroups.filter(  o => o.FLAG_SELECTED === 'Y' ).length
        ,nUsersSelected              = aoUsers.filter(               o => o.FLAG_SELECTED === 'Y' ).length
        ,nOriginalUsersSelected      = aoOriginalUsers.filter(       o => o.FLAG_SELECTED === 'Y' ).length
        
        ,[ bOnlySelected        , setbOnlySelected        ] = useState(false)
        
        ,handleChangeName        = (event) => {
            setsLayoutName(event.target.value);
        },
        
        handleChangeDefault     = (event) => {
            setbFlagDefault(!!event.target.checked);
        },

        handleChangeOnlySelected = (event) => {
            setbOnlySelected(!!event.target.checked);
        },
    
        handleModalSave         = () => {
            props.saveLayout({ nLayoutCod: oLayout.column, sLayoutName, bFlagDefault, aoUserGroups, aoUsers });
        },
        
        handleModalDelete       = () => {
            props.deleteLayout( oLayout.column );
        },
    
        handleModalClose        = () => {
            props.loading.set( false, 'ModalLayouts - handleModalClose' );
            props.refreshCubeBuilderState( { showLayoutModal: false }, false );
        },
        
        isSaveDisabled          = () => {
            return !sLayoutName;
        },
        
        isResetDisabled         = () => !aoFiltersRowsOptions.find( o => ( o.FLAG_SELECTED === 'Y' ) ),
        
        sOriginalName           = ( props.oLayoutToEdit || {} ).description || '',
        
        handleResetAllFilters   = async () => { // ( column, showLayoutModal = false )
        
            const aoDimensions  = props.aoDimensions || [];
            const aoMeasures    = props.aoMeasures   || [];
            const all           = [ ...aoDimensions ,...aoMeasures ];
            
            aoFiltersRowsOptions.forEach( oFilterRowOption => {
                if (  oFilterRowOption.FLAG_SELECTED === 'Y' ) { // per la riga indicata come "da resettare"
                    all.forEach( oDimOrMea => { // ciclo su dimensioni e misure
                        if ( oDimOrMea.column === oFilterRowOption.column ) { // fino a quella interessata
                            oDimOrMea.asCurrentFilters = []; // azzero i filtri
                        }
                    });
                }
            });
            
            await props.refreshCubeBuilderState({ aoDimensions, aoMeasures, showLayoutModal: false, sGraphSelected: '' });
            // await props.refreshCubeBuilderState({ showLayoutModal: showLayoutModal });
        
        },
        
        aoFiltersColumns        = [
             { name: 'label'        ,title: 'Option'            ,width: 170      ,isUniqueKeyForRow: true}
            ,{ name: 'value'        ,title: 'Value'             ,width: 170      ,isUniqueKeyForRow: true }
            ,{ name: 'selected'     ,title: 'Selected'          ,width: 90       }
            ,{ name: 'subtotal'     ,title: 'Subtotal'          ,width: 90       }
            ,{ name: 'sort'         ,title: 'Sorting'           ,width: 91       }
            ,!oLayout.oStructure.bTabularMode && // solo se non è tabular visualizzo la colonna Drill down
             { name: 'drill'        ,title: 'Drill down'        ,width: 90       }
            ,{ name: 'exclude'      ,title: 'Include'           ,width: 90       }
            ,{ name: 'filters'      ,title: 'Filters'           ,width: '100%'   }
        ],

        aoSharedGroupsColumns   = [
            { name: 'DESCRIPTION',  title: 'User Groups ( ' + aoUserGroups.filter( o => o.FLAG_SELECTED === 'Y' ).length + ' of '  + aoUserGroups.length + ' )', width: '100%', isUniqueKeyForRow: true }
        ],

        aoSharedUsersColumns    = [
            { name: 'DESCRIPTION',  title: 'Users ( ' + aoUsers.filter( o => o.FLAG_SELECTED === 'Y' ).length + ' of '  + aoUsers.length + ' )',            width: '100%', isUniqueKeyForRow: true }
        ],

        /*  viene impostato il FLAG_SELECTED se specificato, altrimenti viene invertito
        handleSetShared              = ({ oElement, aoElements, setAoElements, FLAG_SELECTED }) => {

            oElement.FLAG_SELECTED = ( FLAG_SELECTED ? FLAG_SELECTED : ( oElement.FLAG_SELECTED === 'Y' ? 'N' : 'Y' ) );
            setAoElements( [...aoElements] );
            
        },*/
        
        /* ATTENZIONE - questa funzione viene passata a un componente figlio (SimpleTable)
        handleChangeCheck = ( isChecked, oRow, sTableDataType ) => {
            oRow.FLAG_SELECTED = !!isChecked ? 'Y' : 'N';
            // oRow è il riferimento ad un oggetto contenuto in aoUsers o aoUserGroups
            // quindi una volta modificato è sufficiente risalvare nello stato una copia (come nuovo array) di aoUsers o aoUserGroups 
            if ( sTableDataType === 'USERS' ) {
                setaoUsers( [...aoUsers] );
            } else if ( sTableDataType === 'USERS_GROUPS' ) {
                setaoUserGroups( [...aoUserGroups] );
            }
        },*/

        isCheckedFunction       = oRow => oRow.FLAG_SELECTED  === 'Y',
        
        // ATTENZIONE - questa funzione viene passata a un componente figlio (SimpleTable)
        handleChangeCheck       = ( { forceChecked, oRow, onlyWithFLAG_SELECTED } ) => {
            // oRow è il riferimento ad un oggetto contenuto in aoRows
            if ( !onlyWithFLAG_SELECTED || ( onlyWithFLAG_SELECTED && oRow.FLAG_SELECTED ) ) {
                oRow.FLAG_SELECTED = ( forceChecked != null ? forceChecked : !isCheckedFunction( oRow ) ) ? 'Y' : 'N';
            }
            // quindi una volta modificato è sufficiente risalvare nello stato una copia (come nuovo array) di aoRows usando setAoRows
        },

        fCheckAll               = ( { bCheckAll, aoRows, setAoRows, onlyWithFLAG_SELECTED } ) => {
            // vengono selezionate TUTTE le righe, indipendentemente dai filtri per colonna
            aoRows.forEach( oRow => handleChangeCheck({ forceChecked: bCheckAll, oRow, onlyWithFLAG_SELECTED }) );
            setAoRows( [...aoRows] );
        },

        fCheckOne               = ( { isChecked, oRow, aoRows, setAoRows  } ) => {
            handleChangeCheck({ isChecked, oRow });
            setAoRows( [...aoRows] );
        },

        tableContainer          = ({ sTableType, sTableDataType, aoColumns, aoRows, setAoRows = () => {}, bOnlySelectedEnabled, bResetFilters }) => {
            return ( aoRows && aoRows.length ) && (
                <SimpleTable

                    chiave            ={ sTableDataType }
                    sTableDataType    ={ sTableDataType }
                    aoRows            ={ aoRows         }
                    aoCols            ={ aoColumns      }

                    extraClasses      ={ ( sTableDataType === 'FILTERS' )
                                           ? ' for-filters'
                                           : ( [ 'USERS', 'USERS_GROUPS' ].includes( sTableDataType )
                                               ? ' for-users'
                                               : ''
                                           )
                                       }
                    bOnlySelected     ={ bOnlySelectedEnabled && bOnlySelected }
                    oCheckOptions     ={ {
                        isEnabled:      !(oLayout.sharedToMe || !oLayout.selected),
                        isVisible:      sTableType === 'checkable',
                        isLastCol:      bResetFilters && true,
                        onlyIfFlagged:  bResetFilters
                    } }
                    isCheckedFunction ={ oRow => oRow.FLAG_SELECTED === 'Y'     }
                    fCheckAll         ={ bCheckAll => fCheckAll( { bCheckAll, aoRows, setAoRows, onlyWithFLAG_SELECTED: bResetFilters } ) }
                    fCheckOne         ={ ({ isChecked, oRow }) => fCheckOne( { isChecked, oRow, aoRows, setAoRows } ) }
                    
                    exception         ={ ( value, row, column, asCurrentFilters ) => 
                        ( row.filters && asCurrentFilters && ( column.name === 'filters' )
                            ? <span><ListAltIcon className="ListAltIcon" onClick={
                                () => { props.refreshCubeBuilderState({
                                    showFilterModal:  'read_only',
                                    sColumnToFilter:  row.column,
                                    asCurrentFilters
                                }) }
                              }/><span title={ ( value === 0 ) ? 0 : ( value || '' ) }>{ value }</span></span>
                            : ( value )
                        )
                    }
                    
                />
            )
        },

        handleExpandOptions     = () => {
            if ( bOptionsExpanded ) {
                setbOptionsExpanded( false );
            } else {
                setbOptionsExpanded( !bOptionsExpanded );
                setbSharedExpanded(   bOptionsExpanded );
            }
        },

        handleExpandShared      = () => {
            if ( bSharedExpanded ) {
                setbSharedExpanded(false);
            } else {
                setbOptionsExpanded( bSharedExpanded );
                setbSharedExpanded( !bSharedExpanded );
            }
        },

        createPopupDialog       = () => {

            const oPopupOptions = {
                'save': {
                     DialogTitle:       'Save Layout'
                    ,DialogContentText: 'Are you sure you want to save the selected layout as '
                    ,onClick:           handleModalSave
                    ,firstButtonLabel:  'save'
                },
                'delete': {
                     DialogTitle:       'Delete Layout'
                    ,DialogContentText: 'Are you sure you want to delete the layout '
                    ,onClick:           handleModalDelete
                    ,firstButtonLabel:  'delete'       
                }
            }[sPopupType];
            /*
            ,
                'reset': {
                     DialogTitle:       'Reset Filters'
                    ,DialogContentText: 'Are you sure you want to reset all Filters'
                    ,onClick:           handleResetAllFilters
                    ,firstButtonLabel:  'reset'
                }    
            */
            
            const closeAction = () => { setsPopupType('') };
            
            return <Dialog
                open={ ( sPopupType !== '' ) }
                TransitionComponent={Transition}
                onClose={closeAction}
            >
                <DialogTitle>{oPopupOptions.DialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {oPopupOptions.DialogContentText}
                        { ( sPopupType !== 'reset' ) && <b>{sLayoutName}</b> }?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={oPopupOptions.onClick} color="secondary">
                        {oPopupOptions.firstButtonLabel}
                    </Button>
                    <Button onClick={closeAction} color="secondary">
                        cancel
                    </Button>
                </DialogActions>
            </Dialog>

        },
        
        displayNumberElements   = ( aoElements = [] ) => {
            const isGroup    = aoElements[0] && ( aoElements[0].TYPE === 'GROUP' );
            const nTotal     = aoElements.length;
            const aoSelected = aoElements.filter( o => o.FLAG_SELECTED === 'Y' )
            const nSelected  = aoSelected.length;
            return !nSelected ? '' : <Tooltip title={ aoSelected.map( o => o.DESCRIPTION ).join(', ') }><u>{(
                '' + ( nTotal === nSelected ? 'all' : nSelected ) + ' User' + ( isGroup ? ' Group' : '' ) + ( nSelected > 1 ? 's' : '' )
                )}</u></Tooltip>
        }

    ;

    useEffect(() => {
        loadGroups();
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        setbOnlySelected( !isNewLayout && ( nOriginalUserGroupsSelected || nOriginalUsersSelected ) );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isNewLayout, nOriginalUserGroupsSelected, nOriginalUsersSelected ]);
    
    
    
    return (
        
        <MuiThemeProvider theme = { themeDialog }>
            
            <Dialog
                open                ={props.showLayoutModal}
                TransitionComponent ={Transition}
                keepMounted
                onClose             ={handleModalClose}
                aria-labelledby     ="alert-dialog-slide-title"
                aria-describedby    ="alert-dialog-slide-description"
                PaperComponent      ={PaperComponent}
                className           ={
                    'options dialog'
                    + ( bOpacity ? ' opa' : '' )
                    + ( bOptionsExpanded || bSharedExpanded || isModalFiltersRecap ? ' expanded' : '' )
                    + ( props.nZoom ? ' zoom' + props.nZoom : '' )
                }
            >
                
                <DialogTitle id="alert-dialog-slide-title">
                    
                    <span className="dialog-title-container">
                        { ( sOriginalName 
                            ? <>
                                <span className="prefisso">{ oLayout.sharedToMe ? 'Shared Layout:' : 'Layout:' }&nbsp;</span>
                                <span className="nome"    >{ sOriginalName }</span>
                                <span className="suffisso">&nbsp;{ oLayout.selected ? '(selected)' : ( !oLayout.sharedToMe ? '(select to edit)' : '' ) }</span>
                            </> 
                            :   <span>{ !isModalFiltersRecap ? 'New Layout' : 'Filters' }</span>
                        ) }
                    </span>
                    
                    <Tooltip title={ bOpacity ? 'Show' : 'Hide' }>
                        <IconButton id="#button-opacity" onClick={() => setbOpacity(!bOpacity)}><BlurOnIcon /></IconButton>
                    </Tooltip>
                    
                </DialogTitle>
                
                <div className="dialog-separator" />

                <DialogContent id="alert-dialog-slide-description" style={{ minHeight: 150 }}>

                    {
                        ( !isModalFiltersRecap ) ? (
                            <Accordion expanded={bOptionsExpanded} onChange={handleExpandOptions}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="accordionSummary"
                                >
                                    {
                                        ( oLayout.sharedToMe )
                                        ? <label className="accordionLabel"><span>{ 'Owner: ' + oLayout.ownerDesc }</span></label>
                                        : <>
                                            <form className="LayoutNameTextfield" autoComplete="off">
                                                <TextField
                                                    id       ="layout-name"
                                                    label    ="Layout Name"
                                                    value    ={sLayoutName}
                                                    onChange ={handleChangeName} 
                                                    onClick  ={((event)=>{event.stopPropagation()})}
                                                    disabled ={ oLayout.sharedToMe || ( !oLayout.selected && !isNewLayout ) }
                                                />
                                            </form>
                                            <Tooltip className="defaultCheckbox" title="Set this Layout as default to preload it with the actual cube" >
                                                <FormControlLabel
                                                    onClick  ={((event)=>{event.stopPropagation()})}
                                                    control={
                                                        <Checkbox
                                                            id       ="layout-default"
                                                            checked  ={ !!bFlagDefault }
                                                            onChange ={handleChangeDefault}
                                                            onClick  ={((event)=>{event.stopPropagation()})}
                                                            name     ="defaultCheck"
                                                            color    ="primary"
                                                            disabled ={ oLayout.sharedToMe || ( !oLayout.selected && !isNewLayout ) }
                                                        />
                                                    }
                                                    label="Default"
                                                />
                                            </Tooltip>
                                        </>
                                    }
                                    
                                    <span className="accordionLabel secondaryHeading">Options</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    { tableContainer( {
                                        sTableType: 'read_only', sTableDataType: 'FILTERS' ,aoColumns: aoFiltersColumns,
                                        aoRows: aoFiltersRowsOptions ,setAoRows: setaoFiltersRowsOptions 
                                    } ) }
                                </AccordionDetails>
                            </Accordion>
                        )
                        :  tableContainer( {
                                sTableType: 'checkable', sTableDataType: 'FILTERS', aoColumns: aoFiltersColumns, 
                                aoRows: aoFiltersRowsOptions ,setAoRows: setaoFiltersRowsOptions ,bResetFilters: true
                        } )
                    }
                    
                    {
                        !isModalFiltersRecap && (
                            // VEDERE SCHEMA: #sette_casi_per_i_layout
                            (      isNewLayout
                                || ( oLayout.selected && oLayout.sharedByMe  )
                                || ( oLayout.selected && !oLayout.sharedByMe && !oLayout.sharedToMe )
                                || ( !oLayout.selected && oLayout.sharedByMe )
                            )
                            ?
                                <Accordion expanded={bSharedExpanded} onChange={handleExpandShared}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2-header"
                                        className="accordionSummary"
                                    >
                                        
                                        { ( nOriginalUserGroupsSelected || nOriginalUsersSelected )
                                            ? <span className="sharedwith">Shared with&nbsp;
                                                  { displayNumberElements( aoOriginalUserGroups ) }
                                                  { ( nOriginalUserGroupsSelected && nOriginalUsersSelected ) ? ' and ' : '' }
                                                  { displayNumberElements( aoOriginalUsers ) }
                                              </span>
                                            : <span className="sharedwith">Not shared</span>
                                        }
                                        
                                        <span className="accordionLabel secondaryHeading">Share</span>
                                        
                                    </AccordionSummary>
                                    <AccordionDetails className={
                                        'accordionDetails '+ ( ( oLayout.selected || isNewLayout ) ? '' : 'checkbox-invisible' )
                                    }>
                                        
                                        <FormControlLabel
                                            className="checkOnlySelected"
                                            control={
                                                <Checkbox
                                                    checked     ={ !!bOnlySelected }
                                                    onChange    ={handleChangeOnlySelected}
                                                    color       ="primary"
                                                    disabled    ={ !bOnlySelected && ( nUserGroupsSelected === 0 ) && ( nUsersSelected === 0 )}
                                                />
                                            }
                                            label="Only selected"
                                        />
                                        
                                        { tableContainer({
                                            sTableType:             'checkable',
                                            sTableDataType:         'USERS_GROUPS',
                                            aoColumns:              aoSharedGroupsColumns,
                                            aoRows:                 aoUserGroups,
                                            setAoRows:              setaoUserGroups,
                                            bOnlySelectedEnabled:   true
                                        }) }
                                        
                                        { tableContainer({
                                            sTableType:             'checkable',
                                            sTableDataType:         'USERS',
                                            aoColumns:              aoSharedUsersColumns,
                                            aoRows:                 aoUsers,
                                            setAoRows:              setaoUsers,
                                            bOnlySelectedEnabled:   true
                                        }) }
                                        
                                    </AccordionDetails>
                                </Accordion>
                            
                            :   ( oLayout.sharedToMe ? null : <div className="sharedwith">Not shared</div> )
                        )
                    }
                    
                </DialogContent>

                <DialogActions>
                    
                    { ( !isModalFiltersRecap )                 && !oLayout.sharedToMe && ( oLayout.selected || isNewLayout ) && 
                        <Button onClick={ () => { setsPopupType('save'  ) } } color="secondary" disabled={isSaveDisabled()}  >save</Button>   }
                    
                    { ( !isModalFiltersRecap ) && !oLayout.sharedToMe && !isNewLayout &&
                        <Button onClick={ () => { setsPopupType('delete') } } color="secondary"                              >delete</Button> }
                    
                    { ( isModalFiltersRecap  ) &&
                        <Tooltip title="Reset selected filters">
                            <Button onClick={ handleResetAllFilters }               color="secondary" disabled={isResetDisabled()} >reset</Button>
                        </Tooltip> }
                    
                        <Button onClick={ handleModalClose }                        color="secondary"                              >cancel</Button>
                    
                </DialogActions>
               
            </Dialog>

            { ( sPopupType !== '' ) && createPopupDialog() }
            
        </MuiThemeProvider>
        
    )
    
}
