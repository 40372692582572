import React, { useEffect, useState } from 'react';
import                       './UsersEditing.css';
import FetchService          from '../../common/FetchService';
import Utils                 from '../../common/CommonUtilities';
import { SnackAlert }        from '../../common/SnackAlert';
import { PopupConfirm }      from '../../common/PopupConfirm';
import ChangePassword        from '../profile/ChangePassword';
import { SimpleTable }       from '../cubes/components/SimpleTable';

// material
import {
    createTheme,
    MuiThemeProvider
}                            from '@material-ui/core/styles';
import Dialog                from '@material-ui/core/Dialog';
import DialogActions         from '@material-ui/core/DialogActions';
import DialogContent         from '@material-ui/core/DialogContent';
import DialogContentText     from '@material-ui/core/DialogContentText';
import DialogTitle           from '@material-ui/core/DialogTitle';
import Button                from '@material-ui/core/Button';
import Checkbox              from '@material-ui/core/Checkbox';
import InputLabel            from '@material-ui/core/InputLabel';
import TextField             from '@material-ui/core/TextField';
import Tooltip               from '@material-ui/core/Tooltip';
import Fab                   from '@material-ui/core/Fab';
import FormControl           from '@material-ui/core/FormControl';
import Input                 from '@material-ui/core/Input';
import InputAdornment        from '@material-ui/core/InputAdornment';
import IconButton            from '@material-ui/core/IconButton';

// icons
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CloseIcon             from '@material-ui/icons/Close';
import VpnKey                from '@material-ui/icons/VpnKey';
import AddIcon               from '@material-ui/icons/Add';
import Visibility            from '@material-ui/icons/Visibility';
import VisibilityOff         from '@material-ui/icons/VisibilityOff';
import utils                 from '../../common/CommonUtilities';

const theme          = createTheme({
    overrides: { MuiTooltip: { tooltip: { fontSize: '0.9em' } } },
    palette: {
      primary: {
        main: '#519657'
      },
      secondary: {
        main: 'rgb(33, 33, 33)'
      },
    },
    typography: {
        useNextVariants: true,
        fontSize: 13,
    }
});
const formatDateTime = (sDateTime) => Utils.formatDateTime(sDateTime, { output: 'D MMM YYYY HH:mm', fromNow: true });

function PopupEdit( { options } ) {
    const
         { bShowDialog, oDialogOptions, isLoading, set_bShowDialog, sTitle, sConfirmButton, oMapLabelAndValues, reload, toggleNotifica } = options
        ,isInsNewUser  = !oDialogOptions || ( Object.keys(oDialogOptions).length === 0 )
        ,{ oRow = {} } = oDialogOptions
        ,[ sUserRole      ,set_sUserRole      ] = useState(oRow['FLAG_ADMIN'])
        ,[ sUserStatus    ,set_sUserStatus    ] = useState(oRow['FLAG_ATTIVO'])
        ,[ sUserRoobeek   ,set_sUserRoobeek   ] = useState(oRow['FLAG_ROOBEEK'])
        ,[ sSurname       ,set_sSurname       ] = useState(oRow['COGNOME'])
        ,[ sName          ,set_sName          ] = useState(oRow['NOME'])
        ,[ sUserName      ,set_sUserName      ] = useState(oRow['USERNAME'])
        ,[ sEmail         ,set_sEmail         ] = useState(oRow['EMAIL_ADDRESS'])
        ,[ sUserAgents    ,set_sUserAgents    ] = useState(oRow['AGENTE_COD'])
        ,[ oParamsInsUser ,set_oParamsInsUser ] = useState( {} )
        ,[ oParamsModUser ,set_oParamsModUser ] = useState( {} )
        ,[ oParamsDelUser ,set_oParamsDelUser ] = useState( {} )
        ,[ sPopupType     ,set_sPopupType     ] = useState('')
        ,[ sPassword      ,set_sPassword      ] = useState('')
        ,[ bShowPassword  ,set_bShowPassword  ] = useState(false)
    ;

    const createPopupConfirm = () => {
        const oPopupOptions = {
            'delete': {
                 DialogTitle:       'Delete User'
                ,DialogContentText: 'Are you sure you want to delete the user "' + oRow.FULL_NAME + '" ?'
                ,onClick:           ()=>{
                    set_oParamsDelUser({ pUserId: oRow.KUSER });
                }
                ,firstButtonLabel:  'delete'
                ,firstButtonColor:  '#ce4040'
            }
        }[sPopupType] || {};
        return <PopupConfirm
            { ...{ oPopupOptions, sPopupType, set_sPopupType } }
        />
    };

    const actionsOnUser = ({ url, params, actionType }) => {
        if ( Object.keys( params || {} ).length ) {
            ( async () => {
                const { nRetVal, vErrorMessageDesc } = await FetchService.asyncPost({
                     url
                    ,params
                    ,displayErrorHandler: toggleNotifica
                });

                if ( ( +nRetVal < -99 ) || ( +nRetVal > 0 ) ) {
                    set_bShowDialog( false );
                    toggleNotifica( 'User ' + { ins: ' created', mod: ' updated', del: ' deleted' }[actionType], 'success' );
                    reload();
                } else {
                    toggleNotifica( vErrorMessageDesc || 'Error', 'error' );
                }
            })();
        }
    };

    useEffect(() => {
        if ( Object.keys(oParamsInsUser).length ) {
            actionsOnUser({ url: '/settings/ins-user', params: oParamsInsUser, actionType: 'ins' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oParamsInsUser ]);

    useEffect(() => {
        if ( Object.keys(oParamsModUser).length ) {
            actionsOnUser({ url: '/settings/mod-user', params: oParamsModUser, actionType: 'mod' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oParamsModUser ]);

    useEffect(() => {
        if ( Object.keys(oParamsDelUser).length ) {
            actionsOnUser({ url: '/settings/del-user', params: oParamsDelUser, actionType: 'del' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oParamsDelUser ]);

    const makeTextField = ( sKey, value, set_value ) => {
        const oMap = oMapLabelAndValues[sKey];
        if ( oMap ) {
            const format = oMap.format || (val => val);
            return <TextField
                key      ={sKey}
                id       ={sKey}
                className={sKey}
                label    ={ oMap.label }
                value    ={ format( typeof value !== 'undefined' ? value : oRow[sKey] ) || '' }
                onChange ={ (event) => {
                    set_value( format( event.target.value ) );
                } }
                onClick  ={((event)=>{event.stopPropagation()})}
                disabled ={ !oMap.enabled }
            />
        }
    };

    const makeCheckbox = ({ sFieldName, sFieldLabel, sState, setState }) => {
        return <div className={sFieldName} >
            <div className="container">
                <Checkbox
                    className="checkbox"
                    labelId  ={ sFieldName + '-label' }
                    id       ={ sFieldName }
                    value    ={ sState }
                    checked  ={ sState === 'Y' }
                    onChange ={ event => { setState(event.target.checked ? 'Y' : 'N' ); } }
                />
                <InputLabel className="inputLabel" id={ sFieldName + '-label' } disabled>{ sFieldLabel }</InputLabel>
            </div>
        </div>
    }

    const makePswField = ({ sFieldName, sFieldLabel, sState, setState, showState, setShowState }) => (
        <div className={sFieldName}>
            <FormControl className="password-wrapper" >
                <InputLabel htmlFor={ sFieldName }>{ sFieldLabel }</InputLabel>
                <Input
                    type        ={ showState ? 'text' : 'password' }
                    name        ={ sFieldName }
                    value       ={ sState     }
                    onChange    ={ (event) => { setState( event.target.value ); } }
                    margin      ="dense"
                    inputProps  ={{maxLength: 50, autoComplete: 'off'}}
                    endAdornment={
                        <InputAdornment position="end" tabIndex={-1}>
                            <IconButton onClick={ (event) => { setShowState(!showState) } } tabIndex={-1}>
                                { showState ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </div>
    )

    return <Dialog open={ bShowDialog } className="users editing-dialog" >

        <DialogTitle>{ sTitle }</DialogTitle>

        <DialogContent>
            { isLoading() ? <div>&nbsp;<br/>&nbsp;</div> : <DialogContentText>{

                <form className="editFields" autoComplete="off">
                    { makeTextField( 'USERNAME'        ,sUserName   ,set_sUserName ) }
                    { makeTextField( 'COGNOME'         ,sSurname    ,set_sSurname  ) }
                    { makeTextField( 'NOME'            ,sName       ,set_sName     ) }
                    { makeTextField( 'EMAIL_ADDRESS'   ,sEmail      ,set_sEmail    ) }
                    { isInsNewUser ? makePswField({
                             sFieldName:    'PASSWORD'
                            ,sState:        sPassword
                            ,setState:      set_sPassword
                            ,showState:     bShowPassword
                            ,setShowState:  set_bShowPassword
                            ,sFieldLabel:   'Password'
                    }) : null }
                    { makeTextField( 'AGENTE_COD'           ,sUserAgents, set_sUserAgents ) }
                    { isInsNewUser ? null : makeTextField( 'DATA_INSERIMENTO'     ) }
                    { isInsNewUser ? null : makeTextField( 'DATA_ULTIMA_MODIFICA' ) }
                    { isInsNewUser ? null : makeTextField( 'DATA_ULTIMA_LOGIN'    ) }
                    {
                        makeCheckbox({
                             sFieldName:    'FLAG_ADMIN'
                            ,sState:        sUserRole
                            ,setState:      set_sUserRole
                            ,sFieldLabel:   'Administrator'
                        })
                    }{  isInsNewUser ? null :
                        makeCheckbox({
                             sFieldName:    'FLAG_ROOBEEK'
                            ,sState:        sUserRoobeek
                            ,setState:      set_sUserRoobeek
                            ,sFieldLabel:   'Roobeek User'
                        })
                    }
                    {   isInsNewUser ? null :
                        <div className="FLAG_ATTIVO">
                            <div className="container">
                                { makeCheckbox({
                                     sFieldName:    'FLAG_ATTIVO'
                                    ,sState:        sUserStatus
                                    ,setState:      set_sUserStatus
                                    ,sFieldLabel:   'Enabled'
                                })}
                            </div>
                        </div>
                    }
                    {   isInsNewUser || ( oRow['FLAG_ATTIVO'] === 'Y' ) ? null :
                        <TextField
                            key       ="DISABLED"
                            id        ="DISABLED"
                            className ="DISABLED"
                            label     =""
                            value     ={ ( oRow['FLAG_ATTIVO'] === 'Y' )
                                ? ''
                                : ( 'Disabled on ' + formatDateTime(oRow['DATA_CANCELLAZIONE']) ) }
                            disabled
                        />
                    }
                </form>

            }</DialogContentText> }
            {
                createPopupConfirm()
            }
        </DialogContent>

        <DialogActions>
            { isLoading() ? null :
                <>
                    <Button onClick={ ()=>{
                        ( isInsNewUser ? set_oParamsInsUser : set_oParamsModUser )({
                             pUserId       : oRow.KUSER
                            ,pUserName     : sUserName
                            ,pCognome      : sSurname
                            ,pNome         : sName
                            ,pPassword     : sPassword
                            ,pEMailAddress : sEmail
                            ,pFlagRoobeek  : sUserRoobeek
                            ,pFlagAdmin    : sUserRole
                            ,pAgenteCod    : sUserAgents
                            ,pFlagEnabled  : sUserStatus
                        });
                    } } color="primary">{ sConfirmButton }</Button>
                    { isInsNewUser ? null :  <Button
                        className="deleteBtn"
                        onClick={() => {
                            set_sPopupType('delete');
                        }}
                    >DELETE</Button> }
                    <Button onClick={ ()=> {
                        set_bShowDialog(false);
                        reload();
                    } } color="secondary">cancel</Button>
                </>
            }
        </DialogActions>

    </Dialog>
}

export default function UsersEditing(props) {

    const
         [ bReload          ,set_bReload         ] = useState(props.reload)

        ,[ aoUsers          ,set_aoUsers         ] = useState([])
        ,[ bUsersChecks                          ] = useState(false) // set_bUsersChecks

        ,[ aoUserGroups     ,set_aoUserGroups    ] = useState([])

        ,[ bShowDialog      ,set_bShowDialog     ] = useState(false)
        ,[ oDialogOptions   ,set_oDialogOptions  ] = useState({}) // { sTitle: '', content: null, sConfirmButton: '' }
        ,[ oNotifyOptions   ,set_oNotifyOptions  ] = useState({ message: '', severity: '' }) // severity: 'error' | 'warning' | 'info' | 'success'

        ,[ oParamsModUserInGroup, set_oParamsModUserInGroup ] = useState( null )
        ,[ pUserIdChangePsw ,set_pUserIdChangePsw ] = useState(0)

        ,{ get: isLoading   ,set: set_isLoading  } = props.loading

     // ,isInsNewUser = Object.keys(oDialogOptions).length === 0

        ,reload = () => { set_bReload( !bReload ); }
    ;

    const handleCloseAlert    = ( /* event, reason */ ) => {
        // if (reason === 'clickaway') { return; }
        set_oNotifyOptions({ message: '' });
    };

    const toggleNotifica        = ( message, severity ) => {
        set_isLoading(false);
        set_bShowDialog(false);
        set_oNotifyOptions({ message, severity  });
    };

    // le chiamate a queste anagrafiche sono indipendenti da tutto
    useEffect(() => {
        // console.log('bReload cambiato');
        ( async () => {
            const userList   = await FetchService.asyncGet({url: '/lookup/user-list'         });
            const userGroups = await FetchService.asyncGet({url: '/settings/get-user-groups' });
            set_aoUsers(      [...userList  ] || [] );
            set_aoUserGroups( [...userGroups] || [] );
        })();
    }, [ bReload ]);

    useEffect(() => {
        if ( Object.keys( oParamsModUserInGroup || {} ).length ) {
            ( async () => {
                const { nRetVal, vErrorMessageDesc } = await FetchService.asyncPost({
                     url:    '/settings/set-user-group-users'
                    ,params: oParamsModUserInGroup
                    ,displayErrorHandler: toggleNotifica
                }) || 0;
                if ( nRetVal < 1 ) {
                    toggleNotifica( vErrorMessageDesc || 'Error', 'error' );
                }
                reload();
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oParamsModUserInGroup ]);

    const
        oFormatRole             = { 'Y': 'Admin', 'N': 'User', 'Admin': 'Y', 'User': 'N' },

        sUsersLabel             = 'Users ('       + (     aoUsers || []).length + ')',
        sGroupsLabel            = 'User Groups (' + (aoUserGroups || []).length + ')',

        aoUsersColumns          = [

             {     group: sUsersLabel ,name: 'KUSER'              ,width: 44  ,title: '' ,noFilter: true ,additionalClass: () => 'edit' ,tooltip: 'Force new Password'
                    ,format: val => ( +val === +localStorage.getItem('kUser') ) ? <></> : <VpnKey onClick={ () => set_pUserIdChangePsw(val) } /> ,isUniqueKeyForRow: true  }
            ,{     group: sUsersLabel ,name: 'USERNAME'           ,width: 200 ,title: 'Username' }
            ,{     group: sUsersLabel ,name: 'COGNOME'            ,width: 150 ,title: 'Surname'  }
            ,{     group: sUsersLabel ,name: 'NOME'               ,width: 150 ,title: 'Name'     }
            ,{     group: sUsersLabel ,name: 'EMAIL_ADDRESS'      ,width: 200 ,title: 'Email'    }
            ,{     group: sUsersLabel ,name: 'DATA_ULTIMA_LOGIN'  ,width: 145 ,title: 'Last Login', format: val => utils.formatDateTime(val, {
                        input  : 'YYYYMMDDhhmmss'
                       ,output : 'D MMM YYYY HH:mm'
                   })
            }
            ,{     group: sUsersLabel ,name: 'FLAG_ROOBEEK'       ,width: 38  ,title: '' ,noFilter: true ,additionalClass: val => val ,format: val => (
                    ( val === 'Y' ) ? <img className="roobeekIcon" draggable="false" src="/roobeek_marchio.svg" alt="Roobeek"/> : ''
             )}
            ,{     group: sUsersLabel ,name: 'STATUS'             ,width: 23  ,title: '' ,noFilter: true ,additionalClass: val => val ,format: val => (
                    ( val.toUpperCase().includes('ADMIN')    ) ? <SupervisorAccountIcon/> :
                    ( val.toUpperCase().includes('DISABLED') ) ? <CloseIcon/>             : ''
             )}
            ,{     group: sUsersLabel ,name: 'STATUS'             ,width: 145 ,title: 'Role'                            ,additionalClass: val => val }
            ,{     group: sUsersLabel ,name: 'AGENTE_COD'         ,width: 150 ,title: 'Agent Code'                      }

            ,...aoUserGroups.map( ({ GROUP_COD, GROUP_DESC }) =>
                ({ group: sGroupsLabel ,name: 'USER_GROUP_LIST'    ,width: '' ,title: GROUP_DESC  ,key: GROUP_COD

                   ,format:      val =>// ( (v=>{console.log(v);return v})(val) === 'Y' ) ?  ( 'USER_GROUP_LIST' + GROUP_COD ) :
                                      // ( val === 'N' ) ? !( 'USER_GROUP_LIST' + GROUP_COD ) :
                                        ( typeof val === 'number' ) ? val :
                                        ( ( val || '' ).includes(GROUP_COD) ? '✓' :  '✕' )

                   ,onHover:     ( val, kUser, groupCod ) => <Checkbox checked={ val === '✓' } onChange={ () => { set_oParamsModUserInGroup({
                        groupCod
                       ,kUser
                       ,flagSelected:   ( val === '✓' ) ? 'N' : 'Y'
                   }) } } />

                   ,selectTriOptions: [
                       { label: ' ' ,value: 'all'     ,checkFunc: () => true }, // tutte le righe sono valide
                       { label: '✓' ,value: 'include' ,checkFunc: ( val, valToCheck ) => val.includes(valToCheck) },
                       { label: '✕' ,value: 'exclude' ,checkFunc: ( val, valToCheck ) => !val || !( val.includes(valToCheck) ) }
                   ]
                })
            )

        ],

        oMapLabelAndValues = {
             'COGNOME'              : { label: 'Surname'            ,enabled: true  }
            ,'NOME'                 : { label: 'Name'               ,enabled: true  }
            ,'FLAG_ADMIN'           : { label: 'Role'               ,enabled: true  ,format: val => oFormatRole[val] }
            ,'USERNAME'             : { label: 'Username'           ,enabled: true  }
            ,'EMAIL_ADDRESS'        : { label: 'Email'              ,enabled: true  }
            ,'AGENTE_COD'           : { label: 'Agent Codes'        ,enabled: true  ,format: val => val ? val.replace(/[^a-zA-Z0-9,]/g, '') : '' }
         // ,'FLAG_ATTIVO'          : { label: 'Active'             ,enabled: false }
         // ,'DATA_INSERIMENTO'     : { label: 'Creation date'      ,enabled: false ,format: val => formatDateTime(val) }
            ,'DATA_ULTIMA_MODIFICA' : { label: 'Last update'        ,enabled: false ,format: val => formatDateTime(val) }
         // ,'DATA_CANCELLAZIONE'   : { label: 'Cancellation date'  ,enabled: false ,format: val => formatDateTime(val) }
            ,'DATA_ULTIMA_LOGIN'    : { label: 'Last login date'    ,enabled: false ,format: val => formatDateTime(val) || 'Never logged' }
        },

        onClickEdit     = ( oRow ) => {
            set_bShowDialog(true);
            set_oDialogOptions({ oRow });
        }

    ;

    useEffect( () => {
        if ( !bShowDialog ) {
            set_isLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ bShowDialog ]);

    return (
        <div className="users-editing">

            <MuiThemeProvider theme={theme}>

                { /* ----- notifiche ----- */ }
                <SnackAlert oNotifyOptions={ { ...oNotifyOptions, handleCloseAlert } } />

                { /* ----- popup di modifica dati utente ----- */ }
                { !bShowDialog ? null : <PopupEdit
                    options ={{ bShowDialog, oDialogOptions, isLoading, set_bShowDialog,
                        sTitle: 'User Profile', sConfirmButton: 'Save', oMapLabelAndValues, reload, toggleNotifica }}
                /> }

                { /* ----- popup di modifica dati utente ----- */ }
                { !pUserIdChangePsw ? null : <ChangePassword { ...{
                     showState:     pUserIdChangePsw
                    ,set_showState: set_pUserIdChangePsw
                    ,set_oNotifyOptions
                    ,force:         true
                    ,sUsername:     ( aoUsers.find( o => o.KUSER === pUserIdChangePsw ) || {} ).USERNAME
                } } /> }

                { /* ----- contenuto della pagina ----- */ }
                <div className="container">

                    {   !aoUsers[0] ? null :
                        <Tooltip title="Create new User">
                            <Fab
                                color       ="primary"
                                aria-label  ="add"
                                size        ="small"
                                style       ={{marginLeft: 20}}
                                className   ="ins-new-user-btn"
                                onClick     ={ () => {
                                    set_bShowDialog(true);
                                    set_oDialogOptions({});
                                } }
                            ><AddIcon /></Fab>
                        </Tooltip>
                    }
                    {
                        <SimpleTable

                            chiave                ={ 'users' }
                            sTableDataType        ={ 'USERS' }
                            aoRows                ={ [...aoUsers] }
                            oRowOptions           ={{ addClass: (oRow) => ( oRow['STATUS'] || '' ).toLowerCase().includes('disabled') ? ' row-disabled ' : '' }}
                            aoCols                ={ [...aoUsersColumns] }

                            noHeight              ={ true }

                            isEditable            ={ !bUsersChecks }
                            editFunction          ={ onClickEdit }

                        />
                    }

                </div>

            </MuiThemeProvider>

        </div>
    );
}
