import Dialog               from '@material-ui/core/Dialog';
import DialogTitle          from '@material-ui/core/DialogTitle';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogContentText    from '@material-ui/core/DialogContentText';
import DialogActions        from '@material-ui/core/DialogActions';
import Button               from '@material-ui/core/Button';

// ----- popup di conferma operazione ----- 
export function PopupConfirm( oConfirmOptions ){
    
    const { oPopupOptions, sPopupType, set_sPopupType } = oConfirmOptions;
    // console.log('sPopupType',sPopupType);
    const closeAction = () => { set_sPopupType('') };

    return <Dialog
        open={ ( !!sPopupType ) }
        onClose={ closeAction }
    >
        <DialogTitle>{ oPopupOptions.DialogTitle }</DialogTitle>
        <DialogContent>
            <DialogContentText>{ oPopupOptions.DialogContentText }</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={ oPopupOptions.onClick }
                style  ={ { color: oPopupOptions.firstButtonColor || '' } }
            >{ oPopupOptions.firstButtonLabel }</Button>
            <Button onClick={ closeAction } color="secondary">{ oPopupOptions.secondButtonLabel || 'cancel' }</Button>
        </DialogActions>
    </Dialog>
}

/* ----- esempio di utilizzo -----

    const [ sPopupType ,set_sPopupType ] = useState('');

    const oPopupOptions = {
         DialogTitle:       'Update Users'
        ,DialogContentText: 'Are you sure you want to update Users?'
        ,onClick:           refreshUsers
        ,firstButtonLabel:  'confirm'
    };
    
    return <PopupConfirm
        { ...{ oPopupOptions, sPopupType, set_sPopupType } }
    />

*/
