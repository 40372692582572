import React, {useEffect, useState} from 'react';
import                          './Dashboard.css';
import Grid                     from '@material-ui/core/Grid';
import Paper                    from '@material-ui/core/Paper';
import Typography               from '@material-ui/core/Typography';
import ToggleButtonGroup        from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton             from '@material-ui/lab/ToggleButton';
import GrossPerChannelYearChart from './components/GrossPerChannelYearChart';
import GrossPerChannelChart     from './components/GrossPerChannelChart';
import GrossPerYearChart        from './components/GrossPerYearChart';
import BacinoPerData            from './components/BacinoPerData';

/*
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));
*/

export default function Dashboard(props) {
    
    const [chartFormat, setChartFormat] = useState(1);
    // const classes = useStyles();
    // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const
        [ customer, setCustomer ]   = useState('')
        ,isCairo                    = customer.includes('cairo')
        ,isClear                    = customer.includes('clear')
        ,isDisco                    = customer.includes('disco')
        ,isSole                     = customer.includes('sole')
        ,oCustomerChecks            = { isCairo, isClear, isDisco ,isSole }
        ,customerRecognized         =[...Object.values(oCustomerChecks)].some( b => b === true )
    ;
    
    const sField  = isClear ? 'Asset' : 'Channel';

    const field1  = isCairo ? `Month` :
                    isClear ? `Asset` :
                    isDisco ? `Channel` 
                            : '';

    const field2  = isCairo ? `Year` :
                    isClear ? `Year` :
                    isDisco ? `Year` 
                            : '';

    const title1  = isCairo ? `Importo Netto per Mese, Anno` :
                    isClear ? `Gross per Asset, Revenue Year` :
                    isDisco ? `Gross per Channel, Revenue Year` :
                    isSole  ? `Bacino degli ultimi 31 giorni` 
                            : '';

    const title2  = ( chartFormat === 1 ) ? (
                        isCairo ? `Importo Netto per Canale` :
                        isClear ? `Total Gross per ${ sField }` :
                        isDisco ? `Total Gross per ${ sField }` 
                                : ''
                    ) : `Gross per Revenue Year, ${ sField }`;
    
    const title3  = isCairo ? `Importo Netto per Anno` :
                    isClear ? `Total Gross per Revenue Year` :
                    isDisco ? `Total Gross per Revenue Year` 
                            : '';
    
    useEffect(() => {
        setCustomer(
            ( localStorage.getItem('appid') || process.env.REACT_APP_ENV || 'undefined' ) +
            ( localStorage.getItem('CLIENTE_DESC') || '' ).toLowerCase()
        )
    }, []);
    
    const handleChartFormat = (event, nextView) => {
        if(nextView != null && nextView !== chartFormat) {
            setChartFormat(nextView);
        }
    };
    
    return (
        <div className="dashboard">
            { ( isCairo || isClear || isSole ) && <Grid container spacing={3}>
    
                { ( isCairo || isClear ) &&
                <Grid item xs={12} md={12} lg={12}>
                    <Paper>
                        <div className="chart-wrapper top">
                            <div className="chart">
                                
                                <Typography component="h2" variant="h6" color="primary" gutterBottom className="title" style={{textAlign: 'left'}}>
                                    { isSole ? <span>{ title1 }</span>
                                             : <span>{ title1 } (€/<span style={{fontSize: 14}}>000</span>)</span> }
                                </Typography>

                                <div className="chart-toggle">
                                    <ToggleButtonGroup
                                        orientation="horizontal"
                                        value={chartFormat}
                                        exclusive
                                        onChange={handleChartFormat}
                                    >
                                        <ToggleButton value={1} aria-label="module">{ field1 }</ToggleButton>
                                        <ToggleButton value={2} aria-label="list"  >{ field2 }</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                
                                { customerRecognized && !isSole &&
                                    <GrossPerChannelYearChart
                                        oCustomerChecks ={{...oCustomerChecks}}
                                        chartFormat     ={chartFormat}
                                        userSignOut     ={props.userSignOut}
                                    />
                                }
                                { customerRecognized && isSole &&
                                    <GrossPerChannelYearChart
                                        oCustomerChecks ={{...oCustomerChecks}}
                                        chartFormat     ={chartFormat}
                                        userSignOut     ={props.userSignOut}
                                    />
                                }
                                
                            </div>
                        </div>
                    </Paper>
                </Grid>
                }
                
                { ( isCairo || isClear ) &&
                <Grid item xs={12} md={12} lg={12}>
                    <Paper>
                        <div className="chart-wrapper bottom">
                            
                            <div className="chart">
                                <Typography component="h2" variant="h6" color="primary" gutterBottom className="title" style={{textAlign: 'center'}}>
                                    { title2 } (€/<span style={{fontSize: 14}}>000</span>)
                                </Typography>
                                { customerRecognized &&
                                    <GrossPerChannelChart
                                        oCustomerChecks ={{...oCustomerChecks}}
                                        userSignOut     ={props.userSignOut}
                                    />
                                }
                            </div>
                            
                            <div className="chart">
                                <Typography component="h2" variant="h6" color="primary" gutterBottom className="title" style={{textAlign: 'center'}}>
                                    { title3 } (€/<span style={{fontSize: 14}}>000</span>)
                                </Typography>
                                { customerRecognized &&
                                    <GrossPerYearChart
                                        oCustomerChecks ={{...oCustomerChecks}}
                                        userSignOut     ={props.userSignOut}
                                    />
                                }
                            </div>
                            
                        </div>
                    </Paper>
                </Grid>
                }
                
                { isSole &&
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper>
                            <div className="chart-wrapper unique">
                                <div className="chart">
                                    <Typography component="h2" variant="h6" color="primary" gutterBottom className="title" style={{textAlign: 'left'}}>
                                        <span>{ title1 }</span>
                                    </Typography>
                        
                                    <div className="chart-toggle">
                                        <ToggleButtonGroup
                                            orientation="horizontal"
                                            value={chartFormat}
                                            exclusive
                                            onChange={handleChartFormat}
                                        >
                                            <ToggleButton value={1} aria-label="module">{ field1 }</ToggleButton>
                                            <ToggleButton value={2} aria-label="list"  >{ field2 }</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                        
                                    { customerRecognized &&
                                        <BacinoPerData
                                            oCustomerChecks ={{...oCustomerChecks}}
                                            chartFormat     ={chartFormat}
                                            userSignOut     ={props.userSignOut}
                                        />
                                    }
                    
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                }
                
            </Grid> }
            
            { isDisco &&
                <div className="app-wrapper dashboard-container">
                    <div className="logo_container">
                        <div      id="logo1"                                       ></div>
                        <div><img id="logo2" alt="logo2" src="warner_logo.png"    /></div>
                        <div><img id="logo3" alt="logo3" src="discovery_logo.png" /></div>
                    </div>
                </div>
            }
            
        </div>
    );
}
