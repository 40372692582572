import React, { useEffect, useState } from 'react';
import                      './UsersSettings.css';
import FetchService         from '../../common/FetchService';
// import Utils                from '../../common/CommonUtilities';
import {
    createTheme,
    MuiThemeProvider
}                           from '@material-ui/core/styles';
// import Paper                from '@material-ui/core/Paper';
// import Tooltip              from '@material-ui/core/Tooltip';
import Dialog               from '@material-ui/core/Dialog';
import DialogActions        from '@material-ui/core/DialogActions';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogContentText    from '@material-ui/core/DialogContentText';
import DialogTitle          from '@material-ui/core/DialogTitle';
import Button               from '@material-ui/core/Button';
// import EditIcon             from '@material-ui/icons/Edit';
import { SnackAlert }       from '../../common/SnackAlert';
import { SimpleTable }      from '../cubes/components/SimpleTable';
import AccordionSummary     from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon       from '@material-ui/icons/ExpandMore';
// import TextField            from '@material-ui/core/TextField';
// import {FormControlLabel}   from '@material-ui/core';
// import Checkbox             from '@material-ui/core/Checkbox';
import AccordionDetails     from '@material-ui/core/AccordionDetails';
import Accordion            from '@material-ui/core/Accordion';

const theme = createTheme({
    overrides: { MuiTooltip: { tooltip: { fontSize: '0.9em' } } },
    palette: {
      primary: {
        main: '#519657'
      },
      secondary: {
        main: 'rgb(33, 33, 33)'
      },      
    },
    typography: {
        useNextVariants: true,
        fontSize: 13,
    }    
  });

export default function UsersSettings(props) {
    
    const
         [ bReload                               ] = useState(props.reload)
        
        ,[ aoUsers          ,set_aoUsers         ] = useState([])
        ,[ sUser            ,set_sUser           ] = useState('')
        ,[ bUsersChecks     ,set_bUsersChecks    ] = useState(false)
        
        ,[ aoUserGroups     ,set_aoUserGroups    ] = useState([])
        ,[ sUserGroup       ,set_sUserGroup      ] = useState('')
        ,[ bGroupsChecks    ,set_bGroupsChecks   ] = useState(false)

        ,[ aoCubes          ,set_aoCubes         ] = useState([])
        ,[ sCube            ,set_sCube           ] = useState('')
        
        ,[ aoDimensions     ,set_aoDimensions    ] = useState([])
        ,[ aoMeasures       ,set_aoMeasures      ] = useState([])
        
        ,[ sTabExpanded     ,set_sTabExpanded    ] = useState('CUB') // CUB DIM MEA

        ,[ bShowDialog      ,set_bShowDialog     ] = useState(false)
        ,[ oDialogOptions /*,set_oDialogOptions*/] = useState({ sTitle: '', content: null, sConfirmButton: '' })
        ,[ oNotifyOptions /*,set_oNotifyOptions*/] = useState({ message: '', severity: '' }) // severity: 'error' | 'warning' | 'info' | 'success'

        ,{ get: isLoading   ,set: set_isLoading  } = props.loading // || { get: false, set: () => {} }
    ;

    // le chiamate a queste anagrafiche sono indipendenti da tutto
    useEffect(() => {
        ( async () => {
            set_aoUsers(      await FetchService.asyncGet({url: '/lookup/user-list'         }) || [] );
            set_aoUserGroups( await FetchService.asyncGet({url: '/settings/get-user-groups' }) || [] );
            set_aoCubes(      await FetchService.asyncGet({url: '/settings/get-cubes'       }) || [] );
        })();
    }, [ bReload ]);
    
    /* le chiamate a queste anagrafiche dipendono da quelle precedenti
    useEffect(() => {

        const selectedGroup = aoUserGroups[0] || {};
        set_oUserGroup(selectedGroup);
        
        const selectedCube = aoCubes[0];
        set_oCube(selectedCube);
        
        //await loadUsers(     selectedGroup.GROUP_COD);

        //await loadDimensions(selectedGroup.GROUP_COD, selectedCube.CUBE_COD);

        //await loadMeasures(  selectedGroup.GROUP_COD, selectedCube.CUBE_COD);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ aoUsers, aoUserGroups, aoCubes ]);*/

    useEffect(() => {

        ( async () => {
            if ( sUserGroup ) {

                const aoUsersInUserGroup = await FetchService.asyncGet(
                    { url: '/settings/get-user-group-users', params: { pGroupCod: sUserGroup } }
                ) || [];
                const asUsersInUserGroup = aoUsersInUserGroup.filter( o => o.FLAG_SELECTED === 'Y' ).map( o => o.KUSER );
                aoUsers.forEach( oUser => {
                    oUser.FLAG_SELECTED = asUsersInUserGroup.includes( oUser.KUSER ) ? 'Y' : 'N';
                });
                set_aoUsers( [...aoUsers] );

            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sUser, sUserGroup ]);


    useEffect(() => {
        // console.log( 'sUserGroup: "' + sUserGroup + '", sCube "' + sCube + '"' );
        ( async () => {
            if ( sUserGroup && sCube ) {
                // console.log('validi')
                const params = { groupCod: sUserGroup, cubeCod: +sCube };
                
                set_aoDimensions( [] );
                set_aoDimensions( await FetchService.asyncGet( { url: '/settings/get-user-group-dimensions', params }) || [] );
                set_aoMeasures(   [] );
                set_aoMeasures(   await FetchService.asyncGet( { url: '/settings/get-user-group-measures',   params }) || [] );
                
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sUserGroup, sCube ]);

    const handleCloseAlert    = ()=>{}/*(event, reason) => {
        // if (reason === 'clickaway') { return; }
        set_oNotifyOptions({ message: '' });
    }*/;
    
    /* const toggleNotifica        = ( message, severity ) => {
        set_isLoading(false);
        set_bShowDialog(false);
        set_oNotifyOptions({ message, severity  });
    }; */
    
    useEffect( () => {
        if ( !bShowDialog ) {
            set_isLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ bShowDialog ]);
    
    /*
    const rebuildCube = async () => {
        
        set_isLoading(true);
        set_isLoading(true);
        const response = await FetchService.asyncPost({ url: '/settings/rebuild-cube', params: { pCubeCod: oCube.CUBE_COD } });
        const sDefaultErrorMessage = 'Operation failed. Please retry or contact application support';
        if ( !response || ( response instanceof Error ) ) {
            toggleNotifica( sDefaultErrorMessage, 'error' );
        } else {
            const { nRetVal, vOutputRowcounts, vErrorMessageDesc } = response;
            if ( nRetVal > 0 ) {
                if ( ( typeof vOutputRowcounts === 'string' ) && ( +vOutputRowcounts.split('-').join('') ) === 0 ) {
                    // se il nRetVal è 1 e vOutputRowcounts è "0-0-0-0-0-0"
                    toggleNotifica( 'Rebuild executed. No changes.', 'warning' );
                } else {
                    toggleNotifica( 'Rebuild completed', 'success' );
                }
            } else {
                toggleNotifica( vErrorMessageDesc || sDefaultErrorMessage, 'error' );
            }
            set_bReload(!bReload);
        }
        
    }*/
    
    const

        isCheckedFunction       = oRow => oRow.FLAG_SELECTED  === 'Y',

        isHighlightedFunction   = oRow => oRow.FLAG_HIGHLIGHT === 'Y',
    
        // ATTENZIONE - questa funzione viene passata a un componente figlio (SimpleTable)
        handleChangeCheck       = ( { forceChecked, oRow } ) => {
            // oRow è il riferimento ad un oggetto contenuto in aoRows
            oRow.FLAG_SELECTED = ( forceChecked != null ? forceChecked : !isCheckedFunction( oRow ) ) ? 'Y' : 'N';
            // quindi una volta modificato è sufficiente risalvare nello stato una copia (come nuovo array) di aoRows usando setAoRows
        },

        fCheckAll               = ( { bCheckAll, aoRows, setAoRows } ) => {
            // vengono selezionate TUTTE le righe, indipendentemente dai filtri per colonna
            aoRows.forEach( oRow => handleChangeCheck({ forceChecked: bCheckAll, oRow }) );
            setAoRows( [...aoRows] );
        },

        fCheckOne               = ( { isChecked, oRow, aoRows, setAoRows  } ) => {
            handleChangeCheck({ isChecked, oRow });
            setAoRows( [...aoRows] );
        },

        // ATTENZIONE - questa funzione viene passata a un componente figlio (SimpleTable)
        handleChangeHighlight   = ( { forceHighlighted, oRow } ) => {
            // oRow è il riferimento ad un oggetto contenuto in aoRows
            oRow.FLAG_HIGHLIGHT = ( forceHighlighted != null ? forceHighlighted : !isHighlightedFunction( oRow ) ) ? 'Y' : 'N';
            // quindi una volta modificato è sufficiente risalvare nello stato una copia (come nuovo array) di aoRows usando setAoRows
        },

        removeHighlight         = oRow => handleChangeHighlight({ forceHighlighted: false, oRow }),
        
        fHighlightOnlyOne       = oRow => {
        
            const { CUBE_COD, GROUP_COD, KUSER } = oRow || {};
            
            const isHighlighted = isHighlightedFunction( oRow );

            if ( KUSER || GROUP_COD ) {
                aoUsers.forEach(      removeHighlight );
                aoUserGroups.forEach( removeHighlight );
            }
            if ( CUBE_COD ) {
                aoCubes.forEach(      removeHighlight );
            }
            
            handleChangeHighlight({ forceHighlighted: !isHighlighted, oRow });

            if ( KUSER || GROUP_COD ) {
                set_aoUsers(      [...aoUsers     ] );
                set_aoUserGroups( [...aoUserGroups] );
            }
            
            if ( KUSER     ) { set_sUser(     KUSER    ); set_bUsersChecks(false); set_bGroupsChecks(true);  }
            if ( GROUP_COD ) { set_sUserGroup(GROUP_COD); set_bUsersChecks(true);  set_bGroupsChecks(false); }
            if ( CUBE_COD  ) { set_sCube(     CUBE_COD ); }
            
        },

        tableContainer          = ({ 
                key, sTableType, sTableDataType, aoColumns, aoRows, setAoRows, bChecksVisible, bOnlySelectedEnabled, exception,
                noHeader, noFilter, isEditable, isHighlightable
            }) => {
            return !( aoRows && aoRows.length ) ? null : (
                <SimpleTable
                    
                    chiave                ={ key }
                    sTableDataType        ={ sTableDataType }
                    aoRows                ={ aoRows    }
                    aoCols                ={ aoColumns }
                    
                    noHeight              ={ true }
                    noHeader              ={ noHeader }
                    noFilter              ={ noFilter }
                    bOnlySelected         ={ bOnlySelectedEnabled }
                    
                    oCheckOptions         ={ { isEnabled: true, isVisible: ( sTableType === 'checkable' ) && bChecksVisible } }
                    isCheckedFunction     ={ isCheckedFunction }
                    fCheckAll             ={ bCheckAll => fCheckAll( { bCheckAll, aoRows, setAoRows } ) }
                    fCheckOne             ={ ({ isChecked, oRow }) => fCheckOne( { isChecked, oRow, aoRows, setAoRows } ) }
                    
                    isHighlightable       ={ isHighlightable }
                    isHighlightedFunction ={ isHighlightedFunction }
                    fHighlightOnlyOne     ={ oRow => fHighlightOnlyOne( oRow ) }
                    isEditable            ={ !bChecksVisible && isEditable }
                    
                    exception             ={ exception }
                    
                />
            )
        },

        aoUsersColumns          = [
             { name: 'FULL_NAME'     ,width: 120 ,title: 'Users (' + aoUsers.length + ')'            ,isUniqueKeyForRow: true }
            ,{ name: 'FLAG_ADMIN'    ,width: 30  ,title: 'Role'                                      ,format: (val) => val === 'Y' ? 'Admin' : 'User' }
            ,{ name: 'AGENTE_COD'    ,width: 90  ,title: 'Agent Code'                                                          }
        ],

        aoGroupsColumns         = [
            { name: 'GROUP_DESC'     ,width: 150 ,title: 'User Groups (' + aoUserGroups.length + ')' ,isUniqueKeyForRow: true }
        ],

        aoCubesColumns          = [
            { name: 'CUBE_DESC'      ,width: 150 ,title: 'Cubes ('       + aoCubes.length      + ')' ,isUniqueKeyForRow: true }
        ],

        aoDimensionsColumns     = [
            { name: 'DIMENSION_DESC' ,width: 150 ,title: 'Dimensions ('  + aoDimensions.length + ')' ,isUniqueKeyForRow: true }
        ],

        aoMeasuresColumns       = [
            { name: 'MEASURE_DESC'   ,width: 150 ,title: 'Measures ('    + aoMeasures.length   + ')' ,isUniqueKeyForRow: true }
        ]
        
        /*
        onClickEditAgentCod     = ( val, row, col ) => {
            set_bShowDialog(true);
            set_oDialogOptions({
                sTitle:         'Edit Agent Code',
                sConfirmButton: 'Save',
                content:
                    <form className="AgentCodTextfield" autoComplete="off">
                        <TextField
                            id       ="agent-cod"
                            label    ="Agent Code"
                            value    ={val}
                            onChange ={()=>{}}
                            onClick  ={((event)=>{event.stopPropagation()})}
                        />
                    </form>
            });
        }
        */
        ,isValidLength = aoDimensions.length && aoMeasures.length
    ;
    
    return (
        <div className="users-settings">

            { !aoCubes[0] ? <div>&nbsp;</div> :
                <MuiThemeProvider theme={theme}>

                    { /* ----- notifiche ----- */ }
                    <SnackAlert oNotifyOptions={ { ...oNotifyOptions, handleCloseAlert } } />

                    { /* ----- popup di conferma operazione ----- */ }
                    <Dialog open={ bShowDialog } >
                        
                        <DialogTitle>
                            { oDialogOptions.sTitle }
                        </DialogTitle>
                        
                        <DialogContent>
                            { isLoading ? <div>&nbsp;<br/>&nbsp;</div> : <DialogContentText>{ oDialogOptions.content }</DialogContentText> }
                        </DialogContent>
                        
                        <DialogActions>
                            { !isLoading
                                ? <>
                                    <Button onClick={()=>{}} color="primary">{ oDialogOptions.sConfirmButton }</Button>
                                    <Button onClick={()=>set_bShowDialog(false)} color="secondary">cancel</Button>
                                </>
                                : null
                            }
                        </DialogActions>
                        
                    </Dialog>

                    { /* ----- contenuto della pagina ----- */ }
                    <div className="container">
                        
                        { tableContainer({
                             key:                    'users'
                            ,sTableDataType:         'USERS'
                            ,sTableType:             'checkable'
                            ,aoColumns:              aoUsersColumns
                            ,aoRows:                 [...aoUsers]
                            ,setAoRows:              set_aoUsers
                            ,bChecksVisible:         bUsersChecks
                         /* ,exception:              (val, row, col) => ( col.name === 'AGENTE_COD' )
                                                        ? (<span><EditIcon onClick={()=>{onClickEditAgentCod(val, row, col)}}/>{val}</span>)
                                                        : val */
                            ,bOnlySelectedEnabled:   false
                            ,isEditable:             true
                            ,isHighlightable:        true
                        }) }
                        
                        { tableContainer({
                             key:                    'usergroups'
                            ,sTableDataType:         'USERS_GROUPS'
                            ,sTableType:             'checkable'
                            ,aoColumns:              aoGroupsColumns
                            ,aoRows:                 [...aoUserGroups]
                            ,setAoRows:              set_aoUserGroups
                            ,bChecksVisible:         bGroupsChecks
                            ,bOnlySelectedEnabled:   false
                            ,isEditable:             true
                            ,isHighlightable:        true
                        }) }

                        <div className="CUB_DIM_MEA">

                            { !sUserGroup ? null :
                                <Accordion
                                    expanded={  sTabExpanded === 'CUB'  }
                                    onChange={ () => { set_sTabExpanded('CUB') } }
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        id="cubes-tab-header"
                                        className="accordionSummary"
                                    >
                                        <div>{ sCube ? ( 'Cube selected: ' + ( ( aoCubes.find( oCube => oCube.CUBE_COD === sCube ) || {} )['CUBE_DESC'] || '' ).toUpperCase() ) : 'Cubes' }</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        { tableContainer({
                                             key:                    'cubes'
                                            ,sTableDataType:         'CUBES'
                                            ,sTableType:             'readOnly'
                                            ,aoColumns:              aoCubesColumns
                                            ,aoRows:                 [...(aoCubes)]
                                            ,setAoRows:              set_aoCubes
                                            ,noHeader:               true
                                            ,noFilter:               true
                                            ,isHighlightable:        true
                                        }) }
                                    </AccordionDetails>
                                </Accordion>
                            }
                            
                            { !sCube ? null :
                                <Accordion
                                    expanded={ sTabExpanded === 'DIM' }
                                    onChange={ () => { set_sTabExpanded( 'DIM' ) } }
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        id="dimensions-tab-header"
                                        className="accordionSummary"
                                    >
                                        <div>{ 'Dimensions' + ( !isValidLength ? '' : ' (' + ( aoDimensions.length || 0 ) + ')' ) }</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        { tableContainer({
                                             key:                    'dimensions'
                                            ,sTableDataType:         'DIMENSIONS'
                                            ,sTableType:             'checkable'
                                            ,aoColumns:              aoDimensionsColumns
                                            ,aoRows:                 [...aoDimensions]
                                            ,setAoRows:              set_aoDimensions
                                            ,noHeader:               true
                                            ,bChecksVisible:         true
                                        }) }
                                    </AccordionDetails>
                                </Accordion>
                            }
                            
                            { !sCube ? null :
                                <Accordion
                                    expanded={ sTabExpanded === 'MEA' }
                                    onChange={ () => { set_sTabExpanded( 'MEA' ) } }
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        id="measures-tab-header"
                                        className="accordionSummary"
                                    >
                                        <div>{ 'Measures' + ( !isValidLength ? '' : ' (' + ( aoMeasures.length || 0 ) + ')' ) }</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        { tableContainer({
                                             key:                   'measures'
                                            ,sTableDataType:        'MEASURES'
                                            ,sTableType:            'checkable'
                                            ,aoColumns:             aoMeasuresColumns
                                            ,aoRows:                [...aoMeasures]
                                            ,setAoRows:             set_aoMeasures
                                            ,noHeader:              true
                                            ,bChecksVisible:        true
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            }
                            
                        </div>
                    
                    </div>
                    
                </MuiThemeProvider>
            }
        </div>
    );
}